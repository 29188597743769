import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import './IndexSheetModal.scss';
import styles from './IndexSheetModal.module.scss';
import { useWindowSize } from '../../../../services/helper';
import { getDoctype } from '../../../../services/suspenseQueueService';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { handleIsRequiredError } from '../../../../utils/helper/handleReuiredErrorFunc';
import { searchPageColumnsAPI } from '../../../../pages/Search/SearchServices';
import moment from 'moment';
import _ from 'lodash';
import { getDayforceEmpInfoByEmpNumber } from '../../../../services/scanOnDemandService';
import { invalidChars } from '../../../../utils/helper/globalFunc';
import { GlobalLoader } from '../../../../newcomponents/GlobalLoader/GlobalLoader';
import { v4 as uuidv4 } from 'uuid';
// import { groupData, sectionData } from '../../../../utils/helper/helperData';

const IndexSheetModalV2 = ({ selectedRows, popupOpen, closePopup, officeId, onSubmit }) => {
  const [lookup, setLookup] = useState('');
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [islookupError, setIslookupError] = useState(false);

  const [isRequiredError, setIsRequiredError] = useState(false);

  const [indexSheetData, setIndexSheetData] = useState({});
  const [pageColumnData, setPageColumnData] = useState([]);
  // const [isSubmitActive, IsSubmitActive] = useState(false);

  const systemConfig = JSON.parse(localStorage.getItem('system_config')) !== undefined && JSON.parse(localStorage.getItem('system_config'));
  const showLookup = systemConfig?.features?.some((itm) => itm.title?.includes('Lookup') && itm.value === true);
  const [selectedBUOW, setSelectedBUOW] = useState({});
  const [showIndexSheetPopup, setShowIndexSheetPopup] = useState(false);
  const [statusCheckboxFields, setStatusCheckBoxFields] = useState([]);

  const onChangeVerNum = (e, verName) => {
    let key = verName;
    let value = e?.label;
    Object.assign(indexSheetData, { [key]: value });
    setIsRequiredError(false);
  };

  const onChangeDocType = (e, colName) => {
    let key = colName;
    let value = e?.label;
    let tempObj = indexSheetData.system_folders;
    Object.assign(tempObj, { [key]: value });

    setIndexSheetData((values) => ({ ...values, ['system_folders']: tempObj }));
  };
  const [defaultKey, setDefaultKey] = useState(false);

  const filterData = (obj1) => {
    let keys = Object.keys(obj1);
    keys.length !== 0 &&
      keys?.map((key) => {
        pageColumnData.length !== 0 &&
          pageColumnData?.map((col) => {
            if (col?.name === key && lookup === '') {
              return (indexSheetData.system_folders[key] = obj1[key]);
            } else return (indexSheetData.system_folders[col.name] = selectedRows[0]['IndexData'][col.name]);
          });
      });
  };
  useEffect(() => {
    if (selectedRows.length > 0 && !popupOpen) {
      if (selectedRows[0].IndexData) {
        setIslookupError(false);
        setMessage('');
        setLookup('');
        var metadata = selectedRows[0].IndexData;
        setIndexSheetData({
          company: metadata.company,
          division: metadata.division,
          employmentyear: metadata.employmentyear,
          filecode: metadata.filecode,
          program: metadata.program,
          system_folders: {},
          VersionNumber: '1.0',
          ...metadata,
        });
      }
    }
    updateCheckboxFields(indexSheetData, selectedBUOW);
  }, [selectedRows]);
  useEffect(() => {
    if (
      indexSheetData !== undefined &&
      indexSheetData !== null &&
      indexSheetData?.system_folders !== undefined &&
      indexSheetData?.system_folders !== null &&
      Object.keys(indexSheetData?.system_folders).length === 0
    ) {
      filterData(selectedRows.length > 0 && selectedRows[0]?.IndexData);
    }
    let system_config = JSON.parse(window.localStorage.getItem('system_config')).buowDescription;
    const key = indexSheetData.buow;
    let selectedBuow = _.get(system_config, [key]);
    if (selectedBuow) {
      selectedBuow.indexSheet = Object.fromEntries(Object.entries(selectedBuow.indexSheet).sort((x, y) => x[1].sequence - y[1].sequence));
    }
    setSelectedBUOW(selectedBuow !== undefined ? selectedBuow : {});
  }, [indexSheetData]);

  useEffect(() => {
    searchPageColumnsAPI().then(async (response) => {
      setPageColumnData(response.data);
    });
    updateCheckboxFields(indexSheetData, selectedBUOW);
  }, []);

  let officeOptions = !Array.isArray(officeId)
    ? [{ value: officeId, label: officeId }]
    : officeId.length > 0 &&
      officeId?.map((item) => {
        return { value: item.officeId, label: item.officeId };
      });

  const closePopupActions = () => {
    // IsSubmitActive(false);
    setShowIndexSheetPopup(false);
    closePopup();
    setStatusCheckBoxFields([]);
  };

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value.trim() === '' ? '' : e.target.value;
    let type = e.target.type;

    if (type === 'date') {
      let formattedDate = moment(value).format('MM/DD/yyyy');
      formattedDate = moment(formattedDate).isValid() ? formattedDate : '';
      setIndexSheetData((values) => ({ ...values, [key]: formattedDate }));
    } else {
      setIndexSheetData((values) => ({ ...values, [key]: value }));
    }
  };

  const handleInputChangeLookup = (e) => {
    let key = e.target.name;
    let value = e.target.value;

    if (key === 'lookup') {
      setLookup(value);
    }
  };

  const handleColumnData = (e) => {
    let keyName = e.target.name;
    let value = e.target.value.trim() === '' ? '' : e.target.value;
    systemKeyValidator = false;

    let TempObj = indexSheetData?.system_folders;
    Object.assign(TempObj, { [keyName]: value });
    setIndexSheetData((values) => ({ ...values, ['system_folders']: TempObj }));
  };
  let systemKeys = Object.keys(indexSheetData).length !== 0 && Object.keys(indexSheetData?.system_folders).length !== 0 ? Object.keys(indexSheetData?.system_folders) : [];
  let systemKeyValidator = systemKeys?.some(
    (itm, idx) => indexSheetData.system_folders[itm.name] !== 'ObjectType' && (!indexSheetData.system_folders[itm] || indexSheetData.system_folders[itm] === '')
  );

  const handleOnSubmit = (e) => {
    pageColumnData?.map((itm) => {
      if (itm.lowCard === true && itm.lowCardValue !== null && !itm.lowCardValue.includes(indexSheetData.system_folders[itm.name])) indexSheetData.system_folders[itm.name] = '';
    });
    e.preventDefault();

    let statusKey;

    // Find the statusKey based on selectedBUOW.indexSheet
    if (selectedBUOW?.indexSheet && Object.keys(selectedBUOW.indexSheet).length > 0) {
      Object.entries(selectedBUOW.indexSheet).forEach(([key, val]) => {
        if (val.type?.toLowerCase() === 'multicheckbox') {
          statusKey = key;
        }
        if (statusKey) {
          // Initialize an empty array to collect names
          const namesToUpdate = [];

          // Iterate through statusCheckboxFields to collect names
          statusCheckboxFields.forEach((field) => {
            if (field.fieldName && field.fieldName.toLowerCase() === statusKey.toLowerCase()) {
              field.items.forEach((item) => {
                // Push item.name to namesToUpdate if isCheck is true
                if (item.isCheck === true) {
                  namesToUpdate.push(item.name);
                }
              });
            }
          });
          // Update indexSheetData with namesToUpdate array
          indexSheetData[statusKey] = namesToUpdate.length > 0 ? namesToUpdate : [];
        }
      });
    }

    // Check if statusKey exists

    //End of logic or adding the array of status in payload
    let temp = indexSheetData;

    systemKeyValidator = systemKeys?.some((itm, idx) => itm !== 'ObjectType' && (!temp.system_folders[itm] || temp.system_folders[itm] === ''));
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    let sysColumnValidator = systemFolders?.some((itm, idx) => itm.name !== 'ObjectType' && (!temp.system_folders[itm.name] || temp.system_folders[itm.name] === ''));

    Object.entries(selectedBUOW.indexSheet).some(([key, val]) => {
      if (val.type && val.type === 'boolean') {
        if (indexSheetData[key]) {
          indexSheetData[key] = indexSheetData[key] === 'Active' ? true : false;
        }
      }
      if (val.type && val.type === 'date') {
        if (indexSheetData[key]) {
          indexSheetData[key] = moment(indexSheetData[key]).format('yyyy-MM-DDTHH:mm:ss');
        }
      }
    });

    const verifyIndexSheetFields = Object.entries(selectedBUOW.indexSheet).some(([key, val]) => {
      if (val.is_required && val.is_required === true && (indexSheetData[key] === undefined || indexSheetData[key] === '' || indexSheetData[key]?.length === 0)) {
        setIsRequiredError(true);
        return true;
      }
      return false;
    });

    if (verifyIndexSheetFields) {
      return;
    }

    if (
      // temp.employeenumber === '' ||
      // temp.employeenumber === undefined ||
      // temp.last_name === undefined ||
      // temp.first_name === undefined ||
      // temp.last_name === '' ||
      // temp.first_name === '' ||
      temp.VersionNumber === '' ||
      temp.VersionNumber === undefined ||
      temp.VersionNumber !== '1.0' ||
      systemKeyValidator === true ||
      systemKeys.length === 0 ||
      sysColumnValidator === true

      // systemKeys.includes('ObjectType') === false ||
      // systemKeys.includes('Section') === false ||
      // systemKeys.includes('File Series') === false ||
      // systemKeys.includes('Folder') === false
    ) {
      setIsRequiredError(true);
      return;
    } else {
      setIsRequiredError(false);
    }

    if (!Object.keys(temp).includes('OfficeSiteID')) {
      temp = { ...temp, OfficeSiteID: officeOptions[0].value };
      setIndexSheetData(temp);
    }
    let payload = _.cloneDeep(temp);
    delete payload.VersionNumber;
    onSubmit(payload);
    setShowIndexSheetPopup(false);
  };

  useEffect(() => {
    setIsRequiredError(false);
    // selectedRows.map((file) => {
    let metadataObj = selectedRows[0]?.IndexData;
    if (selectedRows.length < 0) return;
    let system_config = JSON.parse(window.localStorage.getItem('system_config')).buowDescription;
    const key = metadataObj?.buow;
    let selectedBuow = _.get(system_config, [key]);
    if (selectedBuow?.index_sheet_workflow === true && popupOpen === true) {
      setShowIndexSheetPopup(true);
    } else if (selectedBuow?.index_sheet_workflow === false && popupOpen === true) {
      let temp = indexSheetData;

      if (!Object.keys(temp).includes('OfficeSiteID')) {
        temp = { ...temp, OfficeSiteID: officeOptions[0].value };
        setIndexSheetData(temp);
      }
      temp.system_folders = {};
      pageColumnData.forEach((column) => {
        temp.system_folders[column.name] = key;
      });
      let payload = _.cloneDeep(temp);
      delete payload.VersionNumber;
      onSubmit(payload);
    }
    pageColumnData?.map((colData) => {
      if (
        indexSheetData?.system_folders[colData.name] === undefined ||
        indexSheetData?.system_folders[colData.name] === '' ||
        indexSheetData?.system_folders[colData.name] === null
      ) {
        indexSheetData.system_folders[colData.name] = colData?.defaultValue;
      }
    });
    // });
  }, [popupOpen]);

  const returnRequiredFields = (colData) => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    return systemFolders?.map((item) => {
      return colData?.name === item.name && <React.Fragment key={uuidv4()}>*</React.Fragment>;
    });
  };
  const renderIsRequiredSymbol = (itm) => {
    if (selectedBUOW?.indexSheet[itm]?.is_required && selectedBUOW?.indexSheet[itm]?.is_required === true) return '*';
  };

  const renderRequiredErrMsg = (itm) => {
    if (selectedBUOW?.indexSheet[itm]?.is_required && selectedBUOW?.indexSheet[itm]?.is_required === true) {
      let isKeyPresent = false;
      let isCheck = false;
      statusCheckboxFields?.forEach((val) => {
        if (val?.fieldName?.toLowerCase() === itm?.toLowerCase() && val?.items?.every((n) => n?.isCheck === false)) isCheck = true;
      });
      Object.entries(selectedBUOW.indexSheet).forEach(([key, val]) => {
        if (key?.toLowerCase() === itm?.toLowerCase() && val?.is_required === true && val?.type?.toLowerCase() === 'multicheckbox') {
          isKeyPresent = true;
        }
      });

      return (
        <>
          {(indexSheetData[itm] === undefined || indexSheetData[itm] === '' || (isKeyPresent === true && isCheck && indexSheetData[itm]?.length === 0)) &&
            isRequiredError === true &&
            handleIsRequiredError()}
        </>
      );
    }
  };

  const fetchDayForceInfo = async (e) => {
    e.preventDefault();
    setIsRequiredError(false);

    setIndexSheetData((values) => {
      const updatedIndexSheet = Object.keys(selectedBUOW.indexSheet).reduce((acc, key) => {
        acc[key] = '';
        return acc;
      }, {});

      return {
        ...values,
        ...updatedIndexSheet,

        system_folders: {
          ...(values.system_folders['Group'] = ''),
          ...(values.system_folders['Folder'] = ''),
          ...(values.system_folders['Section'] = ''),
          ...values.system_folders,
        },
      };
    });
    setLoading(true);
    await getDayforceEmpInfoByEmpNumber(lookup).then((response) => {
      if (response.data) {
        setIslookupError(false);
        setMessage('Record Updated Successfully!');
        setLoading(false);
        let dayforceData = response.data.Data;
        setDefaultKey(!defaultKey);
        const modifiedIndexSheet = storeDayForceInfo(indexSheetData, dayforceData);
        updateCheckboxFields(modifiedIndexSheet, selectedBUOW);
        setIndexSheetData(modifiedIndexSheet);
      } else if (response.error) {
        setIslookupError(true);
        setLoading(false);
        setMessage('No Record Found');
        setIndexSheetData((values) => {
          const updatedIndexSheet = Object.keys(selectedBUOW.indexSheet).reduce((acc, key) => {
            acc[key] = '';
            return acc;
          }, {});

          return {
            ...values,
            ...updatedIndexSheet,
            system_folders: {
              ...(values.system_folders['Group'] = ''),
              ...(values.system_folders['Folder'] = ''),
              ...(values.system_folders['Section'] = ''),
              ...values.system_folders,
            },
          };
        });

        updateCheckboxFields(indexSheetData, selectedBUOW);
      }
    });
  };

  const storeDayForceInfo = (indexsheet, dayforce) => {
    const specialCharsRegex = /[!@#$%^&*()_,.?":{}|<>]/g;

    Object.entries(dayforce).forEach(([key, val]) => {
      let modifiedKey = key.toLowerCase().replace(/ /g, '');
      modifiedKey = modifiedKey.replace(specialCharsRegex, '');
      if (modifiedKey !== key) {
        dayforce[modifiedKey] = dayforce[key];
        delete dayforce[key];
      }
    });

    //iterated indexsheet data ans compares the indexsheet keys with dayforce and store the data in original keys
    Object.entries(indexsheet).forEach(([key, value]) => {
      let modifiedKey = key.toLowerCase().replace(/ /g, '');
      modifiedKey = modifiedKey.replace(specialCharsRegex, '');
      // Day-force data for other than status, folder and term date
      if (dayforce.hasOwnProperty(modifiedKey)) {
        indexsheet[key] = dayforce[modifiedKey];
      }

      // Day-force data for status
      if (modifiedKey.includes('status')) {
        indexsheet[key] =
          dayforce['employmentstatuses']['Items'][0]['EmploymentStatus']['XRefCode'] === 'TERMINATED' ||
          dayforce['employmentstatuses']['Items'][0]['EmploymentStatus']['XRefCode'] === 'INACTIVE'
            ? 'Inactive'
            : 'Active';
      }
      // Day-force data for term date
      if (modifiedKey.includes('termdate')) {
        indexsheet[key] = dayforce['TerminationDate'] ? moment(dayforce['TerminationDate']).format('YYYY-MM-DD') : null;
      }

      // Day-force data for folder
      indexsheet.system_folders['Folder'] =
        (dayforce.lastname !== '' && dayforce.lastname !== undefined ? dayforce.lastname + ', ' : '') +
        (dayforce.firstname !== '' && dayforce.firstname !== undefined ? dayforce.firstname + ', ' : '') +
        (dayforce.middlename !== '' && dayforce.middlename !== undefined ? dayforce.middlename + ', ' : '') +
        (dayforce.employeenumber !== '' && dayforce.employeenumber);
    });

    return indexsheet;
  };

  // Start of adding flag values for Status checkbox field
  const updateCheckboxFields = (indexSheetData, selectedBUOW) => {
    let hasStatusKey;

    // Check if there are multicheckbox fields in selectedBUOW.indexSheet
    if (selectedBUOW?.indexSheet && Object.keys(selectedBUOW.indexSheet).length > 0) {
      Object.entries(selectedBUOW.indexSheet).forEach(([key, val]) => {
        if (val.type === 'multicheckbox') {
          // Check if key exists in indexSheetData
          const matchingKey = Object.keys(indexSheetData).find((keys) => keys.toLowerCase() === key);
          if (matchingKey) {
            hasStatusKey = matchingKey;
          }
        }
      });
    }

    // Initialize statusCheckboxFields if not already initialized
    let indexSheetField = Object.keys(indexSheetData).some((key) => key.toLowerCase() === 'status') ? indexSheetData?.[hasStatusKey] : null;

    // Check if hasStatusKey is truthy
    if (hasStatusKey) {
      // Iterates through selectedBUOW.indexSheet
      if (selectedBUOW?.indexSheet && Object.keys(selectedBUOW.indexSheet).length > 0) {
        Object.entries(selectedBUOW.indexSheet).forEach(([key, val]) => {
          if (val.type === 'multicheckbox' && val.fieldValues) {
            // Check if key exists in indexSheetData and retrieve it
            const indexSheetField = Object.keys(indexSheetData).find((keys) => keys.toLowerCase() === key?.toLowerCase());

            // Initialize an array to collect unique items for the current key
            const uniqueItems = [];

            val.fieldValues.forEach((field, index) => {
              // Determine isCheck based on field matching indexSheetData value
              const isCheck = indexSheetField && indexSheetData[indexSheetField]?.includes(field);

              // Check if this field already exists in statusCheckboxFields
              const existingIndex = statusCheckboxFields.findIndex((item) => item.fieldName === key && item.items.some((item) => item.name === field));

              if (existingIndex === -1) {
                // Add new item to uniqueItems array if not already present
                uniqueItems.push({
                  id: index,
                  name: field,
                  isCheck: isCheck ?? false, // Ensure isCheck defaults to false if undefined
                });
              }
            });

            // Check if fieldName already exists in statusCheckboxFields
            const existingIndex = statusCheckboxFields.findIndex((item) => item.fieldName === key);

            if (existingIndex !== -1) {
              // If fieldName already exists, merge uniqueItems into its existing items array
              uniqueItems.forEach((item) => {
                const existingItem = statusCheckboxFields[existingIndex].items.find((i) => i.name === item.name);
                if (!existingItem) {
                  statusCheckboxFields[existingIndex].items.push(item);
                }
              });
            } else if (uniqueItems.length > 0) {
              // Add new fieldName with uniqueItems array only if there are items to add
              statusCheckboxFields.push({
                fieldName: key,
                items: uniqueItems,
              });
            }
          }
        });
      }
    } else {
      let newStatusCheckboxFields = [];

      if (selectedBUOW?.indexSheet) {
        Object.entries(selectedBUOW.indexSheet).forEach(([key, val]) => {
          if (val.type === 'multicheckbox' && val.fieldValues) {
            val.fieldValues.forEach((field, index) => {
              if (!statusCheckboxFields.some((item) => item.fieldName === key && item.items.some((i) => i.name === field))) {
                newStatusCheckboxFields.push({
                  fieldName: key,
                  items: [
                    {
                      id: index,
                      name: field,
                      isCheck: false,
                    },
                  ],
                });
              } else {
                setStatusCheckBoxFields((prev) =>
                  prev.map((item) =>
                    item.fieldName === key && item.items.length > 0
                      ? {
                          ...item,
                          items: item.items.map((itm) => ({
                            ...itm,
                            isCheck: false, // Set isCheck to false for each item in items array
                          })),
                        }
                      : item
                  )
                );
              }
            });
          }
        });
      }
      setStatusCheckBoxFields((prev) => [...prev, ...newStatusCheckboxFields]);
    }
  };
  // End of adding flag values for Status checkbox field

  const handleCheckChildElement = (event, id, fieldName) => {
    const updatedFields = statusCheckboxFields.map((values) => {
      if (values.fieldName?.toLowerCase() === fieldName?.toLowerCase()) {
        const updatedItems = values.items.map((item) => {
          // Check if the item id matches the clicked id
          if (item.id === id) {
            return {
              ...item,
              isCheck: event.target.checked,
            };
          }
          return item;
        });

        // Return updated values object with updated items array
        return {
          ...values,
          items: updatedItems,
        };
      }

      return values; // Return unchanged values if fieldName does not match
    });

    // Update state with the updatedFields array
    setStatusCheckBoxFields(updatedFields);
  };

  return (
    <>
      <Modal
        show={showIndexSheetPopup}
        className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
        backdropClassName="orc-modal-custom-backdrop"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header>
          <div data-testid="modal-main-header-container" className={styles.modalHeader}>
            <div data-testid="modal-header-container">
              <h2 data-testid="modal-header" className={styles.modalHeaderTitle}>
                Index Sheet Metadata
              </h2>
            </div>
            <div
              data-testid="header-close-icon-container"
              className={styles.modalHeaderIcon}
              onClick={() => {
                closePopupActions();
              }}
            >
              <img data-testid="header-close-icon" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {Loading && <GlobalLoader />}
          <form data-testid="form-container" onSubmit={handleOnSubmit}>
            <div style={{ margin: '0 2em ' }}>
              <div
                className="fsa-grid"
                style={{
                  marginTop: '1px',
                }}
              >
                <div data-testid="form-help-text-container" className="fsa-grid__1/1">
                  <div data-testid="form-help-text-main" style={{ fontWeight: 'bold', color: `rgba(33, 37, 41,0.5) ` }}>
                    This index sheet metadata will be applied to all selected files.
                  </div>
                  <div data-testid="form-help-text-sub" style={{ marginBottom: '1em', fontWeight: 'bold', color: `rgba(33, 37, 41,0.5) ` }}>
                    {JSON.parse(sessionStorage.getItem('user_information'))?.contextId} INDEX METADATA:<b style={{ color: '#000' }}> Version 1.0</b>
                  </div>
                  <div style={{ marginBottom: '1em' }}></div>
                </div>
              </div>
              <div
                data-testid="indexsheet-modal-fields"
                className="fsa-grid"
                style={{
                  marginTop: '5px',
                }}
              >
                {showLookup === true && (
                  <>
                    <div data-testid="look-up-container" className="fsa-grid__1/2">
                      <div className="fsa-field">
                        <label data-testid="look-up-label" className="fsa-field__label" htmlFor="lookup">
                          <b>Lookup</b>
                        </label>
                        <div data-testid="look-up-input-container" className="d-flex">
                          <input
                            data-testid="look-up-input"
                            className="fsa-input fsa-field__item"
                            id="lookup"
                            name="lookup"
                            aria-describedby="lookup"
                            aria-required="false"
                            value={lookup}
                            type="number"
                            placeholder="Enter Employee Number"
                            onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()}
                            pattern="^(0|[1-9][0-9]*)$"
                            onChange={handleInputChangeLookup}
                          />
                          <button data-testid="look-up-retrieve-btn" className="fsa-btn fsa-btn--primary m-0" disabled={lookup.length < 6} onClick={fetchDayForceInfo}>
                            Retrieve
                          </button>
                        </div>
                        {message && <span className={islookupError ? 'validation-message' : 'success-message'}>{message}</span>}
                      </div>
                    </div>
                    <div className="fsa-grid__1/2"></div>
                  </>
                )}
              </div>
              <div
                className="fsa-grid"
                style={{
                  marginTop: '5px',
                }}
              >
                {selectedBUOW?.indexSheet &&
                  Object.keys(selectedBUOW?.indexSheet).length > 0 &&
                  Object.keys(selectedBUOW?.indexSheet)?.map((itm) => {
                    return (
                      <React.Fragment>
                        {(selectedBUOW?.indexSheet[itm].type === 'text' || selectedBUOW?.indexSheet[itm].type === 'number') && (
                          <div className="fsa-grid__1/2">
                            <div className="fsa-field" data-testid="text-input-fields">
                              <label className="fsa-field__label" htmlFor={itm}>
                                <b>
                                  {itm} {renderIsRequiredSymbol(itm)}
                                </b>
                              </label>
                              <input
                                className="fsa-input fsa-field__item"
                                id={itm}
                                name={itm}
                                aria-describedby={itm}
                                aria-required="false"
                                value={indexSheetData?.[itm]}
                                type={selectedBUOW?.indexSheet[itm].type}
                                placeholder={itm}
                                onChange={handleInputChange}
                              />
                              {renderRequiredErrMsg(itm)}
                            </div>
                          </div>
                        )}

                        {selectedBUOW?.indexSheet[itm].type === 'boolean' && (
                          <div className="fsa-grid__1/2 d-flex flex-column justify-content-center">
                            <div className="" style={{ display: 'flex', alignItems: 'center ', width: '100%' }} data-testid="boolean-fields">
                              <label style={{ width: '50%', margin: '0' }} htmlFor={itm}>
                                <b>
                                  {itm} {renderIsRequiredSymbol(itm)} :{' '}
                                </b>
                              </label>
                              <div className="fsa-field__item" style={{ display: 'flex', alignItems: 'center ', justifyContent: 'space-evenly' }}>
                                <input type="radio" name={itm} value={'Active'} checked={indexSheetData[itm] === 'Active' ? true : false} onChange={handleInputChange} /> Active
                                <input type="radio" name={itm} value={'Inactive'} checked={indexSheetData[itm] === 'Inactive' ? true : false} onChange={handleInputChange} />{' '}
                                Inactive
                              </div>
                            </div>
                            {renderRequiredErrMsg(itm)}
                          </div>
                        )}
                        {selectedBUOW?.indexSheet[itm].type === 'multicheckbox' && (
                          <div className="fsa-grid__1/2 d-flex flex-column justify-content-center">
                            <div className="fsa-field__item" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} data-testid="boolean-fields">
                              <label htmlFor={itm} style={{ width: '100%' }}>
                                <b>
                                  {itm} {renderIsRequiredSymbol(itm)}{' '}
                                </b>
                              </label>

                              {/* Render checkboxes based on statusCheckboxFields */}
                              {statusCheckboxFields?.map((values, index) => {
                                if (values.fieldName?.toLowerCase() === itm?.toLowerCase()) {
                                  return values.items?.map((field, idx) => (
                                    <div>
                                      <input
                                        className="fsa-checkbox"
                                        id={`${values.fieldName}-${field.id}-${idx}`}
                                        onChange={(e) => handleCheckChildElement(e, field.id, values.fieldName)}
                                        type="checkbox"
                                        aria-label="checkbox"
                                        checked={field.isCheck}
                                      />
                                      <label htmlFor={`${values.fieldName}-${field.id}-${idx}`}>{field.name}</label>
                                    </div>
                                  ));
                                }
                              })}
                            </div>
                            {renderRequiredErrMsg(itm)}
                          </div>
                        )}
                        {selectedBUOW?.indexSheet[itm].type === 'date' && (
                          <div className="fsa-grid__1/2">
                            <div className="fsa-field" data-testid="date-fields">
                              <label className="fsa-field__label" htmlFor={itm}>
                                <b>
                                  {itm} {renderIsRequiredSymbol(itm)}
                                </b>
                              </label>
                              <input
                                name={itm}
                                value={indexSheetData[itm] !== '' && indexSheetData[itm] !== undefined ? moment(indexSheetData[itm]).format('yyyy-MM-DD') : ''}
                                className="fsa-input fsa-field__item"
                                type={selectedBUOW?.indexSheet[itm].type}
                                onChange={handleInputChange}
                              />
                              {selectedBUOW?.indexSheet[itm]?.is_required &&
                                selectedBUOW?.indexSheet[itm]?.is_required === true &&
                                (moment(indexSheetData[itm]).isValid() === false || indexSheetData[itm] === undefined) &&
                                isRequiredError === true &&
                                handleIsRequiredError()}
                            </div>
                          </div>
                        )}
                        {selectedBUOW?.indexSheet[itm].type === 'dropdown' && (
                          <div className="fsa-grid__1/2 d-flex flex-column justify-content-center">
                            <div className="" style={{ display: 'flex', alignItems: 'center ', width: '100%' }}>
                              <label style={{ width: '50%', margin: '0' }} htmlFor={itm}>
                                <b>
                                  {itm} {renderIsRequiredSymbol(itm)} :{' '}
                                </b>
                              </label>

                              <AsyncSelect
                                isClearable
                                defaultOptions
                                value={indexSheetData?.[itm]}
                                loadOptions={(inputValue, callback) => {
                                  if (inputValue === '') {
                                    // let options = [];
                                    // colData?.lowCardValue.map((res) => {
                                    // options.push({ label: res, value: res });
                                    // setTimeout(() => {
                                    // callback(options);
                                    // }, 1000);
                                    // });
                                    // callback(options);
                                  } else {
                                    // let options = [];
                                    // const lowCardValuesData = (inputValue) => {
                                    // return colData?.lowCardValue.filter((i) => i?.toLowerCase().includes(inputValue?.toLowerCase()));
                                    // };
                                    // lowCardValuesData(inputValue).map((res) => {
                                    // options.push({ label: res, value: res });
                                    // setTimeout(() => {
                                    // callback(options);
                                    // }, 1000);
                                    // });
                                    // callback(options);
                                  }
                                }}
                                onChange={(e) => onChangeDocType(e, itm)}
                              />
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}

                {pageColumnData
                  ?.sort((a, b) => a.systemFolderLevel - b.systemFolderLevel)
                  ?.map((colData, idx) => {
                    return (
                      colData?.name !== 'ObjectType' && (
                        <div className="fsa-grid__1/2" data-testid="systemFolder-data-container">
                          <div className="fsa-field">
                            <label className="fsa-field__label" htmlFor={colData?.name}>
                              {colData?.name}
                              {returnRequiredFields(colData)}
                            </label>
                            {
                              colData?.lowCard === false ? (
                                <>
                                  <input
                                    className="fsa-input fsa-field__item"
                                    id={colData?.name}
                                    name={colData?.name}
                                    aria-describedby={colData?.name}
                                    aria-required="false"
                                    type="text"
                                    value={indexSheetData?.system_folders !== undefined && indexSheetData?.system_folders[colData.name]}
                                    key={defaultKey}
                                    placeholder=""
                                    onKeyUp={(e) => {
                                      if (e.key === 'Backspace' || e.key === 'Delete') {
                                        handleColumnData(e);
                                      } else if (e.target.value === '') {
                                        handleColumnData(e);
                                      }
                                    }}
                                    onChange={handleColumnData}
                                    data-testid={'system-folder-textField'}
                                  />

                                  {indexSheetData?.system_folders !== undefined
                                    ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                                      ? handleIsRequiredError()
                                      : (indexSheetData?.system_folders[colData?.name] === '' ||
                                          indexSheetData?.system_folders[colData?.name] === undefined ||
                                          indexSheetData?.system_folders[colData?.name] === null) &&
                                        isRequiredError === true &&
                                        handleIsRequiredError()
                                    : handleIsRequiredError()}
                                </>
                              ) : (
                                <>
                                  <AsyncSelect
                                    isClearable
                                    defaultOptions
                                    value={
                                      Object.keys(indexSheetData).length !== 0 &&
                                      Object.keys(indexSheetData?.system_folders).length !== 0 && { label: indexSheetData?.system_folders[colData?.name] }
                                    }
                                    loadOptions={(inputValue, callback) => {
                                      if (inputValue === '') {
                                        let options = [];
                                        colData?.lowCardValue?.map((res) => {
                                          options.push({ label: res, value: res });
                                          setTimeout(() => {
                                            callback(options);
                                          }, 1000);
                                        });
                                        callback(options);
                                      } else {
                                        let options = [];
                                        const lowCardValuesData = (inputValue) => {
                                          return colData?.lowCardValue.filter((i) => i?.toLowerCase().includes(inputValue?.toLowerCase()));
                                        };
                                        lowCardValuesData(inputValue)?.map((res) => {
                                          options.push({ label: res, value: res });
                                          setTimeout(() => {
                                            callback(options);
                                          }, 1000);
                                        });
                                        callback(options);
                                      }
                                    }}
                                    onChange={(e) => onChangeDocType(e, colData?.name)}
                                    data-testid="system-folder-dropdownField"
                                  />
                                  {indexSheetData?.system_folders !== undefined
                                    ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                                      ? handleIsRequiredError()
                                      : (indexSheetData?.system_folders[colData?.name] === '' ||
                                          indexSheetData?.system_folders[colData?.name] === undefined ||
                                          indexSheetData?.system_folders[colData?.name] === null) &&
                                        isRequiredError === true &&
                                        handleIsRequiredError()
                                    : handleIsRequiredError()}
                                </>
                              )
                              // : colData?.name === 'ObjectType' ? null : (
                              // <>
                              // <AsyncSelect
                              // isClearable
                              // defaultOptions
                              // value={
                              // Object.keys(indexSheetData).length !== 0 &&
                              // Object.keys(indexSheetData?.system_folders).length !== 0 && { label: indexSheetData?.system_folders[colData?.name] }
                              // }
                              // loadOptions={(inputValue, callback) => {
                              // if (inputValue === '') {
                              // let options = [];
                              // sectionList.map((res) => {
                              // options.push({ label: res.display_name, value: res.id });
                              // setTimeout(() => {
                              // callback(options);
                              // }, 1000);
                              // });
                              // callback(options);
                              // } else {
                              // let options = [];
                              // sectionFilterData(inputValue).map((res) => {
                              // options.push({ label: res.display_name, value: res.id });
                              // setTimeout(() => {
                              // callback(options);
                              // }, 1000);
                              // });
                              // callback(options);
                              // }
                              // }}
                              // onChange={(e) => {
                              // onChangeDocType(e, colData?.name);
                              // }}
                              // />
                              // {indexSheetData?.system_folders !== undefined
                              // ? Object.keys(indexSheetData?.system_folders).length === 0 && isRequiredError === true
                              // ? handleIsRequiredError()
                              // : (indexSheetData?.system_folders[colData.name] === '' || indexSheetData?.system_folders[colData.name] === undefined) &&
                              // isRequiredError === true &&
                              // handleIsRequiredError()
                              // : handleIsRequiredError()}
                              // </>
                              // )
                            }
                          </div>
                        </div>
                      )
                    );
                  })}
                <div style={{ display: 'flex', alignItems: 'center' }} className="fsa-grid__1/2">
                  <div data-testid="version-number-container" className="fsa-field" style={{ width: '100%' }}>
                    <label data-testid="version-number-label" style={{ width: '50%', margin: '0' }} htmlFor="sections">
                      <b>Version Number: </b>
                    </label>
                    <div data-testid="version-number-input" className="fsa-field__item">
                      <AsyncSelect
                        defaultOptions
                        value={{ label: indexSheetData?.VersionNumber }}
                        onChange={(e) => {
                          onChangeVerNum(e, 'VersionNumber');
                        }}
                      />
                      {indexSheetData?.VersionNumber !== undefined || (indexSheetData?.VersionNumber !== '' && isRequiredError === true && handleIsRequiredError())}
                      {indexSheetData?.VersionNumber !== '1.0' && isRequiredError === true && (
                        <span className={'requiredErrorMsgStyle'}> "{JSON.parse(sessionStorage.getItem('user_information'))?.contextId} version should be 1.0"</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Modal.Footer style={{ padding: '20px 0' }}>
                <input data-testid="index-sheet-submit" type="submit" style={{ marginLeft: 'auto' }} className="fsa-btn fsa-btn--primary" value={'Submit'} />
                <button data-testid="index-sheet-cancel" type="reset" style={{ marginRight: 'auto' }} className="fsa-btn fsa-btn--secondary" onClick={closePopupActions}>
                  Cancel
                </button>
              </Modal.Footer>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndexSheetModalV2;
