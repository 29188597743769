import React, { useEffect, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '@progress/kendo-react-dialogs';
import UserContext from '../UserContext/UserContext';

export default function KeywordSearch(props) {
  const { handleKeywordSearch, elementRef } = props;
  const [keywords, setKeywords] = useState('');
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const { userPermissions } = React.useContext(UserContext);

  useEffect(() => {
    handleKeywordSearch('');
  }, [keywords === '']);

  const handleKeywordChange = (e) => {
    setKeywords(e.target.value);
  };

  const handleAdvModal = () => {
    setSearchPopupOpen(!searchPopupOpen);
  };

  const onSearchClick = () => {
    handleKeywordSearch(keywords);
  };
  // const defaultModalLocation = { left: 105, top: 235 };

  return (
    <div>
      {userPermissions['Search_Transfer_Management_By_Keyword'] === true && (
        <div className="formRow">
          <div className="form-field">
            <label className="fsa-field__label" htmlFor="keywordSearch">
              Keyword Search:
            </label>
            <Input id="keywordSearch" name="keywordSearch" value={keywords} onChange={handleKeywordChange} className="fsa-input fc-keyword-input" />
          </div>
          <div className="form-field">
            <button className="fsa-btn" onClick={onSearchClick}>
              Search
            </button>
          </div>
        </div>
      )}

      {userPermissions['Search_Transfer_By_Advance_Search'] === true && (
        <div className="flexrow underKeywordContainer">
          <div className="modal-link-con" ref={elementRef}>
            <p className="modal-link" style={{ cursor: 'pointer' }} onClick={handleAdvModal}>
              Advanced Search
              <span>
                {searchPopupOpen ? (
                  <FontAwesomeIcon onClick={handleAdvModal} className="float-right chevron" icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                )}
              </span>
            </p>
          </div>
        </div>
      )}
      {searchPopupOpen && (
        <Dialog
          minimizeButton={() => null}
          maximizeButton={() => null}
          title={'Advanced Search'}
          onClose={handleAdvModal}
          // initialHeight={725}
          // initialWidth={750}
          // initialLeft={modalLocation?.left || defaultModalLocation.left}
          // initialTop={modalLocation?.top + 45 || defaultModalLocation.top + 45}
          className="glbModalHeader"
        >
          {/* pass the onCLose so that the child component can close this window if needed */}
          {React.cloneElement(props.children, { onClose: handleAdvModal })}
        </Dialog>
      )}
    </div>
  );
}
