import React, { useState, useEffect, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import './AdminSearchPage.scss';
import _ from 'lodash';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import FetchLocalStorageDataByPath from '../../../utils/helper/FetchLocalstorageDataByPath';

import SystemFolder from '../../../fsa-style/img/svgs/SystemFolder.svg';
import UserPreference from '../../../fsa-style/img/svgs/UserPreference.svg';
import UserGuide from '../../../fsa-style/img/svgs/User_guide2.svg';
import InBasketIcon from '../../../fsa-style/img/svgs/InBasketIcon.svg';
import UnitOfWorkIcon from '../../../fsa-style/img/svgs/UnitOfWorkIcon.svg';
import MessageIcon from '../../../fsa-style/img/svgs/MessageIcon.svg';
import WorkgroupUserXwalkIcon from '../../../fsa-style/img/svgs/WorkgroupUserXwalkIcon.svg';
import RoutingOrchestrationIcon from '../../../fsa-style/img/svgs/RoutingOrchestrationIcon.svg';
import RuleDefinitionIcon from '../../../fsa-style/img/svgs/RuleDefinitionIcon.svg';
import RolesTableIcon from '../../../fsa-style/img/svgs/rolesTable.svg';
import PermissionsTableIcon from '../../../fsa-style/img/svgs/permissionsTable.svg';
import UsersTableIcon from '../../../fsa-style/img/svgs/usersTable.svg';
import BuowCrudIcon from '../../../fsa-style/img/svgs/BuowCrud.svg';
import RuleTriggerIcon from '../../../fsa-style/img/svgs/RuleTriggerIcon.svg';
import RuleLog from '../../../fsa-style/img/svgs/RuleLog.svg';
import WorkgrupIcon from '../../../fsa-style/img/svgs/WorkgrupIcon.svg';
import RoutingStepsIcon from '../../../fsa-style/img/svgs/RoutingStepsIcon.svg';
import SystemConfigurationIcon from '../../../fsa-style/img/svgs/SystemConfigurationIcon.svg';
import BUOWDisplayNameIcon from '../../../fsa-style/img/svgs/BUOWDisplayNameLogo.svg';
//with lazy

const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));

// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';

const AdministratorPanel = () => {
  let roleName = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.rolesName;
  const isAdmin = roleName?.includes('Admin');

  const initialCardData = [
    {
      title: 'Routing Orchestration',
      buttonName: 'View More ',
      pageUrl: '/orchestration',
      icon: RoutingOrchestrationIcon,
      id: 1,
    },
    {
      title: 'Routing Steps',
      buttonName: 'View More ',
      pageUrl: '/routingsteps',
      icon: RoutingStepsIcon,
      id: 2,
    },
    {
      title: 'System Configuration',
      buttonName: 'View More ',
      pageUrl: '/systemconfiguration',
      icon: SystemConfigurationIcon,
      id: 3,
    },
    {
      title: 'Buow Display Name',
      buttonName: 'View More ',
      pageUrl: '/buowdisplayname',
      icon: BUOWDisplayNameIcon,
      id: 4,
    },
    {
      title: 'Rule Definition',
      buttonName: 'View More ',
      pageUrl: '/ruledefinition',
      icon: RuleDefinitionIcon,
      id: 5,
    },
    {
      title: 'Users',
      buttonName: 'View More ',
      pageUrl: '/userstable',
      icon: UsersTableIcon,
      id: 6,
    },
    {
      title: 'Roles',
      buttonName: 'View More ',
      pageUrl: '/rolestable',
      icon: RolesTableIcon,
      id: 7,
    },
    {
      title: 'Permissions',
      buttonName: 'View More',
      pageUrl: '/permissionstable',
      icon: PermissionsTableIcon,
      id: 8,
    },
    {
      title: 'Rule Trigger',
      buttonName: 'View More ',
      pageUrl: '/ruletrigger',
      icon: RuleTriggerIcon,
      id: 9,
    },
    {
      title: 'Work Group',
      buttonName: 'View More ',
      pageUrl: '/work-group',
      icon: WorkgrupIcon,
      id: 10,
    },

    {
      title: 'System Folder',
      buttonName: 'View More ',
      pageUrl: '/system-folder',
      icon: SystemFolder,
      id: 11,
    },
    {
      title: 'User Preference',
      buttonName: 'View More ',
      pageUrl: '/user-preference',
      icon: UserPreference,
      id: 12,
    },
    {
      title: 'In Basket',
      buttonName: 'View More ',
      pageUrl: '/inbaskettable',
      icon: InBasketIcon,
      id: 13,
    },
    {
      title: 'BUOW',
      buttonName: 'View More ',
      pageUrl: '/buowtable',
      icon: BuowCrudIcon,
      id: 14,
    },
    {
      title: 'Unit of Work',
      buttonName: 'View More ',
      pageUrl: '/unit-of-work',
      icon: UnitOfWorkIcon,
      id: 15,
    },
    {
      title: 'Messages',
      buttonName: 'View More ',
      pageUrl: '/messages',
      icon: MessageIcon,
      id: 16,
    },
    {
      title: 'Work Group User Xwalk',
      buttonName: 'View More ',
      pageUrl: '/workgroupuserxwalk',
      icon: WorkgroupUserXwalkIcon,
      id: 17,
    },
    {
      title: 'Rule Log',
      buttonName: 'View More ',
      pageUrl: '/rule-log',
      icon: RuleLog,
      id: 18,
    },
  ];

  const [cardData, setCardData] = useState(initialCardData);
  const history = useHistory();
  const [filteredCardData, setFilteredCardData] = useState(cardData);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    renderPermittedCards();
  }, []);
  const handleCardButton = (pageUrl) => {
    history.push(pageUrl);
  };

  const renderPermittedCards = async () => {
    try {
      const fetchedLocalStorageData = FetchLocalStorageDataByPath('system_config', 'adminPanel');

      let filteredCardsData = await fetchedLocalStorageData.filter((itm) => {
        if (itm.value === true) return itm;
      });

      let isSuperUser = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.superUser;
      const isAuthorized = roleName?.includes('Admin');

      let data = await initialCardData.filter((itm, i) => {
        let itemFound = _.find(filteredCardsData, { title: itm.title });
        if (itemFound && itemFound.title !== 'System Configuration' && itemFound.title !== 'System Folder') return itm;
      });
      if (isSuperUser === true)
        data.push({
          title: 'System Configuration',
          buttonName: 'View More ',
          pageUrl: '/systemconfiguration',
          icon: SystemConfigurationIcon,
          id: 0,
        });
      if (isSuperUser === true || isAuthorized === true) {
        data.push({
          title: 'System Folder',
          buttonName: 'View More ',
          pageUrl: '/system-folder',
          icon: SystemFolder,
          id: 1,
        });
      }
      if (process.env.REACT_APP_GUIDE_VISIBLE === 'true' || process.env.REACT_APP_GUIDE_VISIBLE === true)
        data.push({
          title: 'User Guide',
          buttonName: 'View More',
          pageUrl: '/userguide',
          icon: UserGuide,
          id: 2,
        });

      setCardData(data);
      setFilteredCardData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const renderCard = (card) => {
    return (
      <>
        <section className="col-3 card-section" key={`${card.id}_admin_card`}>
          <div className="card admin-panel-card mt-2 mb-3">
            <div className="card-circle mx-auto white">
              <div className=" dashboardIconOuterWhite">
                <div className="dashboardIconOuter">
                  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" fill="none">
                    <circle cx="56.5" cy="56.5" r="56.5" fill="white" />
                  </svg>
                  <img className={card.icon === UserGuide ? 'userGuideIcon' : 'card-icon'} src={card.icon} alt="icon"></img>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <h4 className="card-title">{card.title}</h4>
              <div className="horizontalBar">
                <span>
                  <hr className="horizontalBarContent"></hr>
                </span>
              </div>
              <h6 className="card-button-name">
                <CustomButton className="admin-panel-button" parentWarpper={'w-100'} title={card.buttonName} onClick={() => handleCardButton(card.pageUrl)} />
              </h6>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <main className="main-admin-panel">
        {isLoading && <GlobalLoader />}

        <div className="main-card-content flex-column">
          <div className="row col-md-12 main-card-content mt-5">
            {filteredCardData.map((card, index) => (
              <React.Fragment key={`${index}_admin_panels_cruds`}>
                {/* {JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Application Administrator') === true && card.title === 'Users' && renderCard(card)} */}
                {((JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Application Administrator') === true && card.title === 'Users') ||
                  JSON.parse(sessionStorage.getItem('user_information')).rolesName?.includes('Admin') === true) &&
                  renderCard(card)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default AdministratorPanel;
