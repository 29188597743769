import React, {  useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './UpdateHoldModal.module.scss';
import '../../../../GlobalStyle.scss';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import ResizeIcon from '../../../../fsa-style/img/svgs/ResizeIcon.svg';
// import CustomButton from '../../../../newcomponents/CustomButton/CustomButton';
// import { Window } from "@progress/kendo-react-dialogs";
import UpdateHoldForm from "./UpdateHoldForm";
import UpdateHoldFields from './UpdateHoldFields.json'
import SelectableTable from "./Table/SelectableTable";
// import { searchHolds } from "../../../../services/recordsManagementService";
// import axios from "axios";
// import UserContext from "../../../../components/UserContext/UserContext";
// import { useNotificationContext } from "../../../../components/Notification/NotificationProvider";
// import { RecordReferencesProvider } from "../../../../components/RecordReferences/RecordReferencesProvider";
import { convertDateToString } from "../../../../components/FormModules/FormDatepicker";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

const UpdateHoldModal = (props) => {
    const [isRequiredError, setisRequiredError] = useState(false);
    const {handleUpdateHold}=props

    // const { visible, onClose, recordIds } = props;
    // const { userInfo } = useContext(UserContext);
    // const { addNotification } = useNotificationContext();
    const initialData = [
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"
        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"
        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        },
        {
            holdName: "abcd",
            holdtype: "Litigation",
            responsibleOfficer: "John Masters",
            participants: "Jeffrey Champa 001319",
            effectiveDate: "2023-05-22",
            holdScope: "Employee Action forms and evaluations",
            holdstatus: "Active",
            holdNumber: "2023-05-22-922062000",
            expirationDate: "12",
            holdReleaseDate: "122",
            responsibleOfficerEmail: "brillent@gmail.com"

        }
    ]
    const [holdSearchResults, setHoldSearchResults] = useState(initialData);
    const [page, setPage] = useState(0);
    const pageSize = 14

    const onSearchSubmit = (search) => {
        const effectiveDate = convertDateToString(search.values.effectiveDate)
        const expirationDate = convertDateToString(search.values.expirationDate)

        const data = omitBy({
            ...search.values,
            effectiveDate,
            expirationDate,
        }, isEmpty)

        // searchHolds(data)
        //     .then((r) => {
        //         setHoldSearchResults(r.data)
        //         setPage(0)
        //     })
        //     .catch((err) => {
        //         console.error(err)
        //         addNotification({ style: 'error', msg: `${err}` })
        //     })
    }

    // const updateObjectsWithHolds = (holds) => {
    //     const promises = holds.map(({ holdNumber }) => {
    //         const url = `/recordsmanagementapi/records-management/holds/add?holdNumber=${holdNumber}`
    //         return axios.put(url, recordIds, {
    //             headers: {
    //                 userName: userInfo.name || '',
    //                 usdaeauthid: userInfo.usdaeauthid || '',
    //                 // Authorization: sessionStorage.getItem('userJwt') || '',
    //             }
    //         })
    //     })

    //     Promise.all(promises)
    //         .then(() => {
    //             addNotification({ style: 'success', msg: "Object(s) updated with hold(s)" })
    //             onClose()
    //         })
    //         .catch((err) => {
    //             addNotification({ style: 'error', msg: `Cannot update object(s). ${err}` })
    //             console.error(err)
    //         })
    // }

    const onPageChange = ({ page }) => {
        const newPage = page.skip / page.take
        setPage(newPage)
    }

    // useEffect(() => {
    //     onSearchSubmit({
    //         values:
    //         {
    //             holdstatus: "Active"
    //         }
    //     })
    // }, [visible])

    // if (!visible) {
    //     return null;
    // }




    return (
        <Modal
            {...props}
            className={[styles.updateHoldModal, 'glbUpdateHoldModalStyle'].join(' ')}
            backdropClassName="orc-modal-custom-backdrop"
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
            onHide={() => {
                props.onHide();
            }}
        >
            <div>
                <Modal.Header>
                    <div className={styles.modalHeader}>
                        <div>
                            <b className={styles.headingText}>Update Object Hold</b>
                        </div>
                        <div
                            className={styles.modalHeaderIcon}

                        >
                            <img className={styles.resizeIcon} src={ResizeIcon} alt='resize icon' />
                            <img src={crossIcon} alt="cross icon" onClick={() => {
                                handleUpdateHold(false);
                                setisRequiredError(false);
                            }} />

                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className={styles.updateHoldModalBody}>

                    {/* <RecordReferencesProvider> */}
                    {/* <Window
                            minimizeButton={() => null}
                            maximizeButton={() => null}
                            title={"Update Object Hold"}
                            // onClose={onClose}
                            initialHeight={850}
                            initialWidth={1200}
                            style={{ zIndex: 1, padding: '1rem' }}
                        > */}
                    <div className={'row'}>
                        <div className={'col-3 pb-5'}>
                            <h3 style={{ marginTop: 0 }}>Search Holds</h3>
                            <div className="fsa-divider" />
                            <UpdateHoldForm
                            //  onClose={onClose} 
                             onSubmit={onSearchSubmit}
                            />
                        </div>

                        <div className={'col-9'} style={{borderLeft:'1px solid #D9D9D9'}}>
                            <h3 style={{ marginTop: 0 }}>Hold Search Results</h3>
                            <div className="fsa-divider" />
                            <SelectableTable
                                data={holdSearchResults.slice(page * pageSize, pageSize * page + pageSize)}
                                fields={UpdateHoldFields}
                                total={holdSearchResults.length}
                                // onCancel={onClose}
                                actionText={'Update'}
                                // onAction={updateObjectsWithHolds}
                                onPageChange={onPageChange}
                                page={page}
                                pageSize={pageSize}
                                handleUpdateHold={handleUpdateHold}

                            />

                        </div>
                    </div>
                    {/* </Window>
                    </RecordReferencesProvider> */}

                </Modal.Body>
                {/* <Modal.Footer className={styles.updateHoldFooter}>
                    <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>


                        {props.isNew ? (
                            <CustomButton title="Save" className={styles.saveRecordBtn} />
                        ) : (
                            <CustomButton title="Update" className={styles.saveRecordBtn} />
                        )}

                        <CustomButton
                            title="Cancel"
                            className={styles.cancelRecordBtn}
                            onClick={() => {
                                props.setShowUpdateHold(false);
                                setisRequiredError(false);
                            }}
                        />
                    </div>
                </Modal.Footer> */}
            </div>
        </Modal>
    );
};

export default UpdateHoldModal;