import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { calculateWidth } from './Table';
import { Checkbox } from '@progress/kendo-react-inputs';
import UserContext from '../UserContext/UserContext';
import searchImg from '../../fsa-style/img/searchImg.svg';
import { SingleObjectViewDetailsModal } from '../../newcomponents/SingleObjectViewDetailsModal/SingleObjectViewDetailsModal';

const headerStyle = (column) => {
  return (
    <a style={{ textAlign: 'center', backgroundColor: '#25a4db' }} className="k-link">
      <span style={{ fontWeight: 'bold', color: 'white', fontSize: '16px', paddingRight: '1em' }} onClick={column.onClick}>
        {column.title}{' '}
      </span>
      {column.children} {column.sort}
    </a>
  );
};

const SELECTED_FIELD = 'selected';

export default function SelectableTable(props) {
  const { data = [], fields, onCancel, actionText, onAction, onPageChange, total = data.length, page = 0, pageSize = 10, dataKeyItem = 'id' } = props;
  const [selected, setSelected] = useState([]);
  const { userPermissions } = React.useContext(UserContext);
  const [openSingleObjectPopup, setOpenSingleObjectPopup] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const onTableAction = () => {
    const actionData = data.filter((d) => selected.includes(d[dataKeyItem]));
    if (onAction) {
      onAction(actionData);
    }
  };

  useEffect(() => {});

  const onCheckboxClick = (id) => {
    const index = selected.indexOf(id);
    // deselect
    if (index >= 0) {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
    }
    // select
    else {
      setSelected([...selected, id]);
    }
  };

  // const onCheckboxAllClick = (e) => {
  //   const isAllCheckboxChecked = selected.length === data.length;
  //   // select all
  //   if (!isAllCheckboxChecked) {
  //     const newSelected = data.map((row) => row[dataKeyItem]);
  //     setSelected(newSelected);
  //   }
  //   // deselect all
  //   else {
  //     setSelected([]);
  //   }
  // };

  const clickCell = (props) => {
    const id = props.dataItem[dataKeyItem];
    return (
      <td>
        <div className="selectCell">
          <Checkbox checked={selected.includes(id)} name="rowsCheckbox" id={props.id} onChange={() => onCheckboxClick(id)} />
        </div>
      </td>
    );
  };

  const headerCell = (props) => {
    return <div>{/* <Checkbox checked={selected.length === data.length} id={props.id} onChange={onCheckboxAllClick} /> */}</div>;
  };
  const onRowClick = (props) => {
    setOpenSingleObjectPopup(true);
    setRecordId(props?.dataItem?._id);
  };

  return (
    <div>
      <Grid
        selectedField={SELECTED_FIELD}
        data={data}
        total={total}
        pageSize={pageSize}
        skip={page * pageSize}
        onPageChange={(page) => {
          onPageChange(page);
          setSelected([]);
        }}
        pageable={{
          buttonCount: 10,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 15, 20, 25],
          previousNext: true,
        }}
      >
        {data.length &&
          fields.map((column, index) => {
            if (column.field === 'SELECTED_FIELD') {
              return <Column headerCell={headerCell} headerClassName={'grid-column-header'} field={SELECTED_FIELD} cell={clickCell} title={column.title} key={index} />;
            } else if (column.field === 'VIEW_DETAILS') {
              return (
                <Column
                  headerCell={headerCell}
                  headerClassName={'grid-column-header'}
                  field=""
                  cell={(dataItem) => {
                    return (
                      <td>
                        <div className="textCenter">
                          <img src={searchImg} style={{ cursor: 'pointer' }} onClick={() => onRowClick(dataItem)} alt={'searchImg'}></img>
                        </div>
                      </td>
                    );
                  }}
                  title=""
                  width={100}
                  filterable={false}
                />
              );
            } else {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={column.cell}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                />
              );
            }
          })}
      </Grid>

      <div className="mt-4">
        <div style={{ display: 'flex' }} className={'justify-content-end'}>
          {userPermissions['Remove_Objects_From_Hold'] === true && (
            <button className="fsa-btn" onClick={onTableAction} disabled={selected.length <= 0}>
              {actionText}
            </button>
          )}
          <button className="fsa-btn fsa-btn--secondary" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
      {openSingleObjectPopup === true && (
        <SingleObjectViewDetailsModal
          setOpenObjectPopup={setOpenSingleObjectPopup}
          openObjectPopup={openSingleObjectPopup}
          recordId={recordId}
          setRecordId={setRecordId}
          getData={props.getData}
        />
      )}
    </div>
  );
}
