import { useEffect, useState } from 'react';
import './ProgressBar.scss';
const VerticleProgressBar = ({ value }) => {
  useEffect(() => {
    isNaN(value) === true ? setVal(0) : setVal(value);
  }, [value]);
  let [val, setVal] = useState(value);
  return (
    <>
      {val !== null && val !== undefined && (
        <>
          <div className="progress-bar-container truncated-text-confidenceBar" title={val}>
            <div
              className="progress-bars progress-bar-striped "
              style={{
                height: val + '%',
                backgroundColor: val > 90 && val <= 100 ? '#aecf9e' : val >= 25 && val <= 90 ? '#c0c050' : '#de3535',
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};
export default VerticleProgressBar;
