import {TextArea} from "@progress/kendo-react-inputs";
import React from "react";

export default function FormTextArea(props) {
  const {name, value, onChange} = props;

  return (
    <TextArea
      className="fsa-field__item"
      rows={3}
      autoSize={true}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}