import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import axios from 'axios';
import HoldTypeDropDownList from './FormModules/HoldTypeDropDownList';
import FormTextArea from './FormModules/FormTextArea';
import HoldStatusRadioGroup from './FormModules/HoldStatusRadioGroup';
import ValidatedInput from '../FormModules/ValidatedInput';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useContext, useState } from 'react';
import UserContext from '../UserContext/UserContext';
import FormDatepicker from '../FormModules/FormDatepicker';
import { convertDateToString } from '../FormModules/FormDatepicker';
import { emailValidator, requiredValidator } from '../FormModules/validators';
import { minEffectiveDateValidator } from './FormModules/validators';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';

export default function HoldManagementForm(props) {
  const { EventEmitter } = require('../../services/events');

  const { onClose, holdObject } = props;
  const { userInfo, userPermissions } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');

  const headers = {
    userName: userInfo.name || '',
    usdaeauthid: userInfo.usdaeauthid || '',
    Authorization: sessionStorage.getItem('userJwt'),
  };

  const onSubmit = async (hold) => {
    if (hold.holdName.trim() === '' && hold.responsibleOfficer.trim() === '') {
      hold.holdName = '';
      hold.responsibleOfficer = '';
      requiredValidator(hold.holdName);
      requiredValidator(hold.responsibleOfficer);
      return;
    }
    if (hold.holdName.trim() === '') {
      hold.holdName = '';
      requiredValidator(hold.holdName.trim());
      return;
    }
    if (hold.responsibleOfficer.trim() === '') {
      hold.responsibleOfficer = '';
      requiredValidator(hold.responsibleOfficer.trim());
      return;
    }

    const effectiveDate = convertDateToString(hold.effectiveDate);
    const expirationDate = convertDateToString(hold.expirationDate);

    const data = omitBy(
      {
        ...hold,
        effectiveDate,
        expirationDate,
      },
      isNil
    );
    setLoading(true);
    // update existing hold
    if (data?.holdstatus !== 'Completed' && holdObject && holdObject.holdNumber) {
      const url = `/recordsmanagementapi/records-management/holds/${holdObject.holdNumber}`;
      axios
        .put(url, data, {
          headers: headers,
        })
        .then(() => {
          setMessage('Hold updated successfully');
          setIsSuccessGrowlHidden(false);
          EventEmitter.emit('refreshHoldGrid');
          setTimeout(() => {
            setIsSuccessGrowlHidden(true);

            onClose();
            setLoading(false);
          }, 3000);

          //
        })
        .catch((err) => {
          setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);

          setTimeout(() => {
            setIsErrorGrowlHidden(true);
            setLoading(false);
          }, 3000);

          // addNotification({ style: 'error', msg: err.message });
        });
    } else if (data?.holdstatus === 'Completed' && holdObject && holdObject.holdNumber) {
      let url = `/recordsmanagementapi/records-management/holds/release/${holdObject.holdNumber}`;
      if (expirationDate !== undefined) url = url + `?expirationDate=${expirationDate}`;
      axios
        .put(
          url,
          {},
          {
            headers: headers,
          }
        )
        .then(() => {
          setMessage('Hold updated successfully');
          setIsSuccessGrowlHidden(false);
          EventEmitter.emit('refreshHoldGrid');
          setTimeout(() => {
            setIsSuccessGrowlHidden(true);

            onClose();
            setLoading(false);
          }, 3000);

          //
        })
        .catch((err) => {
          setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);

          setTimeout(() => {
            setIsErrorGrowlHidden(true);
            setLoading(false);
          }, 3000);

          // addNotification({ style: 'error', msg: err.message });
        });
    }
    // create new hold
    else {
      const url = '/recordsmanagementapi/records-management/holds';
      axios
        .post(url, data, {
          headers: headers,
        })
        .then(() => {
          // addNotification({ style: 'success', msg: 'New hold created' });
          setMessage('New hold created successfully');
          setIsSuccessGrowlHidden(false);
          EventEmitter.emit('refreshHoldGrid');

          setTimeout(() => {
            setIsSuccessGrowlHidden(true);
            onClose();
            setLoading(false);
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
          setMessage(err.message ? err.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);

          setTimeout(() => {
            onClose();
            setIsErrorGrowlHidden(true);
            setLoading(false);
          }, 3000);
          // addNotification({ style: 'error', msg: err.message });
        });
    }
  };

  // The conditional values for the existing hold object are a temporary patch to fix errors when displaying the form
  const initialValues = holdObject
    ? holdObject
    : {
        holdstatus: 'Active',
        holdtype: 'Audit',
        effectiveDate: new Date(),
      };

  return (
    <>
      {loading && <GlobalLoader />}
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={(fieldRenderProps) => {
          const holdNumber = fieldRenderProps.valueGetter('holdNumber');
          return (
            <div>
              <div className="row">
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Hold Name*</label>
                  <Field name={'holdName'} className="fsa-input fsa-field__item" component={ValidatedInput} validator={requiredValidator} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Type of Hold</label>
                  <Field name={'holdtype'} component={HoldTypeDropDownList} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Responsible Staff Email*</label>
                  <Field name={'responsibleOfficerEmail'} className="fsa-input fsa-field__item" component={ValidatedInput} validator={emailValidator} />
                </FieldWrapper>
              </div>
              <div className={'row mt-4'}>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Responsible Staff*</label>
                  <Field name={'responsibleOfficer'} className="fsa-input fsa-field__item" component={ValidatedInput} validator={requiredValidator} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Hold Effective Date</label>
                  <Field name={'effectiveDate'} component={FormDatepicker} />
                </FieldWrapper>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Hold Expiration Date</label>
                  <Field name={'expirationDate'} className=" fsa-field__item" component={FormDatepicker} validator={minEffectiveDateValidator} />
                </FieldWrapper>
              </div>
              <div className={'row mt-4'}>
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Hold Declaration Status</label>
                  <Field name={'holdstatus'} component={HoldStatusRadioGroup} />
                </FieldWrapper>
                {holdNumber ? (
                  <FieldWrapper className={'col-4'}>
                    <label className="fsa-field__label">Hold Number</label>
                    <p>{holdNumber}</p>
                  </FieldWrapper>
                ) : null}
              </div>
              <div className={'row mt-4'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Participants/Litigants</label>
                  <Field name={'participants'} component={FormTextArea} />
                </FieldWrapper>
              </div>
              <div className={'row mt-4'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Hold Scope</label>
                  <Field name={'holdScope'} component={FormTextArea} />
                </FieldWrapper>
              </div>
              <div className="row mt-5">
                <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                  {userPermissions['Update_Hold'] === true && (
                    <button className="fsa-btn" onClick={fieldRenderProps.onSubmit} disabled={!fieldRenderProps.allowSubmit}>
                      Save
                    </button>
                  )}
                  <button onClick={onClose} className="fsa-btn lite-btn">
                    Cancel
                  </button>
                </div>
              </div>
              <div>
                <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
                <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
              </div>
              {/* <HoldEditSuccessGrowl isHoldEditSuccessGrowlHidden={isHoldEditSuccessGrowlHidden} setIsHoldEditSuccessGrowlHidden={setIsHoldEditSuccessGrowlHidden} /> */}
            </div>
          );
        }}
      />
    </>
  );
}
