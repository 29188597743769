import axios from 'axios';
// import { serviceUserJwt } from './serviceConfig';

export async function userLogin(payload) {
  let uploadResponse = {};
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/smc_security_api/security/login';
    await axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: serviceUserJwt,
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse.error = ex.response.data;
  }
  return uploadResponse;
}

export async function userLogout(data) {
  let getDoctypeResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/smc_security_api/security/logout';

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + data.token,
        },
      })
      .then((response) => {
        getDoctypeResponse = response;
      });
  } catch (ex) {
    console.log('Error in getDoctype.js getDoctype()', ex.message);
  }

  return getDoctypeResponse;
}
