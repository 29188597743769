import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";

export default function HoldTypeDropDownList(props) {
  const {value, onChange} = props;
  const {holdtype = []} = useRecordReferencesContext()

  return (
    <DropDownList
      data={holdtype}
      className="fsa-field__item"
      onChange={onChange} value={value}
    />
  )
}