import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";

export default function VitalRadioGroup(props) {
  const {name, value, onChange, others} = props;

  const data = [{label: 'Yes', value: true}, {label: 'No', value: false}]

  return (
    <RadioGroup
      data={data}
      name={name}
      value={value}
      onChange={onChange}
      {...others}
    />
  )
}