const handleIsRequiredError = () => {
  return (
    <span className={'requiredErrorMsgStyle'} data-testid="required-error-message">
      This field is required *
    </span>
  );
};

const handleMaxLimitError = (maxLength) => {
  return <span className={'requiredErrorMsgStyle'}>Maximum {maxLength} char will be allowed *</span>;
};
const handleMinLimitError = (minLength) => {
  return <span className={'requiredErrorMsgStyle'}>Minimum {minLength} char will be allowed *</span>;
};

const handleCustomErrorMsg = (errorMsg) => {
  return <span className={'requiredErrorMsgStyle'}>{errorMsg}</span>;
};

export { handleIsRequiredError, handleMaxLimitError, handleCustomErrorMsg, handleMinLimitError };
