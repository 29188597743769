import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import crossIcon from '../../fsa-style/img/crossIcon.svg';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {
  return (
    <Modal show={showModal} onHide={handleClose} style={{zIndex:9999999999}}>
      <Modal.Header>
        <Modal.Title>You Have Been Idle!</Modal.Title>
        <img src={crossIcon} alt="close_img" onClick={handleClose} className='cursor' />
      </Modal.Header>
      <Modal.Body>You Will Get Timed Out Very Soon. Do you want to stay?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Stay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
