import { useEffect, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import UpdateObjectTransferForm from './UpdateObjectTransferForm';
import './UpdateObjectTransferForm.scss';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import axios from 'axios';
import UserContext from '../../UserContext/UserContext';
import { useContext } from 'react';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import ErrorGrowl from '../../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import { searchTransferData } from '../../../services/transferServices';

export default function UpdateObjectTransferModal(props) {
  const {  onClose, recordIds } = props;
  const [expand, setExpand] = useState('DEFAULT');
  const { userInfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [searchData, setSearchData] = useState([]);

  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };

  const onSearch = async (searchFilter = {}) => {
    let data = {};
    if (Object.keys(searchFilter).length > 0 && searchFilter.values) {
      const { username, transferName, agencyTransferNumber, NARATransferNumber, fileCode, holdNumber } = searchFilter.values;
      data = {};

      if (username !== '') data.transferCreatedBy = username;
      if (transferName !== '') data.transferName = transferName;

      if (agencyTransferNumber !== '') data.transferNumber = agencyTransferNumber;

      if (NARATransferNumber !== '') data.naraTransferNumber = NARATransferNumber;

      if (fileCode !== '') data.fileCode = fileCode;

      if (holdNumber !== '') data.holdNumber = holdNumber;
    }

    try {
      const response = await searchTransferData(data);
      setSearchData(response);
    } catch (error) {
      // Handle API error
      console.error('Error fetching transfer data:', error);
    }
  };
  useEffect(() => {
    onSearch();
  }, []);
  const assignTransfer = (TransferNumbers) => {
    setIsLoading(true);
    let ArrayData = [TransferNumbers];
    let uniqueIdsArray = [];
    for (let i = 0; i < recordIds.length; i++) {
      let isAggObject = recordIds[i].hasOwnProperty('aggregations');
      if (isAggObject === true) {
        let idArray = recordIds[i].aggregations.asMap.employee_data.hits.hits;
        for (let j = 0; j < idArray.length; j++) {
          uniqueIdsArray.push(idArray[j].id);
        }
      } else {
        uniqueIdsArray = [];
        uniqueIdsArray = recordIds;
      }
    }

    const promises = ArrayData.map(async ({ dataItem }) => {
      const url = `/recordsmanagementapi/records-management/transfers/add?transferNumber=${dataItem.transferNumber}`;

      let createResponse = [];

      let headers = {
        userName: userInfo.name || '',
        usdaeauthid: userInfo.usdaeauthid || '',
        // Authorization: sessionStorage.getItem('userJwt') || '',
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.put(url, uniqueIdsArray, { headers });
        createResponse.data = response.data;
        setIsLoading(false);
        setSuccessMsg('Object(s) updated with Transfer(s)');
        setIsSuccessGrowlHidden(false);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
          onClose();
        }, 3000);
      } catch (ex) {
        console.error('Error in EditMetadata()', ex.response.data.message);
        setErrMsg(`Cannot update object(s). ${ex.response.data.message}`);
        setIsLoading(false);
        setIsErrorGrowlHidden(false);
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 5000);

        // throw ex;
      }
    });

    Promise.all(promises)
      .then((data) => {
        // addNotification({ style: 'success', msg: 'Object(s) updated with hold(s)' });
      })
      .catch((err) => {
        // addNotification({ style: 'error', msg: `Cannot update object(s). ${err}` });

        console.error(err);
      });
  };
  return (
    <>
      {isLoading && <GlobalLoader />}

      <Window
        minimizeButton={() => null}
        maximizeButton={() => null}
        title={'Update Transfer'}
        onClose={onClose}
        initialHeight={850}
        initialWidth={900}
        style={{ padding: '1rem' }}
        className="updateObjTransfer"
        restoreButton={() => null}
        modal={true}
        draggable={false}
        stage={expand}
        closeButton={() => (
          <>
            <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
              {/* <b>X</b> */}
              <img src={FullscreenIcon} alt="FullScreen icon" />
            </button>
            <button
              aria-label="Close Detail View Window"
              style={{ backgroundColor: 'Transparent', border: 'none' }}
              onClick={() => {
                return onClose(), setExpand('DEFAULT');
              }}
            >
              {/* <b>X</b> */}
              <img src={crossIcon} alt="cross icon" />
            </button>
          </>
        )}
      >
        <UpdateObjectTransferForm onClose={onClose} onSubmit={assignTransfer} onSearch={onSearch} data={searchData} />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={errMsg} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={successMsg} />
      </Window>
    </>
  );
}
