import React from 'react';
import styles from './Breadcrumb.module.scss';

import PropTypes from 'prop-types';

/**
 * Breadcrumb component for displaying a navigation breadcrumb trail.
 *
 * This component is used to render a breadcrumb trail, which provides a visual representation of the navigation hierarchy within the application.
 *
 * @component
 */

const Breadcrumb = (props) => {
  return (
    <div className={styles.breadcrumbOuterDiv} data-testid="breadcrumb-container">
      <ul className={styles.breadcrumbListStyle}>
        {props.data.map((obj, index) => {
          return (
            <React.Fragment key={`${index}_breadcrum`}>
              <li>
                {obj.link === '' ? (
                  <a href={obj.link} className={styles.disabledLinkStyle}>
                    {obj.title}
                  </a>
                ) : (
                  <a href={obj.link}>{obj.title}</a>
                )}
              </li>
              {index !== props.data.length - 1 && (
                <li>
                  <span>/</span>
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};
export default Breadcrumb;
Breadcrumb.propTypes = {
  /**
   * An array of objects representing breadcrumb items.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The title of the breadcrumb item.
       */
      title: PropTypes.string.isRequired,
      /**
       * The link URL of the breadcrumb item.
       */
      link: PropTypes.string.isRequired,
    })
  ).isRequired, // 'data' prop is required
};
