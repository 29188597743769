const mimeTypes = {
    "gif": "image/gif",
    "bmp": "image/bmp",
    "jpg": "image/jpg",
    "xls": "application/vnd.ms-excel",
    "dgn": "image/vnd.dgn",
    "txt": "text/plain",
    "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "csv": "text/csv",
    "doc": "application/msword",
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "htm": "text/html",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "png": "image/png"
}

export default mimeTypes;