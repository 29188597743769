import React, { createContext, useContext, useEffect, useState } from "react";
import { getRecordSchedules, getReferenceValues } from "../../services/recordsManagementService";

const RecordReferencesContext = createContext();

export function useRecordReferencesContext() {
  return useContext(RecordReferencesContext);
}

export function RecordReferencesProvider(props) {
  const [recordReferences, setRecordReferences] = useState({})
  useEffect(() => {
    Promise.all([getReferenceValues(), getRecordSchedules()])
      .then(([referencesResponse, scheduleResponse]) => {
        const newRecordReferences = referencesResponse.data.reduce((map, v) => {
          return {[v.columnKey]: v.columnValues, ...map}
        }, {})
        setRecordReferences({...newRecordReferences, recordScheduleList: scheduleResponse.data || []})
      })
      .catch((err) => console.error(err))
  }, [])

  return (
    <RecordReferencesContext.Provider value={recordReferences}>{props.children}</RecordReferencesContext.Provider>
  );
}