export const serviceUserJwt = sessionStorage.getItem('userJwt');
const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
export const serviceConfig = {
  headers: {
    'Content-Type': 'application/json',
    userName: userInfo ? userInfo.name : '',
    usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
    token: serviceUserJwt,
  },
};
