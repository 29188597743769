import React, { useEffect } from 'react';
import styles from './NoAccess.module.scss';
import { useHistory } from 'react-router-dom';

const NoAccess = () => {
  const navigate = useHistory();
  useEffect(() => {
    sessionStorage.clear();
    setTimeout(() => {
      navigate.push('/login');
    }, 5000);
  }, []);

  return (
    <div className={styles.noAccessSection}>
      <h1>Access Denied: Your account is currently inactive.</h1>
      {/* <CustomButton title={'Back to Login'} className={'mt-5'} onClick={() => navigate.push('/login')} /> */}
    </div>
  );
};

export default NoAccess;
