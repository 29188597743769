import axios from 'axios';
// console.log(serviceConfig, 'serviceConfig');

export async function uploadToS3(file, userJwt = undefined) {
  let uploadResponse = {};
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/scan_on_demand_api/file/upload';
    console.log('scanOnDemandService.js - saving file in S3: Calling ' + url);
    let formData = new FormData();
    formData.append('file', file);
    await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          type: 'formData',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js uploadToS3()', ex.message);
  }

  return uploadResponse;
}

export async function createKofaxJob(fileIds, indexSheet, userJwt = undefined) {
  let createKofaxJobResponse = {};
  try {
    const url = '/api/file/job/s3?processName=MainProcess_OBJ3';
    console.log('scanOnDemandService.js - creating kofax job: Calling ' + url);

    await axios
      .post(url, fileIds, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          indexSheet: JSON.stringify(indexSheet),
        },
      })
      .then((response) => {
        createKofaxJobResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js createKofaxJob()', ex.message);
  }

  return createKofaxJobResponse;
}

export async function deleteKofaxJob(fileId, userJwt = undefined) {
  let createKofaxJobResponse = {};
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/scan_on_demand_api/file';
    console.log('scanOnDemandService.js - deleting kofax job: Calling ' + url);

    await axios
      .delete(url, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
        data: fileId,
      })
      .then((response) => {
        createKofaxJobResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js deleteKofaxJob()', ex.message);
    createKofaxJobResponse.error = ex;
  }

  return createKofaxJobResponse;
}

export async function getWorkQueue({ resultSet, limit = 10, page = 1, jobStatusFilter = [0], userFilter = ['Any'], docTypeFilter = ['Any'], startDate = '', endDate = '' }) {
  let getWorkQueueResponse = [];
  try {
    const url = '/api/workqueue?limit=' + limit.toString() + '&page=' + page.toString();
    // console.log('scanOnDemandService.js - getting work queue: Calling ' + url + ' for ResultSet ' + resultSet);
    let body = {
      JobStatusId: jobStatusFilter,
      DocType: docTypeFilter,
      EauthId: userFilter,
      ResultSet: resultSet,
      UploadStartDate: startDate,
      UploadEndDate: endDate,
    };

    await axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getWorkQueueResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js getWorkQueue()', ex.message);
  }

  return getWorkQueueResponse;
}

export async function getWorkQueueDemo({ resultSet, limit = 25, page = 0, jobStatusFilter = 1, userFilter = 'Any', docTypeFilter = 'Any', startDate = '', endDate = '' }) {
  let getWorkQueueResponse = [];
  try {
    const url = '/scan_on_demand_api/workqueue/populate?limit=' + limit + '&page=' + page;
    // console.log('scanOnDemandService.js - getting work queue demo: Calling ' + url + ' for ResultSet ' + resultSet);
    let body = {
      jobStatusId: jobStatusFilter,
      docType: docTypeFilter,
      eauthId: userFilter,
      resultSet: resultSet,
      uploadStartDate: startDate,
      uploadEndDate: endDate,
    };

    await axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getWorkQueueResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js getWorkQueue()', ex.message);
  }

  return getWorkQueueResponse;
}

export async function submitDirectlyToSubmissionAPI(indexSheet) {
  let apiResponse;
  try {
    // const url = process.env.REACT_APP_BACKEND_URL + '/scan_on_demand_api/file/submit/id/' + fileId;
    const url = process.env.REACT_APP_BACKEND_URL + '/scan_on_demand_api/workqueue/submit';
    // let payload = { ...indexSheet}
    // let body = {
    //   // company: indexSheet.company,
    //   // program: indexSheet.program,
    //   // division: indexSheet.division,
    //   // filecode: indexSheet.filecode,
    //   // employeename: indexSheet.employeename,
    //   // // employeenumber: indexSheet.employeenumber,
    //   // employmentyear: indexSheet.employmentyear,
    //   // first_name: indexSheet.first_name,
    //   // last_name: indexSheet.last_name,
    //   // middle_name: indexSheet.middle_name,
    //   // sections: indexSheet.sections,
    //   // term_date: indexSheet.termdate,
    //   // emp_status: indexSheet.empstatus === 'Active' ? true : false,
    //   // system_folders: indexSheet.system_folders,
    //   // contextID: indexSheet.contextID,
    //   // smc_department: 'HR',
    //   // FileSeries: indexSheet.system_folders['FileSeries'],
    //   // Folder: indexSheet.system_folders['Folder'],
    //   // Section: indexSheet.system_folders['Section'],
    //   // ObjectType: indexSheet.system_folders['ObjectType'],
    //   // object_ocr: indexSheet.object_ocr,
    //   // document_ocr_id: indexSheet.document_ocr_id,
    //   // FileSeries: indexSheet.system_folders.FileSeries,
    //   // object_type: {
    //   //   object_type_name: indexSheet.doctype,
    //   //   title: indexSheet.doctype,

    //   // },
    //   // object_type: { title: indexSheet.system_folders['ObjectType'], object_type_name: indexSheet.system_folders['ObjectType'] },
    // };

    // payload.object_type = { title: indexSheet.system_folders['ObjectType'], object_type_name: indexSheet.system_folders['ObjectType'] };
    // payload.smc_department = 'HR';

    // dynamically add system folder [0].
    // let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    // systemFolders.map((i) => {
    //   // body[i.name.replace(/\s/g, '')] = indexSheet.system_folders[i.name];
    //   payload[i.name.replace(/\s/g, '')] = indexSheet.system_folders[i.name];
    // });
    await axios
      .post(url, indexSheet, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        apiResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js submitDirectlyToSubmissionAPI()', ex.message);
  }

  return apiResponse;
}
//Get Work Queue Filters call
// api/workqueue/filters
export async function getWorkQueueFilters() {
  let workQueueFilterResponse = [];
  try {
    const url = '/api/workqueue/filters';
    // console.log('scanOnDemandService.js - getting work queue: Calling ' + url);

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js getWorkQueueFilters()', ex.message);
  }

  return workQueueFilterResponse;
}

//Claim Suspense Queue Job
// api/file/job/{jobId}/claim
export async function claimJob(jobId) {
  let claimResponse = [];
  try {
    const url = '/api/file/job/' + jobId + '/claim';
    console.log('scanOnDemandService.js - claim job ' + url);

    await axios
      .put(url, null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        claimResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
  }

  return claimResponse;
}

//Resubmit Unclassified object
// api/file/job/{jobId}/nextactivity
export async function completeActivity(jobId) {
  let activityResponse = [];
  try {
    const url = '/api/file/job/' + jobId + '/nextactivity';
    console.log('scanOnDemandService.js - complete activity ' + url);

    await axios
      .post(url, null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        activityResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js completeActivity()', ex.message);
  }

  return activityResponse;
}

export async function getFileUploadStatus(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/scan_on_demand_api/file/status';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

// API call to fetch dayforce info by using employee number
export async function getDayforceEmpInfoByEmpNumber(empNumber) {
  let apiResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/metadata_api/dayforce/' + empNumber;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        apiResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js getDayforceEmpInfoByEmpNumber()', ex.message);
    apiResponse.error = ex.response.data;
  }

  return apiResponse;
}

export async function updateDocTypeName(payload) {
  let apiResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/aws_s3_api/file_upload_details/object_type/' + payload.fileId + '?objectType=' + encodeURIComponent(payload.objectType);
    const userInfo = JSON.parse(sessionStorage.getItem('user_information'));

    await axios
      .patch(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
            userName: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.userName : '',
            usdaeauthid: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaeauthid : '',
          },
        }
      )
      .then((response) => {
        apiResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js getDayforceEmpInfoByEmpNumber()', ex.message);
    apiResponse.error = ex.response.data;
  }

  return apiResponse;
}
