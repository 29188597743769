import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

export default function SecondaryOrganizationDropDownList(props) {
  // TODO Get data for display. Currently there are no known values to use for the dropdown
  const {value, onChange} = props;
  const secondaryorg = []

  return (
    <DropDownList
      data={secondaryorg}
      className="fsa-field__item"
      onChange={onChange} value={value}
    />
  )

}