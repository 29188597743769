import { MESSAGES, IS_AUTHENTICATED, SET_ALICE_HISTORY, SET_BUOW_DETAILS, SET_USER_INFO } from '../constants';
import { getMessages } from '../inBasketService';

export const getMessagesCountNew = () => async (dispatch, getState) => {
  await getMessages().then((response1) => {
    dispatch(messageCount(response1.data));
  });
};

export const messageCount = (data) => {
  return { type: MESSAGES, data: data };
};

export const isUserAuthenticated = (data) => {
  return { type: IS_AUTHENTICATED, data: data };
};

export const setAliceHistory = (data) => {
  return { type: SET_ALICE_HISTORY, data: data };
};

export const setBUOWDetailsInfo = (data) => {
  return { type: SET_BUOW_DETAILS, data: data };
};

export const setUserInfo = (data) => {
  return { type: SET_USER_INFO, data: data };
};
