import React, { lazy, useRef, useState } from 'react';
import uploadFileCloudIcon from '../../../fsa-style/img/svgs/uploadFileCloudIcon.svg';
import successCloudIcon from '../../../fsa-style/img/svgs/successCloudIcon.svg';
import styles from './UserGuide.module.scss';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import uploadedCheckIcon from '../../../fsa-style/img/svgs/uploadedCheckIcon.svg';
import { updateUserGuide } from './UploadUserGuideServices';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'User Guide', link: '' },
];

const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));

const UserGuide = () => {
  const fileInput = useRef();
  const [file, setFile] = useState(null);
  const [uploadedSuccessfully, setuploadedSuccessfully] = useState(false);
  const [fileUploadMessage, setFileUploadMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFile = () => {
    fileInput.current.click();
    setuploadedSuccessfully(false);
    setFileUploadMessage('');
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setuploadedSuccessfully(false);

    if (selectedFile) {
      setFile(null);
      const fileSize = selectedFile.size;
      const mbFile = Math.round(fileSize / 1000000); // convert to MB
      //file validation
      if (mbFile <= 100 && selectedFile.name.endsWith('.pdf')) {
        setFile(selectedFile);
        setFileUploadMessage('');
      } else {
        setFile(null);
        setuploadedSuccessfully(false);
        setFileUploadMessage(mbFile > 100 ? 'Max file size should be 100MB' : 'Invalid file type. Supported file types: .pdf');
      }
    } else {
      setFile(null);
      setFileUploadMessage('');
    }
  };

  const handleUpload = () => {
    setIsLoading(true);
    if (file) {
      let formData = new FormData();
      if (file) {
        // Change the file name here (example: add a prefix)
        const modifiedFileName = 'SMC User Training Guide.pdf';
        // Create a new File object with the modified name
        const modifiedFile = new File([file], modifiedFileName, { type: file.type });
        // Use 'modifiedFile' for further processing or upload
        console.log('Modified File:', modifiedFile);
        formData.append('file', modifiedFile);
        updateUserGuide(formData).then((response) => {
          if (response.data) {
            setIsError(false);
            setuploadedSuccessfully(true);
            setFileUploadMessage('Your file has been uploaded successfully!');
            setIsLoading(false);
            setTimeout(() => {
              setuploadedSuccessfully(false);
              setFileUploadMessage('');
              setFile(null);
            }, 5000);
            // setFile(null);
          } else if (response.error) {
            setIsError(true);
            setFileUploadMessage('Error while uploading file!');
            setuploadedSuccessfully(false);
            setIsLoading(false);
          }
        });
      }

      // setFileUploadMessage('Your file has been uploaded successfully!');
    }
  };

  return (
    <div className={styles.mainDiv}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />
      <div className={styles.userGuideDiv}>
        <div>
          <h1 className={styles.heading}>Upload User Guide</h1>

          <div className={styles.borderDiv}>
            <div className={styles.uploadSectionDiv}>
              <div className={styles.imageDiv}>
                {uploadedSuccessfully ? <img src={successCloudIcon} alt="upload-file-icon" /> : <img src={uploadFileCloudIcon} alt="upload-file-icon" />}
              </div>

              <div className={styles.filesDiv}>
                <button
                  className={styles.browseButton}
                  onClick={() => {
                    handleFile();
                  }}
                >
                  Browse Files
                </button>
                <input style={{ display: 'none' }} type="file" ref={fileInput} accept=".pdf" onChange={handleFileChange} multiple={false} />
                <p className={styles.fileNameText}>File Name: {file ? <span>{file.name}</span> : 'no file chosen'}</p>

                <div>
                  <p className={styles.fileSizeText}>
                    Max file size:
                    <span>&nbsp;100MB</span> &nbsp;| &nbsp;Supported file types:
                    <span>&nbsp;.pdf</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button disabled={!file || !file.name.endsWith('.pdf')} className={!file ? styles.disableButton : styles.uploadButton} onClick={handleUpload}>
            Upload
          </button>

          <p className={!isError ? styles.successMessage : styles.errorMessage}>
            {fileUploadMessage}

            {fileUploadMessage.length > 0 && uploadedSuccessfully && (
              <span>
                <img src={uploadedCheckIcon} alt="checkIcon" className={styles.checkIcon} />
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserGuide;
