import axios from 'axios';

export async function getRuleDefinitions() {
    let workQueueFilterResponse = [];
    try {
        const url = '/buow_api/ruledefinition';
        await axios
            .get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                workQueueFilterResponse = response;
            });
    } catch (ex) {
        console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
}


export async function createRuleDefinition(data) {
    let createResponse = [];
    try {
        const url = '/buow_api/ruledefinition';
        await axios
            .post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                createResponse = response;
            });
    } catch (ex) {
        console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    }
    return createResponse;
}

export async function updateRuleDefinition(data) {
    let updateResponse = [];
    try {
        const url = `/buow_api/ruledefinition/${data.ruleId}`;
        await axios
            .put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                updateResponse = response;
            });
    } catch (ex) {
        console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    }
    return updateResponse;
}

export async function deleteRuleDefinition(data) {
    let deleteResponse = {};
    try {
        const url = `/buow_api/ruledefinition/${data.ruleId}`;
        await axios
            .delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                deleteResponse = response;
            });
    } catch (ex) {
        console.log('Error', ex.message);
    }
    return deleteResponse;
}

export async function searchRuleDefinition(data) {
    let workQueueFilterResponse = [];
    try {
        let url = '/buow_api/ruledefinition/';
        if (data.text !== '' && data.column === '' && data.order === '') {
            url += 'search?keywords=' + data.text;
        } else {
            url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
        }

        await axios
            .get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                workQueueFilterResponse = response;
            });
    } catch (ex) {
        console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
}

export async function sortRuleDefinition(data) {
    let workQueueFilterResponse = [];
    try {
        const url = '/buow_api/ruledefinition/sort/' + data.column + '/' + data.order;
        await axios
            .get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                workQueueFilterResponse = response;
            });
    } catch (ex) {
        console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
}