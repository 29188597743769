import React, { useState, useRef } from 'react';
import FileUploader from '../FileUploader/FileUploader';
import './DropZone.scss';
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function DropZone ({
    cancelUpload,
    uploadAndClose,
    filesToSave,
    setFilesToSave,
    id,
    setId,
    saveInProgress
  }) {
    const [errorMessage, setErrorMessage] = useState('');
    const modalImageRef = useRef();
    const modalRef = useRef();

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const fileManualSelect = (e) => {
        handleFiles(e.target.files);
    }

    const handleFiles = (files) => {
        for(let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setId(id + i + 1);
                setFilesToSave(prevArray => [...prevArray, { "id" : id + i + 1, "file" : files[i], "isChecked": false}]);
            } else {
                files[i]['invalid'] = true;
                alert("File " + files[i].name + " is not a permitted file type.")
                setErrorMessage('File type not permitted');
            }
        }
    }

    const validateFile = (file) => {
        var ext = file.name.split('.').pop();
        const validTypes = ['pdf','docx', 'doc'];
        if (validTypes.indexOf(ext) === -1) {
            return false;
        }
        return true;
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = () => {
        if(!saveInProgress){
            const filesToSaveIndex = filesToSave.findIndex(e => e.id === id);
            filesToSave.splice(filesToSaveIndex, 1);
            setFilesToSave([...filesToSave]);
        }
    }



    // const openImageModal = (file) => {
    //     const reader = new FileReader();
    //     modalRef.current.style.display = "block";
    //     reader.readAsDataURL(file);
    //     reader.onload = function(e) {
    //         modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    //     }
    // }

    const closeModal = () => {
        modalRef.current.style.display = "none";
        modalImageRef.current.style.backgroundImage = 'none';
    }

    return (
        <div data-testid='dropzone-container' className="half-container style-3">
            <div style={{ marginTop: "5vh", minHeight: "30vh" }} 
                data-testid='dropzone'
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}>
                <div style={{minHeight:"30vh",backgroundColor:"#DEE5EF",paddingBottom:"10px"}}>
                    <FontAwesomeIcon style={{marginTop:".5em", width:"100%", fontSize:"6em"}} icon= {faUpload} />

                    <div style={{marginTop:"3em", textAlign:"center"}}><b style={{fontSize:"18px"}}>Drag and drop your object(s) here to add them to the queue.</b><br/><br/> <div style={{fontSize:"16px"}}>Only PDF, DOC, DOCX file types allowed.</div></div>
                </div>
            </div>
                <div style={{padding:"1em", marginTop: "1em",backgroundColor:"#DEE5EF"}}>
                    <FileUploader fileManualSelect={fileManualSelect}></FileUploader>
                </div>
      
                <div className="file-display-container">
                    {
                        filesToSave.map((data, i) => 
                            <div className="file-status-bar" key={i}>
                                <div >
                                    <div className="file-type-logo"></div>
                                    <h2
                                        style={{
                                        fontSize: 14,
                                        color: "#454545",
                                        marginBottom: 0,
                                        wordBreak: "break-word"
                                        }}
                                        className="text-uppercase"
                                    >
                                        <div className="file-type">{fileType(data.file.name)}</div>
                                        {data.file.name}
                                        <span className="file-size">({fileSize(data.file.size)})</span> {data.file.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                        <br/>
                                        <span
                                            style={{
                                            marginLeft:"3.4%",
                                            fontSize: 12,
                                            color: "green",
                                        }}
                                    >
                                        Object successfully added to queue.
                                    </span>
                                    </h2>
                                    
                                    
                                    

                                </div>
                                <div className="file-remove" data-testid='remove-file-btn' onClick={() => removeFile(data.file.name)}><img
                                        style={{height: 15, float:"right", color:"grey"}}
                                        src="/img/close.svg"
                                        alt="Remove Parameter"
                                        />
                                    </div>

                            </div>
                        )
                    }
                
                </div>
                <button style={{marginLeft:"10px"}} className="fsa-btn fsa-btn--secondary save-object-btn" disabled={saveInProgress} onClick={cancelUpload}> Cancel</button>
                <div style={{padding:"0", marginLeft:"0px"}} className="fsa-progress-inline save-object-btn">
                    {saveInProgress && (
                    <div className="fsa-progress fsa-progress--indeterminate" aria-live="polite">
                        <div className="fsa-progress__details fsa-sr-only">
                            <div className="fsa-progress__label">Loading tracts</div>
                        </div>
                        <div className="fsa-progress__bar" aria-hidden="true">
                            <div className="fsa-progress__primary"></div>
                            <div className="fsa-progress__secondary"></div>
                        </div>
                    </div>
                    )}
                    <button className="fsa-btn save-object-btn" disabled={saveInProgress} onClick={uploadAndClose}> Save</button>

                </div>
                
                <div data-testid='modal-container' className="modal" ref={modalRef}>
                    <div data-testid='modal-container-overlay' className="overlay" onClick={(() => closeModal())}></div>
                    <span data-testid='modal-container-span' className="close" onClick={(() => closeModal())}>x</span>
                    <div className="modal-image" ref={modalImageRef}></div>
                </div>
                
        </div>
    )
}