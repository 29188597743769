import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';

const options = ['Hold', 'Other'];

export default function TransferTypeDropDownList(props) {
  const { label, value, onChange, valid, ...rest } = props;
  return (
    <div>
      <label className="fsa-field__label">{label}</label>
      <DropDownList data={options} className="fsa-field__item" onChange={onChange} value={value} {...rest} />
      {!valid && <div className="validation-message">This field is required *</div>}
    </div>
  );
}