import { createContext, useContext, useEffect, useState } from "react";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

const NotificationContext = createContext();

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export default function NotificationProvider(props) {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    // clean up notification array
    const isEveryNotificationShowFalse = notifications.length && notifications.every(({show}) => {
      if (show === null || show === undefined) {
        return false
      }
      return show === false
    });
    if (isEveryNotificationShowFalse) {
      setNotifications([])
    }
  }, [notifications])

  const addNotification = ({style, msg}) => {
    const newNotifications = [{style, msg, show: true}, ...notifications]
    setNotifications(newNotifications)
  }

  const removeNotification = (index) => {
    const newNotifications = [...notifications]
    newNotifications[index].show = false
    setNotifications(newNotifications)
  }

  return (
    <>
      <NotificationContext.Provider value={{addNotification}}>{props.children}</NotificationContext.Provider>
      <NotificationGroup
        style={{
          left: 0,
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade enter={true} exit={true}>
          {notifications.map((notification, i) => {
            return (
              notification.show && <Notification
                key={i}
                type={{style: notification.style, icon: true}}
                closable={true}
                onClose={() => removeNotification(i)}
              >
                {notification.msg}
              </Notification>
            );
          })}
        </Fade>
      </NotificationGroup>
    </>
  )
}