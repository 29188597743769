import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { calculateWidth } from "./Table";
import { Checkbox } from "@progress/kendo-react-inputs";
import styles from './SelectableTable.module.scss'

const headerStyle = (column) => {
  return <a style={{textAlign: "center", backgroundColor: "#12A5E4"}} className="k-link">
    <span style={{fontWeight: "bold", color: "white", fontSize: "16px", paddingRight: "1em"}}
          onClick={column.onClick}>{column.title} </span>
    {column.children} {column.sort}
  </a>
}

const SELECTED_FIELD = "selected";

export default function SelectableTable(props) {
  const {
    data = [],
    fields,
    actionText,
    onAction,
    onPageChange,
    total = data.length,
    page = 0,
    pageSize = 10,
    dataKeyItem = 'id',
    handleUpdateHold
  } = props
  const [selected, setSelected] = useState([])

  const onTableAction = () => {
    const actionData = data.filter((d) => selected.includes(d[dataKeyItem]))
    if (onAction) {
      onAction(actionData)
    }
  }

  const onCheckboxClick = (id) => {
    const index = selected.indexOf(id)
    // deselect
    if (index >= 0) {
      const newSelected = [...selected]
      newSelected.splice(index, 1)
      setSelected(newSelected)

    }
    // select
    else {
      setSelected([...selected, id])
    }
  }

  const onCheckboxAllClick = (e) => {
    const isAllCheckboxChecked = selected.length === data.length
    // select all
    if (!isAllCheckboxChecked) {
      const newSelected = data.map(row => row[dataKeyItem]);
      setSelected(newSelected)
    }
    // deselect all
    else {
      setSelected([])
    }
  }

  const clickCell = (props) => {
    const id = props.dataItem[dataKeyItem]
    return <td>
      <div className="selectCell">
        <Checkbox
          checked={selected.includes(id)}
          name="rowsCheckbox"
          id={props.id}
          onChange={() => onCheckboxClick(id)}
        />
      </div>
    </td>
  }

  const headerCell = (props) => {
    return <div>
      <Checkbox
        checked={selected.length === data.length}
        id={props.id}
        onChange={onCheckboxAllClick}
      />
    </div>
  }

  return (
    <div>
      <Grid
        selectedField={SELECTED_FIELD}
        data={data}
        total={total}
        pageSize={pageSize}
        skip={page * pageSize}
        pageable={true}
        onPageChange={onPageChange}
      >
        {
          data.length && fields.map((column, index) => {
            if (column.field === "SELECTED_FIELD") {
              return (
                <Column
                  headerCell={headerCell}
                  headerClassName={styles.gridColumnHeader}
                  field={SELECTED_FIELD}
                  cell={clickCell}
                  title={column.title}
                  key={index}
                />
              )
            } else {
              return (
                <Column
                  headerCell={headerStyle}
                  field={column.field}
                  title={column.title}
                  key={index}
                  cell={column.cell}
                  width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                />
              )
            }
          })
        }
      </Grid>
      <div className="mt-4">
        <div style={{display: 'flex',justifyContent:'end',marginBottom:'1rem'}} 
        // className={'justify-content-end'}
        >
          <button
            className="fsa-btn"
            onClick={onTableAction}
            disabled={selected.length <= 0}
          >
            {actionText}
          </button>
          {/* <button className="fsa-btn fsa-btn--secondary" onClick={onCancel}>Cancel</button> */}
          <button className="fsa-btn fsa-btn--secondary" onClick={()=>handleUpdateHold(false)}>Cancel</button>

          

          
        </div>
      </div>
    </div>
  )
}