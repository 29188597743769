import { Router } from "react-router-dom";
import RouterControl from "./RouterControl";
import history from "./history";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";
import "./css/fsa-design-system.css";
import "./fsa-style/fsa-style.scss";
import 'react-notifications/lib/notifications.css';

// import oidcConfiguration from "./configuration";
import { UserProvider } from "./components/UserContext/UserContext";
// import Foot from "./components/Foot/foot";
import NotificationProvider from "./components/Notification/NotificationProvider";
import { Suspense } from "react";
import { GlobalLoader } from "./newcomponents/GlobalLoader/GlobalLoader";
import withClearCache from './ClearCache';

// const oktaTokenVal = JSON.parse(sessionStorage.getItem("okta-token-storage"));

//  NOTE:- Commented Old Login Code
// const redirect_uri =
//   process.env.NODE_ENV === "development"
//     ? "https://dev.local.com"
//     : "https://drms-qaqc-train.fpac.usda.gov";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
 // // START clear cache

  // THIS IS THE OPTION THAT YOU CAN OPT, BUT DIFFERENT BROWSERS WILL // HAVE THEIR DIFFERENT MESSAGES FOR PROMPTING BEFORE RELOAD. ELSE // IF YOU ARE USING BROWSERROUTER IN YOUR CODE. GIVE A SHOT FOR //THIS. https://v5.reactrouter.com/web/api/BrowserRouter(forceRefresh prop)
  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => true);

  //   return () => window.removeEventListener("beforeunload",  //() => false);
  // }, []);

  // END for clear cache

  // const logout = async () => {
  //   oidcConfiguration.userManager.signoutRedirect({
  //     id_token: sessionStorage.getItem("eAuthJwt").slice(7),
  //     post_logout_redirect_uri: redirect_uri,
  //   });
  //   sessionStorage.clear();
  // };

  return (
    // <AuthProvider {...oidcConfiguration}>
      <UserProvider>
        <NotificationProvider>
          <div className="App">
          <Suspense fallback={<div><GlobalLoader/></div>}>
            <Router history={history} forceRefresh={true}>
              {/* <Head logout={logout}/> */}
              <RouterControl/>
              {/* {_.isEmpty(oktaTokenVal) === false && <Foot/>} */}
            </Router>
            </Suspense>
          </div>
        </NotificationProvider>
      </UserProvider>
    // </AuthProvider>
  );
};

export default App;
