import jobDescription from '../SuspenseQueueList/SuspenseJobsDescription.json';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SuccessfullyProcessedList({ dataItem, onPreviewClicked }) {
  const job = jobDescription.filter((job) => job.jobStatus === dataItem.JobStatus)[0];
  const handleOnPreviewClicked = () => {
    onPreviewClicked({
      esId: dataItem.EsId,
      pageCount: dataItem.PageCount,
    });
  };

  return (
    <div
      className="row border-bottom border-top align-middle"
      style={{
        margin: 0,
        marginBottom: '5px',
        overflow: 'hidden',
      }}
    >
      <div style={{ padding: 0, borderLeft: 'solid', borderWidth: '1em', borderColor: job.indicator }} className="col-12">
        <div className="job-header">
          <div className="documentsListview">
            <div>
              <FontAwesomeIcon style={{ marginRight: '10px', marginTop: '3px', fontSize: '15px' }} icon={faCheckCircle} />

              <span
                title={`${dataItem.DocTypeName}: ${dataItem.Edition !== null ? dataItem.Edition : ''}`}
                style={{
                  minWidth: '10%',
                  maxWidth: '50%',
                }}
              >
                <p className="docListViewEdition">
                  {dataItem.DocTypeName}: {dataItem.Edition}
                </p>
              </span>
            </div>

            <div>
              <span className="docListViewUploaded">
                <b>Uploaded: {dataItem.UploadDate}</b>
              </span>
            </div>

            <div>
              <span className="docListViewUser">
                <b>User: {dataItem.UserName}</b>
              </span>
            </div>
            <div>
              <span className="docListViewPreview" onClick={handleOnPreviewClicked}>
                <b>Preview</b>
              </span>
            </div>
          </div>
          <span>File Name: {dataItem.FileNameOriginal}</span>
        </div>

        <div className="job-description">
          <div className="docListViewPage">
            <div style={{visibility:'hidden'}}>Page</div>
            {/* <b>{dataItem.PageCount} </b> Page{dataItem.PageCount > 1 ? 's' : ''} */}
          </div>
          <div className="docListViewDescription">
            <b>Description:</b> {dataItem.Description}
          </div>
        </div>
      </div>
    </div>
  );
}
