import { React } from 'react';
import { Switch } from '@progress/kendo-react-inputs';

export default function BooleanSearchInput(props) {
  const systemName = props.userParams.systemName;
  const friendlyName = props.userParams.friendlyName;
  const checked = props?.checked.filter((item) => typeof item === 'boolean')[0];

  return (
    <div style={{ width: '27%' }} key={systemName} className="specsDivs1 form-field fc">
      <div className="d-flex align-items-center justify-content-between">
        <label style={{ marginBottom: 0 }}>
          {friendlyName}
          {/* <img
                        style={{ height: 10, marginLeft: "1rem"}}
                        onClick={() => props.removeParam(systemName)}
                        src="/img/close-primary.svg"
                        alt="Remove Parameter"
                    /> */}
        </label>
        <div style={{ textAlign: 'right' }} className={'inputRemoveIcon'} onClick={() => props.removeParam(systemName)}>
          x
        </div>
      </div>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '10px', width: '75px' }}>
        <Switch
          onChange={(e) => {
            props.handleChange(systemName, e);
          }}
          defaultChecked={true}
          onLabel={'True'}
          offLabel={'False'}
          checked={checked === undefined ? true : checked}
        />
      </div>
    </div>
  );
}
