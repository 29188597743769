const FetchLocalStorageDataByPath = (params, path) => {
  try {
    const keyData = localStorage.getItem(params) && JSON.parse(localStorage.getItem(params));
    if(keyData === null) return;
    const object = path.split('.').reduce((acc, curr) => acc[curr], keyData);
    return object;
  } catch (err) {
    console.log(err, 'err');
  }
};

export default FetchLocalStorageDataByPath;
