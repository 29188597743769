import React, { useEffect, useState, useRef, useContext } from 'react';
import './FileCode.scss';
import { Dialog } from '@progress/kendo-react-dialogs';
import { getFileCodes } from '../../services/recordsManagementService';
import FileCodeAdvSearch from './FileCodeAdvSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { EventEmitter } from '../../services/events';
import FileCodeFields from '../../json/FileCodeFields.json';
import FcKeywordSearch from './FcKeywordSearch';
import Table from '../Table/Table';
import FileCodeManagementForm from './FileCodeManagementForm';
import { RecordReferencesProvider } from '../RecordReferences/RecordReferencesProvider';
import UserContext from '../UserContext/UserContext';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import '../../GlobalStyle.scss';

export default function FileCodeSearch() {
  const { EventEmitter } = require('../../services/events');

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [createFilecodeOpen, setCreateFilecodeOpen] = useState(false);
  const [editFilecodeOpen, setEditFilecodeOpen] = useState(false);

  const [modalLocation, setModalLocation] = useState();
  const elementRef = useRef();

  const [advSearch, setAdvSearch] = useState({});
  const [selectedFileCodeObject, setSelectedFileCodeObject] = useState({});
  const [fileCodeData, setFileCodeData] = useState([]);
  const [pageSize, setPageSize] = React.useState(25);
  const [page, setPage] = useState(0);
  const { userPermissions } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    const newPage = page.skip / page.take;
    setPage(newPage);
  };
  const resetPage = () => {
    setPage(0);
  };

  useEffect(() => {
    setIsLoading(true);
    getFileCodes().then((response) => {
      setFileCodeData(response.data);
      setIsLoading(false);
    });

    if (elementRef) {
      const divElement = elementRef?.current?.getBoundingClientRect();
      setModalLocation(divElement);
    }

    EventEmitter.on('refreshFileCodeGrid', () => {
      setIsLoading(true);

      getFileCodes().then((response) => {
        setFileCodeData(response.data);
        setIsLoading(false);
      });
    });
  }, []);

  const handleAdvModal = () => {
    setSearchPopupOpen(!searchPopupOpen);
  };
  const handleCreateModal = () => {
    setCreateFilecodeOpen(!createFilecodeOpen);
  };
  const handleEditModal = () => {
    setEditFilecodeOpen(!editFilecodeOpen);
  };

  const onRowClick = (e) => {
    setSelectedFileCodeObject(e.dataItem);
    handleEditModal();
  };

  return (
    <RecordReferencesProvider>
      {isLoading && <GlobalLoader />}

      <main className="content" tabIndex="-1">
        <div className="content">
          <div className="pageContent">
            <h1 className="HeaderOne-StyleOne">Manage Record Schedule</h1>
            <div className="flex">
              {userPermissions['Search_Record_Schedule_By_Keyword_File_Management'] === true && <FcKeywordSearch setFileCodeData={setFileCodeData} onSearch={resetPage} />}
              {userPermissions['Search_Record_By_Advance_Search_File_Management'] === true && (
                <div className="flexrow underKeywordContainer">
                  <div className="modal-link-con" ref={elementRef}>
                    <p className="modal-link" style={{ cursor: 'pointer' }} onClick={handleAdvModal}>
                      Advanced Search
                      <span>
                        {searchPopupOpen ? (
                          <FontAwesomeIcon onClick={handleAdvModal} className="float-right chevron" icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <div className="fc-grid-head-row">
                <div className="h3-div-2">Record Schedule Search Results</div>
                <div className="form-field">
                  {userPermissions['Create_Record_Series'] === true && (
                    <button className="fsa-btn lite-btn" onClick={handleCreateModal}>
                      Create Record Series
                    </button>
                  )}
                </div>
              </div>

              {searchPopupOpen && (
                <Dialog
                  minimizeButton={() => null}
                  maximizeButton={() => null}
                  title={'Advanced Search'}
                  onClose={handleAdvModal}
                  initialHeight={600}
                  initialWidth={1000}
                  // initialLeft={modalLocation.left}
                  // initialTop={modalLocation.top + 45}
                  className="glbRecordScheduleSearchModal"
                >
                  <FileCodeAdvSearch setFileCodeData={setFileCodeData} onClose={handleAdvModal} initialValues={advSearch} setInitialValues={setAdvSearch} onSearch={resetPage} />
                </Dialog>
              )}

              {createFilecodeOpen && (
                <Dialog
                  minimizeButton={() => null}
                  maximizeButton={() => null}
                  title={'Create a Record Series'}
                  onClose={handleCreateModal}
                  initialHeight={750}
                  initialWidth={900}
                  className="glbRecordScheduleModal"
                >
                  <FileCodeManagementForm onClose={handleCreateModal} />
                </Dialog>
              )}
              {editFilecodeOpen && (
                <Dialog
                  minimizeButton={() => null}
                  maximizeButton={() => null}
                  onClose={handleEditModal}
                  initialHeight={750}
                  initialWidth={900}
                  title={`Filecode ${selectedFileCodeObject.filecodeId}`}
                  className="glbRecordScheduleModal"
                >
                  <FileCodeManagementForm onClose={handleEditModal} fileCodeObject={selectedFileCodeObject} />
                </Dialog>
              )}
            </div>
            <Table data={fileCodeData} fields={FileCodeFields} total={fileCodeData.length} page={page} pageSize={pageSize} onPageChange={onPageChange} onRowClick={onRowClick} />
          </div>
        </div>
      </main>
    </RecordReferencesProvider>
  );
}
