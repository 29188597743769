import axios from 'axios';

export async function updateUserGuide(data) {
  let workQueueFilterResponse = [];
  try {
    const url =
      process.env.REACT_APP_BACKEND_URL +
      '/aws_s3_api/s3/v2?bucketName=' +
      process.env.REACT_APP_S3_USER_GUIDE_BUCKET_NAME +
    //   '&folderName=' +
    //   process.env.REACT_APP_S3_USER_GUIDE_FOLDER_NAME +
      '&overwrite=true';

    await axios
      .post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        workQueueFilterResponse.data = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    workQueueFilterResponse.error = ex;
  }
  return workQueueFilterResponse;
}
export async function downloadUserGuide() {
    let workQueueFilterResponse = [];
    try {
      const url =
        process.env.REACT_APP_BACKEND_URL +
        '/aws_s3_api/s3/v2?bucketName=' +
        process.env.REACT_APP_S3_USER_GUIDE_BUCKET_NAME +
        '/SMC User Training Guide.pdf'
  
      await axios
        .get(
          url,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
            },
          }
        )
        .then((response) => {
          workQueueFilterResponse.data = response;
        });
    } catch (ex) {
      console.log('Error in get messages ', ex.message);
      workQueueFilterResponse.error = ex;
    }
    return workQueueFilterResponse;
  }
