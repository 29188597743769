import React from 'react';
import styles from './Footer.module.scss';

/**
 * Footer component for displaying the website's footer.
 *
 * This component renders the website's footer section, which typically contains copyright and trademark information.
 *
 * @component
 */

const Footer = (props) => {
  return (
    <footer className={styles.footerSection}>
      <p>
        Copyright 2003-2023 <span className={styles.footerHighlightText}>Brillient Corporation</span>. All Rights Reserved.
        <span className={styles.footerTrademarkText}>Brillient Corporation®</span>
      </p>
    </footer>
  );
};

export default Footer;
