import DrmsFields from '../../json/DrmsFields.json';
import { useContext, useEffect, useState } from 'react';
import Table from '../Table/SelectableTable';
import axios from 'axios';
import UserContext from '../UserContext/UserContext';
import { search } from '../../services/metadataSubmissionService';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';

export default function ObjectsInHold(props) {
  // const { EventEmitter } = require('../../services/events');

  const { onClose, holdObject } = props;
  const [objectsInHolds, setObjectsInHolds] = useState([]);
  const [page, setPage] = useState(0);
  const [pagePreview, setPagePreview] = useState(0);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // const pageSize = 20
  const [pageSize, setPageSize] = useState(5);

  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip);
    setPagePreview(page.skip / page.take);
    getData();
  };
  const getData = () => {
    const searchData = {
      conjunction: 'AND',
      sort: [],
      aggregation: [],
      searchCriteria: [
        {
          condition: 'EQUALS',
          field1: {
            name: 'holds.hold_number',
            value: [holdObject.holdNumber],
          },
        },
        {
          condition: 'EQUALS',
          field1: {
            name: 'doc_repo.is_latest',
            value: [true],
          },
        },
      ],
    };

    search(searchData, page, pageSize, userInfo).then((result) => {
      if (result.isAxiosError === true) {
        if (result.response.status === 404) {
          console.log('No records were found matching your search criteria.');
        } else {
          console.log("We're sorry, something went wrong, please try your search again.");
        }
      } else {
        setObjectsInHolds(result.data);
        // console.log(result.data, 'result.data');
      }
    });
  };

  const removeObjectsFromHold = async (objectsInHoldToRemove) => {
    setLoading(true);
    const data = objectsInHoldToRemove.map((o) => o._id);
    const url = `/recordsmanagementapi/records-management/holds/remove?holdNumber=${holdObject.holdNumber}`;
     await axios
      .put(url, data, {
        headers: {
          userName: userInfo.name || '',
          usdaeauthid: userInfo.usdaeauthid || '',
          Authorization: sessionStorage.getItem('userJwt') || '',
        },
      })
      .then(() => {
        setMessage('Objects Removed from the hold Successfully');

        setIsSuccessGrowlHidden(false);
        // EventEmitter.emit('refreshTransferGrid');

        setTimeout(() => {
          onClose();
          setIsSuccessGrowlHidden(true);
          setLoading(false);
        }, 3000);

        // onClose();
      })
      .catch((err) => {
        setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
        setIsErrorGrowlHidden(false);
        setTimeout(() => {
          onClose();
          setIsErrorGrowlHidden(true);
          setLoading(false);
        }, 3000);
      });
  };

  return (
    <div>
      {loading && <GlobalLoader />}
      <Table
        pageSize={pageSize}
        total={objectsInHolds.total_hits}
        page={pagePreview}
        data={objectsInHolds.metadata}
        fields={DrmsFields}
        onCancel={onClose}
        actionText={'Remove Objects From Hold'}
        onAction={removeObjectsFromHold}
        onPageChange={onPageChange}
        dataKeyItem={'_id'}
        getData={getData}
      />
      <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
    </div>
  );
}
