import { Route, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GlobalLoader } from './newcomponents/GlobalLoader/GlobalLoader';
import FetchLocalStorageDataByPath from './utils/helper/FetchLocalstorageDataByPath.js';

const AdminRoute = ({ component: Component, ...rest }) => {
  const [isFeatureActive, setisFeatureActive] = useState(null);
  const [loading, setLoading] = useState(true);
  let isSuperUser = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.superUser;

  const { path } = rest;
  const newPath = path.substr(1);

  useEffect(() => {
    getLocalStorageDataFunc();
  }, [path]);

  const userRole =
    JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Admin') ||
    JSON.parse(sessionStorage.getItem('user_information'))?.rolesName.includes('Application Administrator');

  const getLocalStorageDataFunc = async () => {
    try {
      const fetchedLocalStorageData = FetchLocalStorageDataByPath('system_config', 'features') ?? [];
      const crudsPermissions = FetchLocalStorageDataByPath('system_config', 'adminPanel');
      const crudsPermissionsFound = await crudsPermissions.find((values) => values.path_name === newPath);
      const foundFeature = await fetchedLocalStorageData.find((values) => values.path_name === newPath);

      if (foundFeature) {
        setisFeatureActive((prev) => ({
          ...prev,
          [newPath]: foundFeature.value,
        }));
      } else if (crudsPermissionsFound) {
        setisFeatureActive((prev) => ({
          ...prev,
          [newPath]: crudsPermissionsFound.value,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userRole !== true) {
          return <Redirect to={'/login'} />;
        }

        if (loading) {
          return <GlobalLoader />;
        }

        if (isFeatureActive === null || !isFeatureActive.hasOwnProperty(newPath)) {
          return <Component {...props} />;
        }

        if (isFeatureActive['systemconfiguration'] === true && !isSuperUser) {
          return <Redirect to={'/inbasketpage'} />;
        }

        if (isFeatureActive[newPath] === true || (isSuperUser === true && newPath === 'admin')) {
          return <Component {...props} />;
        }

        return <Redirect to={'/inbasketpage'} />;
      }}
    />
  );
};

export default AdminRoute;
