import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../SearchFolderOptions/SearchFolderOptionsTable.module.scss';
import '../../../GlobalStyle.scss';
// import TableUsersTable from './WorkGroupTable.js';
import SearchTablesData from './WorkGroupData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createWorkgroupTable, deleteWorkgroup, searchForWorkGroupTable, updateWorkgroup } from './WorkGroupService.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';
//Resusable Components Start
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
// import Footer from '../../../newcomponents/Footer/Footer';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';

//Resusable Components End

var _ = require('lodash');
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Work Group', link: '' },
];
const displayNameWorkGroup = [
  { value: 1, label: 'Lorem1' },
  { value: 2, label: 'Lorem2' },
  { value: 3, label: 'Lorem3' },
];

const TableUsersTable = lazy(() => import('./WorkGroupTable.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function SearchFolderTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: userInfoContext,
    workgroupDisplayName: '',
    workgroupId: null,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [searchTableDetails, setSearchTableDetails] = useState(SearchTablesData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchSearchOptionsData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForWorkGroupTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchSearchOptionsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchForWorkGroupTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      workgroupDisplayName: '',
      workgroupId: null,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      workgroupId: DeleteData.workgroupId,
    };
    deleteWorkgroup(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSearchOptionsData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Workgroup deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data?.contextId,
      workgroupId: data?.workgroupId,
      workgroupDisplayName: data?.workgroupDisplayName,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForWorkGroupTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUsersTableData = JSON.parse(JSON.stringify(searchTableDetails));
    newUsersTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setSearchTableDetails(newUsersTableData);
    await fetchNewUsersTableData();
  };

  // Code needs to change
  const fetchNewUsersTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchForWorkGroupTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchForWorkGroupTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>Work Group</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Work Group" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <TableUsersTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={searchTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditUsersTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchSearchOptionsData={fetchSearchOptionsData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle="Work Group"
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditUsersTable = (props) => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  // const promiseOptions = (inputValue) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(filterWorkGroupName(inputValue));
  //     }, 1000);
  //   });
  // const loadWorkGroupName = async (inputValue, callback) => {
  //   if (inputValue === '') {
  //     // setSearchDataBUOW([]);
  //   } else {
  //     await getSearchContextId(inputValue).then(async (response1) => {
  //       let options = await response1.data.map((res) => {
  //         return { label: res.contextId, value: res.contextId };
  //       });
  //       // setSearchDataBUOW(options);
  //       callback(options);
  //     });
  //   }
  // };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: userInfoContext,
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
    setPasswordVisible(false);
  }, [props.formData, props.isNew]);

  const handleInputName = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: name !== 'description' ? (name === 'systemFolderLevel' ? parseInt(removeSpace.trimStart()) : value.trimStart()) : value.trimStart(),
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        props.formData.contextId === null ||
        props.formData.contextId === '' ||
        props.formData.workgroupDisplayName === null ||
        props.formData.workgroupDisplayName === '' ||
        props.formData.workgroupDisplayName.length > 50
      ) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);
      props.isLoading(true);
      await createWorkgroupTable(payload)
        .then((response) => {
          if (response.data) {
            props.fetchSearchOptionsData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Workgroup created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            setIsSuperUser(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async () => {
    if (
      props.formData.contextId === null ||
      props.formData.contextId === '' ||
      props.formData.workgroupDisplayName === null ||
      props.formData.workgroupDisplayName === '' ||
      props.formData.workgroupDisplayName.length > 50
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    props.isLoading(true);
    await updateWorkgroup(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Workgroup updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchSearchOptionsData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <Modal
      {...props}
      className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Work Group' : 'Edit Work Group'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.usersTableModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {!props.isNew && (
                // <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
                // </div>
              )}

              {/* <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Work Group ID </label>
                <input name="name" disabled={true} value={"abc_Test"} onChange={handleInputName} />
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.name?.length > 50 && handleMaxLimitError(50)}
              </div> */}
              <div className={[styles.inputFieldInternalDiv, `${!props.isNew ? 'col-6' : 'col-12'}`].join(' ')}>
                <label>Work Group Display Name *</label>
                <input name="workgroupDisplayName" value={props.formData.workgroupDisplayName} onChange={handleInputName} />
                {/* <AsyncSelect
                    styles={customStyles}
                    isClearable
                  isDisabled={!props.isNew ? false : true}
                  defaultOptions
                  cacheOptions
                  loadOptions={promiseOptions}
                    value={props?.formData?.workgroupDisplayName !== '' ? { label: props?.formData?.workgroupDisplayName, value: props?.formData?.workgroupDisplayName } : { label: '---Select---' }}
                    onChange={onChangeWorkGroupName}
                  /> */}
                {props.formData.workgroupDisplayName?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                {(props.formData.workgroupDisplayName === null || props.formData.workgroupDisplayName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.usersTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" disabled={false} className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
