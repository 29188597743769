import React, { useEffect, useState,lazy } from 'react';
import { Modal } from 'react-bootstrap';
// import './SystemConfiguration.scss';
import styles from './BUOWDisplayName.module.scss';
import '../../../GlobalStyle.scss';
import AsyncSelect from 'react-select/async';
// import TableBUOWDisplayName from './TableBUOWDisplayName.js';
import BUOWDisplayNameData from './BUOWDisplayName.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl.js';
// import InitialData from './SystemConfigData.json';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb.js';
import { createBUOWDisplayName, deleteBUOWDisplayName, searchBUOWDisplayName, updateBUOWDisplayName } from './BUOWDisplayNameService.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton.js';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';
const TableBUOWDisplayName = lazy(() => import('./TableBUOWDisplayName.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'BUOW Display Name', link: '' },
];

const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

export default function BUOWDisplayName() {
  // const [stepError, setStepError] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    buowDisplayName: '',
    buowName: '',
    buowType: '',
    containerDisplayName: '',
    contextId: '',
    displayClipboard: true,
    id: '',
    kpi1DisplayName: '',
    kpi2DisplayName: '',
    kpi3DisplayName: '',
    kpi4DisplayName: '',
    screenDisplayName: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [BUOWDisplayNameDetails, setBUOWDisplayNameData] = useState(BUOWDisplayNameData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchBUOWDisplayNameData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBUOWDisplayName(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);

    // fetchBUOWDisplayNameData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchBUOWDisplayName(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      buowType: '',
      buowName: '',
      kpi1DisplayName: '',
      kpi2DisplayName: '',
      kpi3DisplayName: '',
      kpi4DisplayName: '',
      screenDisplayName: '',
      containerDisplayName: '',
      buowDisplayName: '',
      displayClipboard: true,
      id: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };

    deleteBUOWDisplayName(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchBUOWDisplayNameData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('BUOW Display Name deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      buowType: data.buowType,
      buowName: data.buowName,
      kpi1DisplayName: data.kpi1DisplayName,
      kpi2DisplayName: data.kpi2DisplayName,
      kpi3DisplayName: data.kpi3DisplayName,
      kpi4DisplayName: data.kpi4DisplayName,
      screenDisplayName: data.screenDisplayName,
      containerDisplayName: data.containerDisplayName,
      buowDisplayName: data.buowDisplayName,
      displayClipboard: data.displayClipboard,
      id: data.id,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchBUOWDisplayName(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newBUOWDisplayNameData = JSON.parse(JSON.stringify(BUOWDisplayNameDetails));
    newBUOWDisplayNameData.forEach((itm) => {
      itm.sortType = '';
    });
    setBUOWDisplayNameData(newBUOWDisplayNameData);
    await fetchNewBUOWDisplayNameData();
  };

  // Code needs to change
  const fetchNewBUOWDisplayNameData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchBUOWDisplayName(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchBUOWDisplayName(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div data-testid="buow-display-name-container" className={styles.BUOWDisplayNamePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div data-testid="buow-display-name-header-container" className={styles.BUOWDisplayNamePageContent}>
        <h1 data-testid="buow-display-header" className={styles.pageTitle}>
          BUOW Display Name
        </h1>
        <div data-testid="buow-search-container">
          <div className={styles.searchSection}>
            <div data-testid="buow-keyword-search" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="buow-display-create-btn-container">
                <CustomButton testId="buow-display-create-btn" title="Create BUOW Display Name" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="search-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="table-container" className={[styles.BUOWDisplayNameTable, 'glbBUOWDisplayNameTable'].join(' ')}>
          <TableBUOWDisplayName
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={BUOWDisplayNameDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditBUOWDisplayName
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchBUOWDisplayNameData={fetchBUOWDisplayNameData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'BUOW Display Name'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditBUOWDisplayName = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      // await searchPermissionTable(inputValue).then(async (response1) => {
      //   let options = await response1.data.map((res) => {
      //     return { label: res.contextId, value: res.contextId };
      //   });
      //   callback(options);
      // });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      displayClipboard: booleanValue,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !props.formData.contextId ||
        props.formData.contextId?.length > 50 ||
        !props.formData.buowType ||
        props.formData.buowType?.length > 35 ||
        !props.formData.buowName ||
        props.formData.buowName?.length > 35 ||
        props.formData.kpi1DisplayName?.length > 50 ||
        props.formData.kpi2DisplayName?.length > 50 ||
        props.formData.kpi3DisplayName?.length > 50 ||
        props.formData.kpi4DisplayName?.length > 50 ||
        props.formData.screenDisplayName?.length > 50 ||
        props.formData.containerDisplayName?.length > 50 ||
        props.formData.buowDisplayName?.length > 50
      ) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);

      props.isLoading(true);

      await createBUOWDisplayName(payload)
        .then((response) => {
          if (response.data) {
            props.fetchBUOWDisplayNameData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('BUOW Display Name created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (
      !props.formData.contextId ||
      props.formData.contextId?.length > 50 ||
      !props.formData.buowType ||
      props.formData.buowType?.length > 35 ||
      !props.formData.buowName ||
      props.formData.buowName?.length > 35 ||
      props.formData.kpi1DisplayName?.length > 50 ||
      props.formData.kpi2DisplayName?.length > 50 ||
      props.formData.kpi3DisplayName?.length > 50 ||
      props.formData.kpi4DisplayName?.length > 50 ||
      props.formData.screenDisplayName?.length > 50 ||
      props.formData.containerDisplayName?.length > 50 ||
      props.formData.buowDisplayName?.length > 50
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    props.isLoading(true);
    await updateBUOWDisplayName(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('BUOW Display Name updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchBUOWDisplayNameData();
        props.setRecordModalShow(false);

        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <Modal
      {...props}
      className={[styles.BUOWDisplayNameModal, 'glbBUOWDisplayNameModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div data-testid="buow-display-create-modal">
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div data-testid="header-container">
              <h2 data-testid="modal-header" className={styles.modalHeaderTitle}>
                {props.isNew ? 'Create BUOW Display Name' : 'Edit BUOW Display Name'}
              </h2>
            </div>
            <div
              data-testid="modal-cross-container"
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img data-testid="cross-icn" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.BUOWDisplayNameModalBody}>
          <div data-testid="buow-body-container" className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {props.isNew ? (
                <></>
              ) : (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId?.length > 50 && handleMaxLimitError(50)}
                </div>
              )}

              <div data-testid="buow-type-container" className={styles.inputFieldInternalDiv}>
                <label>BUOW Type *</label>
                <select data-testid="buow-type-input" id="selectFieldBUOWType" name="buowType" value={props.formData.buowType} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value={'Document'}>Document</option>
                  <option value={'Form'}>Form</option>
                  <option value={'File Review'}>File Review</option>
                  <option value={'Envelope Review'}>Envelope Review</option>
                </select>
                {(props.formData.buowType === null || props.formData.buowType === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.buowType?.length > 35 && handleMaxLimitError(35)}
              </div>
              <div data-testid="buow-name-container" className={styles.inputFieldInternalDiv}>
                <label>BUOW Name *</label>
                <input data-testid="buow-name-input" required name="buowName" value={props.formData.buowName} onChange={handleInputChange} />
                {/* <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadBuowNameList}
                  value={props?.formData?.buowName !== '' ? { label: props?.formData?.buowName, value: props?.formData?.buowName } : null}
                  onChange={onChangeBuowName}
                /> */}
                {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.buowName?.length > 35 && handleMaxLimitError(35)}
              </div>

              <div data-testid="buow-kp1-container" className={styles.inputFieldInternalDiv}>
                <label>KPI 1 Display Name</label>
                <input data-testid="buow-kp1-input" required name="kpi1DisplayName" value={props.formData.kpi1DisplayName} onChange={handleInputChange} />
                {props.formData.kpi1DisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div data-testid="buow-kp2-container" className={styles.inputFieldInternalDiv}>
                <label>KPI 2 Display Name </label>
                <input data-testid="buow-kp2-input" required name="kpi2DisplayName" value={props.formData.kpi2DisplayName} onChange={handleInputChange} />
                {props.formData.kpi2DisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>

              <div data-testid="buow-kp3-container" className={styles.inputFieldInternalDiv}>
                <label>KPI 3 Display Name</label>
                <input data-testid="buow-kp3-input" required name="kpi3DisplayName" value={props.formData.kpi3DisplayName} onChange={handleInputChange} />
                {props.formData.kpi3DisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div data-testid="buow-kp4-container" className={styles.inputFieldInternalDiv}>
                <label>KPI 4 Display Name</label>
                <input data-testid="buow-kp4-input" required name="kpi4DisplayName" value={props.formData.kpi4DisplayName} onChange={handleInputChange} />
                {props.formData.kpi4DisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>

              <div data-testid="screen-display-name" className={styles.inputFieldInternalDiv}>
                <label>Screen Display Name </label>
                <input data-testid="screen-display-name-input" required name="screenDisplayName" value={props.formData.screenDisplayName} onChange={handleInputChange} />
                {props.formData.screenDisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div data-testid="container-display-name" className={styles.inputFieldInternalDiv}>
                <label>Container Display Name </label>
                <input data-testid="container-display-name-input" required name="containerDisplayName" value={props.formData.containerDisplayName} onChange={handleInputChange} />
                {props.formData.containerDisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>

              <div data-testid="buow-display-name" className={styles.inputFieldInternalDiv}>
                <label>BUOW Display Name</label>
                <input data-testid="buow-display-name-input" required name="buowDisplayName" value={props.formData.buowDisplayName} onChange={handleInputChange} />
                {props.formData.buowDisplayName?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <div data-testid="display-clipboard" className={styles.radioInputOuterDiv}>
                  <label className="mr-2">Display Clipboard: </label>
                  <input
                    data-testid="display-clipboard-input"
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="displayClipboard"
                    value={true}
                    checked={props.formData.displayClipboard === true}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2">True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="displayClipboard"
                    value={false}
                    checked={props.formData.displayClipboard === false}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.BUOWDisplayNameFooter}>
          <div data-testid="footer-container" style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
