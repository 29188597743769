import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
// import { useRecordReferencesContext } from "../../components/RecordReferences/RecordReferencesProvider";

export default function HoldTypeDropDownList(props) {
  const { value, onChange } = props;
  // const {holdtype = []} = useRecordReferencesContext();

  // const holdtype=['Audit', 'Litigation', 'Congressional Inquiry', 'Research', 'Legal Counsel']

  const holdtype = []


  return (
    <DropDownList
      data={holdtype}
      className="fsa-field__item"
      onChange={onChange} value={value}
    />
  )
}