import styles from './UpdateFileCodeList.module.scss';

export default function UpdateFileCodeList({
  // dataItems,
  setFileCode
}) {
  const updateChange = (e, dataItem) => {
    setFileCode(dataItem.filecodeId);
  }
  const dataItems = [
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS',
      filecodeUuid:"0055b5a1-cef9-44b4-8b8b-6976e3847017"
    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS',
      filecodeUuid:"0055b5a1-cef9-44b4-8b8b-6976e3847017"
    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS',
      filecodeUuid:"0055b5a1-cef9-44b4-8b8b-6976e3847017"

    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS',
      filecodeUuid:"0055b5a1-cef9-44b4-8b8b-6976e3847017"

    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS'
    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS',
      filecodeUuid:"0055b5a1-cef9-44b4-8b8b-6976e3847017"

    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS'
    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS'
    },
    {
      filecodeId: " 0055ba1-soldre -44b4-65987524",
      recordtitle: "Fedreal Fedreal Register Federa",
      scheduleName: " test-lorem-88546-a25f3v5",
      systemoforigin: " Conservation",
      primaryorg: 'NRCS'
    }
  ]

  return (
    <div
      style={{ paddingRight: "0px" }}
      className="row "
    >
      <div style={{ paddingRight: "0px" }} className="col-12">
        <ul className="fsa-form-list" aria-labelledby="lorem-radio-field-1">
          {dataItems.map(dataItem => {
            return <li onChange={(e) => updateChange(e, dataItem)}>
              <div style={{ color: "white", paddingBottom: "5px", display: "flex", alignItems: "flex-end" }}
                className={styles.filecodeJobHeader}
              // className="filecode-job-header border-bottom border-top"

              >
                <span className={styles.headerColumn}><b>Item ID: </b>{dataItem.filecodeId} </span>
                <span className={styles.headerColumn}><b>Record Title: </b>{dataItem.recordtitle}</span>
                <span className={styles.headerColumn}><b>Schedule ID: </b>{dataItem.scheduleName}</span>
                {/* <span className={styles.headerColumn}><b>Schedule ID: </b>{dataItem.schedule.scheduleName}</span> */}

                <span className={styles.headerColumn}><b>System of Origin: </b>{dataItem.systemoforigin} </span>
              </div>
              <div className={styles.filecodeJobDescription}>
                <div
                  style={{
                    fontSize: 13,
                    float: "left",
                    minWidth: "10%",
                  }}
                >
                  <input className="fsa-radio" id={dataItem.filecodeUuid} type="radio" name="filecode"></input>
                  <label
                    for={dataItem.filecodeUuid}><b>{dataItem.primaryorg}
                      {/* {dataItem.primaryorg + (dataItem.secondaryorg ? " | " + dataItem.secondaryorg : "")} */}
                    </b></label>
                </div>
                <div
                  style={{
                    fontSize: 13,
                    float: "left",
                    maxWidth: "60%",
                    wordBreak: "break-word",
                    position: "sticky",
                    left: "15%"
                  }}
                >
                  <b>Description:</b> {dataItem.primarydescription}
                </div>
              </div>
            </li>
          })}
        </ul>
        {dataItems.length < 1 && <span>Please refine your search. No file Codes found!</span>}

      </div>
    </div>

  );
};