import React from 'react';

export default function HoldSuccessGrowl({isHoldSuccessGrowlHidden, setIsHoldSuccessGrowlHidden}) {

  return (
    <div className="fsa-growl-container">
      <div className="fsa-growl fsa-growl--success" id="" aria-hidden={isHoldSuccessGrowlHidden} tabIndex="0" role="dialog">
        <div className="fsa-growl__hd"></div>
        <div className="fsa-growl__bd">
          <b><p>Success, Hold Created!</p></b>
        </div>
      </div>
    </div>
  );
}

