import React, { useEffect, useState,lazy } from 'react';

// import './UnitOfWork.scss';
import styles from './UnitOfWork.module.scss';
import '../../../GlobalStyle.scss';

// import UnitOfWorkTable from './UnitOfWorkTable';
import UnitOfWorkData from './UnitOfWorkData.json';

import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { searchUnitOfWork } from './UnitOfWorkService';

// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Unit of Work', link: '' },
];

const UnitOfWorkTable = lazy(() => import('./UnitOfWorkTable'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function UnitOfWork() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [unitOfWorkDetails, setUnitOfWorkData] = useState(UnitOfWorkData);

  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchUnitOfWorkData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchUnitOfWorkData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);

      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);

      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUnitOfWorkData = JSON.parse(JSON.stringify(unitOfWorkDetails));
    newUnitOfWorkData.forEach((itm) => {
      itm.sortType = '';
    });
    setUnitOfWorkData(newUnitOfWorkData);
    await fetchNewUnitOfWorkData();
  };

  // Code needs to change
  const fetchNewUnitOfWorkData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);

      setLoading(false);
    });
  };

  return (
    <div className={styles.unitOfWorkPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.unitOfWorkPageContent}>
        <h1 className={styles.pageTitle}>Unit of Work</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.tableUnitOfWork, 'glbTableUnitOfWork'].join(' ')}>
          <UnitOfWorkTable
            data={data}
            page={page}
            pageSize={pageSize}
            fields={unitOfWorkDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
      </div>
    </div>
  );
}
