import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";

export default function RecordTypeDropDownList(props) {
  const {value, onChange} = props;
  const {recordtype = []} = useRecordReferencesContext()

  return (
    <DropDownList
      data={recordtype}
      className="fsa-field__item"
      onChange={onChange}
      value={value}
    />
  )
}


