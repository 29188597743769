import React, { useContext, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';
import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import HoldTypeDropDownList from './FormModules/HoldTypeDropDownList';
import HoldStatusRadioGroup from './FormModules/HoldStatusRadioGroup';
import FormTextArea from './FormModules/FormTextArea';
import UserContext from '../UserContext/UserContext';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import FormDatepicker, { convertDateToString } from '../FormModules/FormDatepicker';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';

export default function HoldAdvSearch({ setHoldData, handleAdvModal, initialValues = {}, setInitialValues }) {
  const { userInfo } = useContext(UserContext);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');

  const onSearch = (search) => {
    const effectiveDate = convertDateToString(search.values.effectiveDate);
    const expirationDate = convertDateToString(search.values.expirationDate);
    const data = omitBy(
      {
        ...search.values,
        effectiveDate,
        expirationDate,
      },
      isEmpty
    );

    setInitialValues(data);

    axios
      .post('/recordsmanagementapi/records-management/holds/search', data, {
        headers: {
          userName: userInfo.userName,
          usdaeauthid: userInfo.usdaeauthid,
          Authorization: sessionStorage.getItem('userJwt') || '',
        },
      })
      .then((response) => {
        setHoldData(response.data);
        handleAdvModal();
      })
      .catch((err) => {
        console.error(err);
        setMessage(err.message);
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          setMessage('');
        }, 5000);
        // addNotification({ style: 'error', msg: err.message });
      });
  };

  // This is just for displaying current values. This does not change the initial values.
  const onResetForm = ({ onChange }) => {
    onChange('holdName', { value: '' });
    onChange('holdtype', { value: '' });
    onChange('responsibleOfficerEmail', { value: '' });
    onChange('responsibleOfficer', { value: '' });
    onChange('effectiveDate', { value: null });
    onChange('expirationDate', { value: null });
    onChange('holdstatus', { value: '' });
    onChange('participants', { value: '' });
    onChange('holdScope', { value: '' });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmitClick={onSearch}
      render={(fieldRenderProps) => {
        return (
          <div>
            <div className="row">
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Hold Name</label>
                <Field name={'holdName'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Type of Hold</label>
                <Field name={'holdtype'} component={HoldTypeDropDownList} />
              </FieldWrapper>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Responsible Staff Email</label>
                <Field name={'responsibleOfficerEmail'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Responsible Staff</label>
                <Field name={'responsibleOfficer'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Hold Effective Date</label>
                <Field name={'effectiveDate'} className=" fsa-field__item" component={FormDatepicker} />
              </FieldWrapper>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Hold Expiration Date</label>
                <Field name={'expirationDate'} className=" fsa-field__item" component={FormDatepicker} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-4'}>
                <label className="fsa-field__label">Hold Declaration Status</label>
                <Field name={'holdstatus'} component={HoldStatusRadioGroup} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Participants/Litigants</label>
                <Field name={'participants'} component={FormTextArea} />
              </FieldWrapper>
            </div>

            <div className={'row mt-4'}>
              <FieldWrapper className={'col-12'}>
                <label className="fsa-field__label">Hold Scope</label>
                <Field name={'holdScope'} component={FormTextArea} />
              </FieldWrapper>
            </div>

            <div className="row mt-5">
              <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                <button className="fsa-btn" onClick={fieldRenderProps.onSubmit}>
                  Search
                </button>
                <button onClick={() => onResetForm(fieldRenderProps)} className="fsa-btn fsa-btn--secondary">
                  Clear
                </button>
              </div>
            </div>
            <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
          </div>
        );
      }}
    />
  );
}
