import React from 'react'
import styles from "./LogsTab.module.scss";
const LogsTab = () => {
    return (
        <div className={styles.logsSection}>
            <div>
                <button className={styles.downloadLogBtn}>Download Logs</button>
            </div>

            <div className={styles.objectHistorySection}>
                <label className={styles.labelText}>Object History</label>
                <button className={styles.logsSectionBtn}>
                    <small className={styles.logsSectionBtnText}>Created Object</small>
                    <small className={styles.logsSectionBtnText}>2023-06-19 18:32:45</small>
                </button>
            </div>

            <div>
                <label className={styles.labelText}>Records Management History</label>
                <button className={styles.logsSectionBtn}>
                    <small className={styles.logsSectionBtnText}>Added Date </small>
                    <small className={styles.logsSectionBtnText}>2023-06-19 18:32:45</small>
                </button>

                <button className={styles.logsSectionBtn}>
                    <small className={styles.logsSectionBtnText}>File Name</small>
                    <small className={styles.logsSectionBtnText}>Employee Performance...</small>
                </button>

            </div>
        </div>
    )
}

export default LogsTab;
