import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';
import _ from 'lodash';

const options = ['Removable Media', 'FTP', 'Direct Access', 'Other'];
const sortedOptions = _.sortBy(options);

export default function TransferMediaDropDownList(props) {
  const { label, value, onChange, valid, ...rest } = props;
  return (
    <div>
      <label className="fsa-field__label">{label}</label>
      <DropDownList data={sortedOptions} className="fsa-field__item" onChange={onChange} value={value} {...rest} />
      {!valid && <div className="validation-message">This field is required *</div>}
    </div>
  );
}
