//When we do for DRMS, we would look at the data type that comes from schema api to determine type of search
//In back end should probably make greater than do max time and less than do max time
export function CreateSearchObjectSearch(searchParamsObjArray, sort = undefined, docType, state, county) {
  let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));

  var metadataSearchObject = {
    searchCriteria: [],
    sort: [
      // {
      //   fieldName: 'object_type.edition_date',
      //   dataType: 'DATE',
      //   order: 'DESC',
      // },
    ],
    aggregation: [],
  };
  searchParamsObjArray.forEach((param) => {
    let condition;
    //For DRMS, we would check if data type is boolean, do EQUALS. Else use CONTAINS
    if (
      param.key === 'is_reviewed' ||
      param.key === 'is_corrected' ||
      param.key === 'internal_review_required' ||
      param.key === 'object_specific.contextID' ||
      param.key === 'object_specific.smc_department' ||
      param.key === 'doc_repo.is_latest'
    ) {
      condition = 'EQUALS';
    } else {
      // Check each systemFolder to ensure param.key matches object_specific.<name>
      let foundMatch = false;
      systemFolders.forEach((objectKey) => {
        if (param.key === `object_specific.${objectKey.name}`) {
          foundMatch = true;
        } else {
          foundMatch = false;
        }
      });
      if (foundMatch === true) {
        condition = 'EQUALS';
      } else {
        condition = 'CONTAINS';
      }
    }
    if (param.key === 'keywordSearch') return;
    if (param.key === 'Producer Identified') {
      metadataSearchObject.searchCriteria.push({
        condition: condition,
        field1: {
          name: '*core_customer_id',
          value: [param.core_customer_id],
        },
      });
    } else {
      metadataSearchObject.searchCriteria.push({
        condition: param.value === '' ? 'NOT_EXISTS' : condition,
        field1: {
          name: param.key,
          value: [param.value],
        },
      });
    }
  });

  if (docType && docType.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_type.object_type_name',
        value: [docType.objectTypeName],
      },
    });
  }

  if (state && state.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_specific.state_name',
        value: [state.name],
      },
    });
  }

  if (county && county.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_specific.county_name',
        value: [county.name],
      },
    });
  }

  if (sort !== undefined && sort.length > 0) {
    metadataSearchObject.sort = {
      fieldName: sort[0].field,
      order: sort[0].dir.toUpperCase(),
    };
  }

  let keywordSearch = searchParamsObjArray.find((i) => i.key === 'keywordSearch');
  if (keywordSearch) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'doc_repo.is_latest',
        value: [true],
      },
      field2: {
        value: keywordSearch?.value,
      },
    });
  } else {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'doc_repo.is_latest',
        value: [true],
      },
    });
  }
  // console.log(metadataSearchObject);
  return metadataSearchObject;
}
