import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

/**
 * SearchAdminPanelComponent is a reusable component for keyword-based search in the admin panel.
 *
 * This component provides a search input field, a "Search" button, and a "Clear All" button.
 *
 * @component
 */

const SearchAdminPanelComponent = ({ searchText, onSearch, clearSearch, handleSearch, inputDisabled, testId }) => {
  return (
    <>
      <div className={styles.searchContent} style={{ gap: 15 }}>
        <input
          data-testid={testId}
          className={styles.keywordSearchInput}
          placeholder="Start typing to search"
          value={searchText}
          onChange={handleSearch}
          disabled={inputDisabled}
        />
        {/* <CustomButton title="Search" disabled={!searchText} className={[styles.keywordSearchBtn, 'ml-3'].join(' ')} onClick={onSearch} /> */}

        {searchText && <CustomButton title="Clear All" className={[styles.clearAllBtnStyle, 'ml-2'].join(' ')} onClick={clearSearch} data-testid="clear-search-button" />}
      </div>
    </>
  );
};

export default SearchAdminPanelComponent;

SearchAdminPanelComponent.propTypes = {
  /** A required string prop for the current search text. */
  searchText: PropTypes.string.isRequired,
  /** A required function prop for handling the "Search" button click. */
  onSearch: PropTypes.func.isRequired,
  /** A required function prop for handling the "Clear All" button click. */
  clearSearch: PropTypes.func.isRequired,
  /** A required function prop for handling changes in the search input. */
  handleSearch: PropTypes.func.isRequired,
};
