import React, { useEffect, useState } from 'react';
import '../FileCode/FileCode.scss';
import '../../GlobalStyle.scss';
import ManageDispositionData from './ManageDispositionData.json';
import { getDisposition, approveDisposition, deleteRecords } from '../../services/recordsManagementService';
import TableManageDisposition from './TableManageDisposition';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
import UserContext from '../UserContext/UserContext';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import CustomButton from '../../newcomponents/CustomButton/CustomButton';

export default function ManageDisposition() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const { userPermissions } = React.useContext(UserContext);

  useEffect(() => {
    function data() {
      getDispositions();
    }
    return data();
  }, []);

  const getDispositions = () => {
    try {
      setIsLoading(true);
      getDisposition().then((response) => {
        setData(response.data);
        setIsLoading(false);
      });
    } catch (ex) {
      console.log('Holds Error: ', ex.message);
    }
  };
  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
    setSelectedRows([]);
    setSelectAllChecked(false);
  };

  const showSelectedRows = () => {
    setModalShow(true);
  };

  const ApproveDispositionSubmit = () => {
    setIsLoading(true);

    let newData = selectedRows?.map((obj) => {
      return obj.guid;
    });

    try {
      approveDisposition(newData).then((response) => {
        if (response.data) {
          let guiDs = Object.keys(response.data.data);
          deleteRecords(guiDs).then((response) => {
            if (response.data) {
              getDispositions();
              setIsLoading(true);
              setIsLoading(false);
              setModalShow(false);
              setSelectedRows([]);
              setIsSuccessGrowlHidden(false);
              setMessage('One or more dispositions approved successfully.');
              // EventEmitter.emit('refreshFileCodeGrid');

              setTimeout(() => {
                setIsSuccessGrowlHidden(true);
              }, 5000);
            }

            if (response.error) {
              setMessage(response.error.data.message ? response.error.response.data.message : 'Something went wrong');
              setIsErrorGrowlHidden(false);
              setTimeout(() => {
                setIsErrorGrowlHidden(true);
                setIsLoading(false);
              }, 5000);
            }
          });
        }
        if (response.error) {
          setMessage(response.error.data.message ? response.error.response.data.message : 'Something went wrong');
          setIsErrorGrowlHidden(false);
          setTimeout(() => {
            setIsErrorGrowlHidden(true);
            setIsLoading(false);
          }, 5000);
        }
      });
    } catch (ex) {
      console.log('Approve disposition Error: ', ex.message);
    }
  };

  return (
    <div className="content">
      {isLoading && <GlobalLoader />}

      <div className="pageContent">
        <h1 className="HeaderOne-StyleOne">Manage Disposition</h1>
        <div className="flex">
          <div className="fc-grid-head-row">
            <div className="h3-div-2">Eligible Objects</div>
            {userPermissions['Approve_Management_Disposition'] === true && (
              <div className="form-field">
                <button disabled={selectedRows.length === 0 ? true : false} className="fsa-btn lite-btn" onClick={showSelectedRows} confirm="Do you want to approve disposition ?">
                  Approve Disposition
                </button>
              </div>
            )}
          </div>
        </div>
        <TableManageDisposition
          data={data}
          fields={ManageDispositionData}
          page={page} // Pass the page state from the parent component
          pageSize={pageSize} // Pass the pageSize state from the parent component
          onPageChange={onPageChange}
          setSelectedRows={setSelectedRows}
          setRowsChecked={setRowsChecked}
          selectedRows={selectedRows}
          setSelectAllChecked={setSelectAllChecked}
          selectAllChecked={selectAllChecked}
          getDispositions={getDispositions}
        />

        {modalShow && (
          <ConfirmationModel
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            show={modalShow}
            selectedRows={selectedRows}
            setModalShow={setModalShow}
            onHide={() => setModalShow(false)}
            getDispositions={() => getDispositions()}
            ApproveDispositionSubmit={ApproveDispositionSubmit}
          />
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}

const ConfirmationModel = (props) => {
  return (
    <Modal {...props} size="lg" className="glbDispositionModalStyle" centered>
      <div className="disposition_modal_popup ">
        <Modal.Header>
          <div className="modal_header_section">
            <div>
              <h2 className="modal_header_panel_text">Approve Disposition {props.popupTitle}</h2>
            </div>
            <div className="modal_header_panel_icon" onClick={props.onHide}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-2 py-5">
            <div className="disposition_modal_body_text">Do you want to approve disposition ? </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="modal_footer_section">
            <CustomButton title="Confirm" className="modal_footer_disposition_button" onClick={props.ApproveDispositionSubmit} />
            <CustomButton title="Cancel" className="modal_footer_cancel_button" onClick={props.onHide} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
