import React from 'react';

/**
 * GlobalLoader component for displaying a global loading indicator.
 *
 * This component renders a loading indicator overlay with a "Loading" message and animation.
 *
 * @component
 */

export const GlobalLoader = (props) => {
  return (
    <div className="k-loading-mask" style={{ zIndex: 9999999 }}>
      {props.text ? (
        <div className="fsa-splash w-100">
          <div className="fsa-splash__bd">
            <div className="fsa-splash__item">
              <div className="fsa-splash__progress">
                <div className="fsa-progress fsa-progress--indeterminate" aria-live="polite">
                  <div className="fsa-progress__details fsa-sr-only">
                    <div className="fsa-progress__label">Application is loading</div>
                  </div>
                  <div className="fsa-progress__bar" aria-hidden="true">
                    <div className="fsa-progress__primary"></div>
                    <div className="fsa-progress__secondary"></div>
                  </div>
                </div>
              </div>
              <div className="fsa-splash__title">
                {/* <span className="fsa-splash__title-abbr">DRIMS</span> */}
                <span className="fsa-splash__title-abbr" style={{ fontSize: '1.30rem', padding: '1rem', whiteSpace: 'nowrap' }}>
                  {props.text}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <span className="k-loading-text">Loading ...</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
        </>
      )}
    </div>
  );
};
