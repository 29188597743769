import React, { useEffect, useState,lazy } from 'react';
import InBasketTableData from './InBasketTableData.json';
// import TableInBasketPage from './TableInBasketTable.js';
import { searchInBasketTable } from './InBasketTableServices.js';
import styles from './InBasketTable.module.scss';
import '../../../GlobalStyle.scss';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl.js';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js';
const TableInBasketPage = lazy(() => import('./TableInBasketTable.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'In Basket', link: '' },
];

export default function AdminInBasketPage() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [inBasketTableDetails, setInBasketTableData] = useState(InBasketTableData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchInBasketTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchInBasketTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchInBasketTableData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    // await fetchInBasketTableData();
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchInBasketTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchInBasketTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newInBasketTableData = JSON.parse(JSON.stringify(inBasketTableDetails));
    newInBasketTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setInBasketTableData(newInBasketTableData);
    await fetchNewInBasketTableData();
  };

  // Code needs to change
  const fetchNewInBasketTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchInBasketTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchInBasketTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.inBasketTable}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.inBasketTableContent}>
        <h1 className={styles.pageTitle}>In Basket</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>

        <div className={[styles.tableInBasketTable, 'glbTableInBasketTable'].join(' ')}>
          <TableInBasketPage
            data={data}
            fields={inBasketTableDetails}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}
