import './FileCodeList.scss';

export default function FileCodeList({ dataItems, setFileCode, expand }) {
  const updateChange = (e, dataItem) => {
    setFileCode(dataItem.filecodeId);
  };

  return (
    <div style={{ paddingRight: '0px' }} className="row ">
      <div style={{ paddingRight: '0px', width: expand === 'FULLSCREEN' ? '100vw' : '100%' }} className="col-12">
        <ul className="fsa-form-list" aria-labelledby="lorem-radio-field-1">
          {dataItems.map((dataItem) => {
            return (
              <li onChange={(e) => updateChange(e, dataItem)} style={{ marginTop: 0 }}>
                <div
                  style={{ color: 'white', paddingBottom: '5px', display: 'flex', alignItems: 'flex-end', background: '12A5E4', paddingLeft: 20 }}
                  className="filecode-job-header border-bottom border-top"
                >
                  <span className="headerColumn">
                    <b>File Code: </b>
                    {dataItem.filecodeId}{' '}
                  </span>
                  <span className="headerColumn">
                    <b>Record Title: </b>
                    {dataItem.recordtitle}
                  </span>
                  <span className="headerColumn">
                    <b>Schedule ID: </b>
                    {dataItem.schedule.scheduleName}
                  </span>
                  <span className="headerColumn">
                    <b>System of Origin: </b>
                    {dataItem.systemoforigin}{' '}
                  </span>
                </div>
                <div id="filecode-job-description" className="filecode-job-description" style={{ backgroundColor: '#CBEFFF', paddingLeft: 20 }}>
                  <div
                    style={{
                      fontSize: 13,
                      float: 'left',
                      minWidth: '10%',
                    }}
                  >
                    <input className="fsa-radio" id={dataItem.filecodeUuid} type="radio" name="filecode"></input>
                    <label for={dataItem.filecodeUuid}>
                      <b>{dataItem.primaryorg + (dataItem.secondaryorg ? ' | ' + dataItem.secondaryorg : '')}</b>
                    </label>
                  </div>
                  <div
                    style={{
                      fontSize: 13,
                      float: 'left',
                      maxWidth: '60%',
                      wordBreak: 'break-word',
                      position: 'sticky',
                      left: '15%',
                    }}
                  >
                    <b>Description:</b> {dataItem.primarydescription}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {dataItems.length < 1 && <span>Please refine your search. No file Codes found!</span>}
      </div>
    </div>
  );
}