import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import recordIcon from '../../fsa-style/img/svgs/record_grey.svg';
import recordIconWhite from '../../fsa-style/img/svgs/record_white.svg';
import scanIcon from '../../fsa-style/img/svgs/scan_grey.svg';
import scanIconWhite from '../../fsa-style/img/svgs/scan_white.svg';
import buowIcon from '../../fsa-style/img/svgs/buow_white.svg';
import Administrator from '../../fsa-style/img/svgs/admin_blue.svg';
import AdministratorIconWhite from '../../fsa-style/img/svgs/admin_white.svg';
import dashboardIcon from '../../fsa-style/img/svgs/dashboard_grey.svg';
import dashboardIconWhite from '../../fsa-style/img/svgs/dashboard_white.svg';
import './SideNavBar.scss';
import UserContext from '../UserContext/UserContext';
import { fixedPermissions } from '../../utils/helper/permissionFinder';
import FetchLocalStorageDataByPath from '../../utils/helper/FetchLocalstorageDataByPath';
import { EventEmitter } from '../../services/events';

const SideNavBar = ({ logout }) => {
  // Commented changes for login page
  // const [selectedLink, isLinkSelected] = useState("inbasketpage");
  const [selectedLink, isLinkSelected] = useState('/');
  const [showPopup, setPopupVal] = useState(false);
  const userRole = JSON.parse(sessionStorage.getItem('user_information'))?.rolesName;
  const [isFeatureActive, setisFeatureActive] = useState({});

  const isSuperUser = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.superUser;

  const adminPanelRoutes = [
    'admin',
    'orchestration',
    'routingsteps',
    'systemconfiguration',
    'ruledefinition',
    'userstable',
    'rolestable',
    'permissionstable',
    'user-preference',
    'system-folder',
    'buowtable',
    'userguide',
    'work-group',
    'messages',
    'inbaskettable',
    'buowdisplayname',
    'work-group',
    'unit-of-work',
    'workgroupuserxwalk',
    'rule-log',
    'ruletrigger',
  ];

  const visibilityRoutes = ['dashboard', 'search', 'inbasket', 'adminpanel', 'recordmanagement', 'scanondemand'];

  const getLocalStorageDataFunc = async () => {
    try {
      const fetchedLocalStorageData = FetchLocalStorageDataByPath('system_config', 'features') ?? [];
      await fetchedLocalStorageData.length > 0 && fetchedLocalStorageData.map((values) => {
        let newKey = values.title.toLowerCase().replace(/\s/g, '');
        if (visibilityRoutes.map((item) => item === newKey)) {
          setisFeatureActive((prev) => ({
            ...prev,
            [newKey]: values.value,
          }));
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLocalStorageDataFunc();

    EventEmitter.on('LocalStorageUpdated', getLocalStorageDataFunc);

    return () => {
      EventEmitter.off('LocalStorageUpdated', getLocalStorageDataFunc);
    };
  }, []);

  const recordMgmtRoutes = ['fileplan', 'filecode', 'holds', 'transfer', 'manage'];

  const { userPermissions, setUserPermissions } = useContext(UserContext);

  const onSelectLink = (val) => {
    setPopupVal(false);
    isLinkSelected(val);
  };

  const location = useLocation();

  useEffect(() => {
    async function data() {
      findPermissions();
    }
    data();
    isLinkSelected(window.location.pathname.length > 1 ? window.location.pathname.split('/')[1] : window.location.pathname);
  }, [location]);

  const handleLeave = () => setPopupVal(false);

  const handleHover = () => setPopupVal(true);
  const findPermissions = async () => {
    let x = JSON.parse(sessionStorage.getItem('user_information')) !== undefined ? JSON.parse(sessionStorage.getItem('user_information'))?.rolePermissions : [];
    let obj = {};
    if (x !== undefined && x.length > 0) {
      await x.map((i) => {
        if (fixedPermissions.includes(i) === true) {
          obj[i] = true;
        }
        return null;
      });

      setUserPermissions(obj);
    }
  };
  return (
    <>
      {/* navbar Start */}
      <div className="navBarcontainer">
        <div className="nav_active">
          {isFeatureActive?.inbasket === true && selectedLink !== 'buow' && (
            <Link
              to={'/inbasketpage'} // Commented changes for login page
              // className={selectedLink === "inbasketpage" ? "abcLink1 thinLine" : "navLink"}   // Commented changes for login page
              className={selectedLink === 'inbasketpage' || selectedLink === '/' ? 'abcLink1 thinLine' : 'navLink'}
              onClick={() => onSelectLink('inbasketpage')}
            >
              {/* <div className='Categories'>Categories</div> */}
              <i className="fa fa-house Categories"></i>
            </Link>
          )}

          {selectedLink === 'buow' && (
            <Link to={'/'} className={selectedLink === 'buow' ? 'abcLink1 thinLine' : 'navLink'} onClick={() => onSelectLink('inbasketpage')}>
              {selectedLink === 'buow' ? <img src={buowIcon} alt="location" className="scan_base_icon" /> : <i className="fa fa-house Categories"></i>}
            </Link>
          )}
          {/* <Link
                to={'suspenseQueue'}
                className={selectedLink === 'scanner' ? "abcLink1" : 'navLink'} onClick={() => onSelectLink('scanner')}>
                <img
                style={{
                  height: "30px",
                  marginBottom: ".5em"
                }}
                src="/img/scanner.svg"
                alt="excel icon"
              >
              </img>
              </Link> <br /> */}
          {isFeatureActive?.scanondemand === true && (
            <Link to={'/scan'} className={selectedLink === 'scan' ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('scan')}>
              {selectedLink === 'scan' ? <img src={scanIconWhite} alt="location" className="scan_base_icon" /> : <img src={scanIcon} alt="location" className="scan_base_icon" />}
            </Link>
          )}

          {isFeatureActive?.search === true && userPermissions['Search_Page'] === true && (
            <Link to={'/search'} className={selectedLink === 'search' ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('search')}>
              <i className="fa fa-magnifying-glass Categories"></i>
            </Link>
          )}

          {/* Temporary For new search */}
          {/* <Link to={'/newsearch'} className={selectedLink === 'newsearch' ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('newsearch')}>
            <i className="fa fa-magnifying-glass Categories"></i>
          </Link> */}

          {/* <Link
            to={"fileplan"}
            className={selectedLink === "fileplan" ? "abcLink1" : "navLink"}
            onClick={() => onSelectLink("fileplan")}
          >
            {selectedLink === "fileplan" ? (
              <img src={recordIconWhite} alt="location" className="base_icon" />
            ) : (
              <img src={recordIcon} alt="location" className="base_icon" />
            )}
          </Link> */}
          {/* <Link to={'filecode'} className={selectedLink === 'filecode' ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('filecode')}>
            {selectedLink === 'filecode' ? <img src={recordIconWhite} alt="location" className="base_icon" /> : <img src={recordIcon} alt="location" className="base_icon" />}
          </Link> */}
          {isFeatureActive?.recordmanagement === true && userPermissions['Record_Management'] === true && (
            <Link
              to={'fileplan'}
              className={recordMgmtRoutes.includes(selectedLink) === true ? 'abcLink1' : 'navLink'}
              onClick={() => onSelectLink('fileplan')}
              onMouseLeave={handleLeave}
              onMouseOver={handleHover}
            >
              {recordMgmtRoutes.includes(selectedLink) === true ? (
                <div>
                  <img src={recordIconWhite} alt="location" className="base_icon" />
                  {showPopup && <DropdownList selectedLink={selectedLink} onSelectLink={onSelectLink} userPermissions={userPermissions} />}
                </div>
              ) : (
                <div>
                  <img src={recordIcon} alt="location" className="base_icon" />
                  {showPopup && <DropdownList selectedLink={selectedLink} onSelectLink={onSelectLink} userPermissions={userPermissions} />}
                </div>
              )}
            </Link>
          )}

          {isFeatureActive?.dashboard === true && (
            <Link to={'/dashboard'} className={selectedLink === 'dashboard' ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('dashboard')}>
              {selectedLink === 'dashboard' ? (
                <img src={dashboardIconWhite} alt="location" className="base_icon" />
              ) : (
                <img src={dashboardIcon} alt="location" className="base_icon" />
              )}
            </Link>
          )}

          {((isSuperUser === true && userRole && userRole.length > 0 && (userRole.includes('Admin') || userRole.includes('Application Administrator'))) ||
            (!isSuperUser === true &&
              isFeatureActive?.adminpanel === true &&
              userRole &&
              userRole.length > 0 &&
              (userRole.includes('Admin') || userRole.includes('Application Administrator')))) && (
            <Link to={'/admin'} className={adminPanelRoutes.includes(selectedLink) === true ? 'abcLink1' : 'navLink'} onClick={() => onSelectLink('admin')}>
              {adminPanelRoutes.includes(selectedLink) === true ? (
                <img src={AdministratorIconWhite} alt="location" className="base_icon" />
              ) : (
                <img src={Administrator} alt="location" className="base_icon" />
              )}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

const DropdownList = (props) => {
  return (
    <div className="dropdownList">
      {props.userPermissions['Record_Schedule'] === true && (
        <Link to="/fileplan" onClick={() => props.onSelectLink('fileplan')}>
          <li className={props.selectedLink === 'fileplan' ? 'selectedLink' : ''}>Create Retention Instruction</li>
        </Link>
      )}

      {props.userPermissions['File_Management'] === true && (
        <Link to="/filecode">
          <li className={props.selectedLink === 'filecode' ? 'selectedLink' : ''}>Manage Record Schedule</li>
        </Link>
      )}

      {props.userPermissions['Hold_Management'] === true && (
        <Link to="/holds">
          <li className={props.selectedLink === 'holds' ? 'selectedLink' : ''}>Manage Holds</li>
        </Link>
      )}

      {props.userPermissions['Transfer_Management'] === true && (
        <Link to="/transfer">
          <li className={props.selectedLink === 'transfer' ? 'selectedLink' : ''}>Manage Transfers</li>
        </Link>
      )}

      {props.userPermissions['Disposition_Management'] === true && (
        <Link to="/manage">
          <li className={props.selectedLink === 'manage' ? 'selectedLink' : ''}>Manage Disposition</li>
        </Link>
      )}
    </div>
  );
};

export default SideNavBar;
