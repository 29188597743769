import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";
import {Error} from "@progress/kendo-react-labels";

export default function ScheduleNameDropDownList(props) {
  const {value, onChange, visited, validationMessage} = props;
  const {recordScheduleList} = useRecordReferencesContext()

  return (
    <>
      <DropDownList
        data={recordScheduleList}
        className={"fsa-field__item"}
        onChange={onChange}
        value={value}
        textField="scheduleName"
        dataItemKey={'scheduleUuid'}
        style={visited && validationMessage ? {border: '1px solid rgba(243, 23, 0, 0.5)'} : {}}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  )
}


