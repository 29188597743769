import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";

export default function ProgramStatusRadioGroup(props) {
  const {name, value, onChange} = props;
  const {programstatus = [],} = useRecordReferencesContext()
  const data = programstatus.map(s => ({label: s, value: s}))

  return (
    <RadioGroup
      data={data}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}