import { Grid, GridNoRecords } from "@progress/kendo-react-grid";
import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import calculateSize from "calculate-size";
import { RadioButton } from "@progress/kendo-react-inputs";
import { orderBy } from "@progress/kendo-data-query";
import "./table.scss";

const headerStyle = (column) => {
  return <a   
    style={{textAlign: "center", backgroundColor: "#236430"}} 
    className="k-link"
  >
    <span 
      style={{fontWeight: "bold", color: "white", fontSize: "16px", paddingRight: "1em"}}
      onClick={column.onClick}>{column.title} 
    </span>
    {/* {column.children} {column.sort} */}
  </a>
}
export const calculateWidth = (data, field, columnName) => {
  let maxWidth = 0;
  const size = calculateSize(columnName.replaceAll("_", " "), {
    font: "Arial",
    fontSize: "26px",
  });
  maxWidth = size.width;
  data.forEach(item => {
    const contentSize = calculateSize(item[field], {
      font: "Arial",
      fontSize: "24px",
    });
    if (contentSize.width > maxWidth) {
      maxWidth = contentSize.width;
    }
  });
  return maxWidth;
}

const determineSelectTypeDisplay = (type, onClick, isSelected) => {
  switch (type) {
    case 'radio':
      return <td>
        <div style={{display: 'grid', justifyContent: 'center'}}>
          <RadioButton
            checked={isSelected}
            onChange={onClick}
          />
        </div>
      </td>
    case 'view':
    default:
      return <td>
        <div className="selectCell">
          <p className="view fc-view" onClick={onClick}>View</p>
        </div>
      </td>
  }
}

export default function Table(props) {
  const {
    data = [],
    fields,
    onRowClick,
    selectType = 'view',
    total = data.length,
    page = 0,
    pageSize = 10,
    onPageChange,
  } = props;
  const [selectedState, setSelectedState] = React.useState(null);
  const [sort, setSort] = React.useState([])

  const clickCell = (props) => {
    const onClick = () => {
      setSelectedState(props.dataIndex)
      onRowClick(props)
    };
    const isSelected = selectedState === props.dataIndex
    return determineSelectTypeDisplay(selectType, onClick, isSelected)
  }

  return (
    <Grid
      {...props}
      total={total}
      data={orderBy(data, sort)}
      page={page}
      pageSize={pageSize}
      skip={page * pageSize}
      pageable={{
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true,
      }}
      onPageChange={onPageChange}
      sortable={true}
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
      }}
    >
      <GridNoRecords>
        No results
      </GridNoRecords>
      {
        data && fields.map((column, index) => {
          if (column.field === "SELECTED_FIELD") {
            return (
              <Column
                headerCell={headerStyle}
                field={'selected'}
                cell={clickCell}
                title={column.title}
                key={index}
                width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
              />
            )
          } else {
            return (
              <Column
                headerCell={headerStyle}
                field={column.field}
                title={column.title}
                key={index}
                cell={column.cell}
                width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
              />
            )
          }
        })
      }
    </Grid>
  )
}