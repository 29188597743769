import React, { useEffect, useState } from 'react';
import './SearchFileCodeFilters.scss';
import { searchFileCodes } from '../../../services/recordsManagementService';

export default function SearchFileCodeFilters({ setDataItems, setIsSearchingFileCode, setFileCode }) {
  const [primaryOrg, setPrimaryOrg] = useState(null);
  const [secondaryorg, setSecondaryOrg] = useState(null);
  const [schedId, setSchedId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [recordTitle, setRecordTitle] = useState(null);
  const [systemOfOrigin, setSystemOfOrigin] = useState(null);
  const [keywordSearch, setKeywordSearch] = useState(null);

  useEffect(() => {}, [setPrimaryOrg, primaryOrg, secondaryorg, setSecondaryOrg, schedId, itemId, setItemId]);

  // const dropDownStyle = {
  //   multiValue: (styles) => {
  //     const color = '#205493';
  //     return {
  //       ...styles,
  //       backgroundColor: color,
  //       color: 'white',
  //     };
  //   },
  //   multiValueLabel: (styles) => ({
  //     ...styles,
  //     color: 'white',
  //   }),
  // };

  const retrieveFileCodesByCriteria = () => {
    const criteria = {};
    criteria.filecodestatus = 'Active';
    if (schedId) criteria.filecodeName = schedId;
    if (itemId) criteria.filecodeId = itemId;
    if (recordTitle) criteria.recordtitle = recordTitle;
    if (systemOfOrigin) criteria.systemoforigin = systemOfOrigin;
    if (keywordSearch) criteria.keywords = keywordSearch;
    if (primaryOrg) criteria.primaryorg = primaryOrg;
    setIsSearchingFileCode(true);
    searchFileCodes(criteria).then((response) => {
      setDataItems(response.data.length > 25 ? response.data.slice(0, 25) : response.data);
      setIsSearchingFileCode(false);
    });
  };

  const clearSearchCriteria = () => {
    setPrimaryOrg(null);
    setSecondaryOrg(null);
    setSchedId('');
    setItemId('');
    setRecordTitle('');
    setSystemOfOrigin('');
    setKeywordSearch('');
    setIsSearchingFileCode(true);
    setFileCode(null);
    searchFileCodes({}).then((response) => {
      setDataItems(response.data.length > 25 ? response.data.slice(0, 25) : response.data);
      setIsSearchingFileCode(false);
    });
  };

  const assignPrimaryOrgCriteria = (e) => {
    console.log(e, 'E');
    setPrimaryOrg(e.target.value === 'Any' ? null : e.target.value);
  };

  const assignSecondaryOrgCriteria = (e) => {
    setSecondaryOrg(e.target.value === 'Any' ? null : e.target.value);
  };

  return (
    <div style={{ marginTop: '1em', paddingRight: '25px' }}>
      <div className="fsa-field updateFilterInput">
        <label className="fsa-field__label" htmlFor="jobStatusFilter">
          <b>Primary Organization</b>{' '}
        </label>
        <select
          id="primaryOrganization"
          value={primaryOrg ? primaryOrg : 'Any'}
          onChange={(e) => assignPrimaryOrgCriteria(e)}
          className="fsa-select w-100"
          name="Primary Organization"
          aria-describedby="Primary Organization Dropdown"
        >
          <option value={null} selected="selected">
            Any
          </option>
          <option value={process.env.REACT_APP_CLIENT_NAME}>{process.env.REACT_APP_CLIENT_NAME}</option>
          {/* <option value="NRCS">NRCS</option>
          <option value="RMA">RMA</option>
          <option value="FBC">FBC</option> */}
        </select>
      </div>
      <div className="fsa-field updateFilterInput">
        <label className="fsa-field__label" htmlFor="userFilter">
          <b>Secondary Organization</b>{' '}
        </label>
        <select
          className="fsa-select w-100"
          name="Secondary Organization"
          aria-describedby="Secondary Organization Dropdown"
          value={secondaryorg ? secondaryorg : 'Any'}
          onChange={(e) => assignSecondaryOrgCriteria(e)}
        >
          <option value="Option A" selected="selected">
            Any
          </option>
          {/* <option value="Option B">SMC</option>
          <option value="Option C">NRCS</option>
          <option value="Option D">RMA</option>
          <option value="Option E">FBC</option> */}
        </select>
      </div>
      {/* <div className="fsa-field updateFilterInput">
                <label className="fsa-field__label" htmlFor="scheduleIdFilter"><b>Schedule ID</b> </label>
                <input
                    value={schedId}
                    onInput={e => setSchedId(e.target.value)}
                    className="fsa-input"
                    type="text"
                    id="scheduleIdFilter"
                    aria-describedby="Schedule ID Filter"
                    aria-required="true"
                    name="scheduleIdFilter"
                    style={{width: '100%'}}
                />
            </div> */}
      <div className="fsa-field updateFilterInput">
        <label className="fsa-field__label" htmlFor="scheduleIdFilter">
          <b>File Code</b>{' '}
        </label>
        <input
          value={itemId}
          onInput={(e) => setItemId(e.target.value)}
          className="fsa-input"
          type="text"
          id="itemIdFilter"
          aria-describedby="Item ID Filter"
          aria-required="true"
          name="itemIdFilter"
          style={{ width: '100%' }}
        />
      </div>
      <div className="fsa-field updateFilterInput">
        <label className="fsa-field__label" htmlFor="scheduleIdFilter">
          <b>Record Title</b>{' '}
        </label>
        <input
          value={recordTitle}
          onInput={(e) => setRecordTitle(e.target.value)}
          className="fsa-input"
          type="text"
          id="recordTitleFilter"
          aria-describedby="Record Title Filter"
          aria-required="true"
          name="recordTitleFilter"
          style={{ width: '100%' }}
        />
      </div>
      <div className="fsa-field updateFilterInput">
        <label className="fsa-field__label" htmlFor="scheduleIdFilter">
          <b>System of Origin</b>{' '}
        </label>
        <input
          value={systemOfOrigin}
          onInput={(e) => setSystemOfOrigin(e.target.value)}
          className="fsa-input"
          type="text"
          id="systemOfOriginFilter"
          aria-describedby="System of Origin Filter"
          aria-required="true"
          name="systemOfOriginFilter"
          style={{ width: '100%' }}
        />
      </div>
      <div className="fsa-field mt-4 updateFilterInput">
        <label className="fsa-field__label" htmlFor="docTypeFilter">
          <b>Keyword Search</b>{' '}
        </label>
        <input
          value={keywordSearch}
          onInput={(e) => setKeywordSearch(e.target.value)}
          className="fsa-input"
          type="text"
          id="keywordSearchFilter"
          aria-describedby="Keyword Search Filter"
          aria-required="true"
          name="keywordSearchFilter"
          style={{ width: '100%' }}
        />
      </div>
      <button
        style={{
          marginTop: '1em',
          marginRight: '10px',
        }}
        className="fsa-btn"
        onClick={() => retrieveFileCodesByCriteria()}
      >
        Search
      </button>
      <button style={{ marginTop: '1em' }} className="fsa-btn fsa-btn--secondary " onClick={() => clearSearchCriteria()}>
        Clear
      </button>
    </div>
  );
}
