import { TextArea } from "@progress/kendo-react-inputs";
import React from "react";

export default function FormTextArea(props) {
  const {name, value, onChange, id, others} = props;
  return (
    <TextArea
      id={id}
      className="fsa-field__item"
      rows={1}
      name={name}
      value={value}
      onChange={onChange}
      {...others} 
    />
  )

}