import moment from 'moment';

const FormattedDate = ({ fetchedDate }) => {
  if (fetchedDate !== null) {
    let utcObj = moment.utc(fetchedDate).toDate();
    const parsedDate = moment(utcObj).local().format('MM-DD-YY  hh:mm:ss A');
    return <>{parsedDate}</>;
  } else return '-';
};

const handleAllowPositiveNumbers = (event) => {
  if (
    [46, 8, 9, 27, 13, 110, 190].includes(event.keyCode) ||
    // Allow: Ctrl+A
    (event.keyCode === 65 && event.ctrlKey === true) ||
    // Allow: Ctrl+C
    (event.keyCode === 67 && event.ctrlKey === true) ||
    // Allow: Ctrl+X
    (event.keyCode === 88 && event.ctrlKey === true) ||
    // Allow: home, end, left, right
    (event.keyCode >= 35 && event.keyCode <= 40)
  ) {
    // Let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress if not
  if ((event.shiftKey || event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
    event.preventDefault();
  }
};
export { FormattedDate, handleAllowPositiveNumbers };
