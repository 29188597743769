import React, {useState} from "react";
import {Window} from '@progress/kendo-react-dialogs';
import './AddToQueueModal.scss';
import {uploadToS3} from "../../../../services/scanOnDemandService";
import '../../../../css/fsa-design-system.css';

import DropZone from "../../../DropZone/Dropzone";

export default function AddToQueueModal(
  {
    popupOpen,
    closePopup,
    uploadedFiles,
    setUploadedFiles,
    id,
    setId,
    retrieveWorkQueue,
    setIsLoading
  }) {
  const [zoom, setZoom] = useState(1.0);
  const [filesToSave, setFilesToSave] = useState([]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const closePopupActions = () => {
    closePopup();
    setZoom(1.0);
  };

  const cancelUpload = () => {
    closePopupActions();
  }

  const uploadAndClose = () => {
    setSaveInProgress(true);
    let savedFileList = [];
    let files = filesToSave;
    let count = 1;
    filesToSave.map((dataItem) => {
      uploadToS3(dataItem.file).then((response) => {
        savedFileList.push(response.data);
        files = files.filter(file => dataItem.id !== file.id);
      }).then(() => {
        if (filesToSave.length === count++) {
          retrieveWorkQueue()
          setSaveInProgress(false);
          setFilesToSave(files);
          closePopupActions();
        }
      })
    });

    if (filesToSave.length === 0) {
      setSaveInProgress(false);
      closePopupActions();
    }
  }

  return (
    popupOpen && (
      <Window
        className="add-objects-window glbModalHeader"
        modal={true}
        draggable={false}
        minimizeButton={() => null}
        maximizeButton={() => null}
        title={"Add Objects to Queue"}
        initialHeight={650}
        initialWidth={1000}
        onClose={closePopupActions}
      >
        <div>
          <DropZone
            filesToSave={filesToSave}
            setFilesToSave={setFilesToSave}
            cancelUpload={cancelUpload}
            uploadAndClose={uploadAndClose}
            id={id}
            setId={setId}
            saveInProgress={saveInProgress}
          />
        </div>
      </Window>
    )
  );


}