import React from 'react';

export default function HoldEditSuccessGrowl({isHoldEditSuccessGrowlHidden, setIsHoldEditSuccessGrowlHidden}) {

  return (
    <div className="fsa-growl-container">
      <div className="fsa-growl fsa-growl--success" id="" aria-hidden={isHoldEditSuccessGrowlHidden} tabIndex="0" role="dialog">
          <div className="fsa-growl__hd"></div>
          <div className="fsa-growl__bd">
            <b><p>Success, Hold Edited!</p></b>
          </div>
      </div>
    </div>
  );
}

