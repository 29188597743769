import axios from 'axios';

export async function getSystemConfigs() {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/systemconfiguration';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function createSystemConfig(data) {
  let createResponse = [];
  try {
    const url = '/buow_api/systemconfiguration';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateSystemConfig(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/systemconfiguration/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteSystemConfig(data) {
  let deleteResponse = [];
  try {
    const url = `/buow_api/systemconfiguration/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchSystemConfig(data) {
  let workQueueFilterResponse = [];
  try {
    let url = '/buow_api/systemconfiguration/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function sortSearchData(data) {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/systemconfiguration/sort/' + data.column + '/' + data.order;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    workQueueFilterResponse.error = ex.response.data;
  }
  return workQueueFilterResponse;
}
