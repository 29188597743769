import React, { useState } from 'react';
import styles from './RecordsManagement.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { objectMetadataRecordsManagement } from '../ObjectTypeData';

const RecordsManagement = () => {
  const [sectionOpen, setSectionOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [loadingFileCodes, setLoadingFileCodes] = useState(true);
  const [objFileCodePopupOpen, setObjFileCodePopupOpen] = useState(false);

  const toggleUpdateObjFileCodePopup = (popupState) => {
    setLoadingFileCodes(true);
    setObjFileCodePopupOpen(popupState);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const UpdateDisposition = () => {
    console.log(startDate, 'updatedispositiondate');
  };

  const closeSection = () => {
    if (!sectionOpen) {
      setSectionOpen(true);
    } else {
      setSectionOpen(false);
    }
  };

  return (
    <div>
      <div className={styles.detailSection}>
        <div className={styles.detailSectionTitle}>
          <div>Filecode information</div>
          <div>
            <button onClick={() => toggleUpdateObjFileCodePopup(true)} className="fsa-btn clear-btn">
              Update Filecode
            </button>
            <span>
              {sectionOpen ? (
                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
              )}
            </span>
          </div>
        </div>
        <div className={styles.detailSectionContent}>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>File Code Name: </span>
          </div>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>
              File Code id: {objectMetadataRecordsManagement.record_management ? objectMetadataRecordsManagement.record_management.file_code : ''}
            </span>
          </div>
        </div>
      </div>
      {/* <UpdateObjectFileCodeModal
        setObjectMetadata={setObjectMetadata}
        objectMetadata={objectMetadata}
        objectID={objectID}
        windowHeight={window.innerHeight}
        windowWidth={window.innerWidth}
        loadingFileCodes={loadingFileCodes}
        setLoadingFileCodes={setLoadingFileCodes}
        objFileCodePopupOpen={objFileCodePopupOpen}
        setRecords={setRecords}
        records={records}
        toggleUpdateObjFileCodePopup={toggleUpdateObjFileCodePopup}
        ids={[objectMetadata._id]}
      ></UpdateObjectFileCodeModal> */}

      <div className={styles.detailSection}>
        <div className={styles.detailSectionTitle}>
          <div>Hold information</div>
          <div>
            <button className="fsa-btn clear-btn">Update Hold</button>
            <span>
              {sectionOpen ? (
                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
              )}
            </span>
          </div>
        </div>
        <div className={styles.detailSectionContent}>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>Hold Name: {objectMetadataRecordsManagement.holds ? objectMetadataRecordsManagement.holds.hold_number : ''}</span>
          </div>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>Type of Hold:</span>
            <br></br>
          </div>
        </div>
      </div>
      {/* Disposition Information Start */}
      <div className={styles.detailSection}>
        <div className={styles.detailSectionTitle}>
          <div>Disposition information</div>
          <div>
            <button className="fsa-btn clear-btn" onClick={UpdateDisposition}>
              Update Disposition
            </button>
            <span>
              {sectionOpen ? (
                <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
              )}
            </span>
          </div>
        </div>
        <div className={styles.detailSectionContent}>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>Cutoff Date:</span>
            {/* <DatePicker className="disposition-datepicker" onChange={handleStartDateChange} selected={startDate} /> */}

            <input type="date" value={startDate} format="MM/dd/yyyy" name={'end_' + startDate} onChange={handleStartDateChange} />
          </div>
          <div className={styles.metaItem}>
            <span className={styles.metaName}>Disposition Date:</span>
            <span>{startDate}</span>
          </div>
        </div>
        <div className={styles.recordsManagementInnerRowBtn}>
          <button
            // onClick={onSaveButtonClick}
            className="fsa-btn"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
      {/* Disposition Information End */}
    </div>
  );
};

export default RecordsManagement;
