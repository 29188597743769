import React from 'react';

export default function WarningGrowl({isWarningGrowlHidden, setIsWarningGrowlHidden}) {

    const closeGrowl = () => {
        setIsWarningGrowlHidden(true);
    }

    return (
        <div className="fsa-growl-container">
            <div className="fsa-growl fsa-growl--error" id="UNIQUE-ID-832353AD65DB511B" aria-hidden={isWarningGrowlHidden} tabIndex="0" role="dialog">
                <div className="fsa-growl__hd">
                </div>
                <div className="fsa-growl__bd">
                <b><p>Warning, selecting a new doctype will clear out your metadata paramaters.</p></b>
                </div>
            </div>
        </div>
    );
}

