import React, { useContext, useEffect, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { getReferenceValues, addNewTrigger, addRecordSchedule, addScheduleStep } from '../../services/recordsManagementService';
import eventComponentTypes from '../../json/eventComponentTypes';
import './FilePlan.scss';
import Multiselect from 'react-select';
import UserContext from '../UserContext/UserContext';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';

const FilePlan = () => {
  const dropDownStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : '#000063',
    }),
    input: (_, { selectProps: { width } }) => ({
      width: width,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };

  const defaultDropdownValue = '-Select Item-';
  const MIN_NAMING_SIZE = 1;
  const [formValid, setFormValid] = useState(true);
  const [message, setMessage] = useState('');
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isCheckTimeDuration, setIsCheckTimeDuration] = useState(false);

  const [state, setState] = useState({
    triggerList: [],
    triggerSelection: '',
    resetTrigger: '',
    timePeriodSelection: '',
    resetTime: '',
    dispositionSelection: '',
    resetDispo: '',
    timeQtyList: [],
    timePeriodList: [],
    dispositionList: [],
    eventComponentList: [],
    recordScheduleList: [],
    recordScheduleName: '',
    scheduleStepName: '',
    eventName: '',
    recordScheduleDisabled: false,
  });
  const [scheduleSteps, setScheduleSteps] = useState([]);
  const { userPermissions } = useContext(UserContext);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    checkRecordScheduleSelection();
  }, [state.dispositionSelection, state.scheduleStepName, state.timePeriodSelection, state.timeQtySelection, state.triggerSelection, state.recordScheduleName]);
  useEffect(() => {
    refreshDdlsFromAPI();
    setState({
      ...state,
      eventComponentList: eventComponentTypes.filter((item) => item.ecTypeName !== 'timePeriod' && item.ecTypeName !== 'disposition'),
    });
    clearSelections();
  }, []);

  const refreshDdlsFromAPI = () => {
    getReferenceValues()
      .then((response) => {
        let tempTriggerList = [];
        let tempTimePeriodList = [];
        let tempDispositionList = [];
        response.data.forEach(function (item) {
          switch (item.columnKey) {
            case 'calendarunit':
              item.columnValues.forEach(function (name) {
                tempTimePeriodList.push({
                  eventComponentName: name,
                  eventComponentType: 'timePeriod',
                  eventComponentId: Date.now().toString(36) + Math.random().toString(36).substring(2),
                });
              });
              break;
            case 'event_name':
              item.columnValues.forEach(function (name) {
                tempTriggerList.push({
                  eventComponentName: name,
                  eventComponentType: 'trigger',
                  eventComponentId: Date.now().toString(36) + Math.random().toString(36).substring(2),
                });
              });
              break;
            case 'disposition':
              item.columnValues.forEach(function (name) {
                tempDispositionList.push({
                  eventComponentName: name,
                  eventComponentType: 'disposition',
                  eventComponentId: Date.now().toString(36) + Math.random().toString(36).substring(2),
                });
              });
              break;
            default:
              break;
          }
        });

        let formatTriggerList =
          tempTriggerList.length > 0 &&
          tempTriggerList.map((item) => {
            return {
              value: item.eventComponentName,
              label: item.eventComponentName,
            };
          });
        let formatTimeList =
          tempTimePeriodList.length > 0 &&
          tempTimePeriodList.map((item) => {
            return {
              value: item.eventComponentName,
              label: item.eventComponentName,
            };
          });
        let formatDispoList =
          tempDispositionList.length > 0 &&
          tempDispositionList.map((item) => {
            return {
              value: item.eventComponentName,
              label: item.eventComponentName,
            };
          });
        setState({
          ...state,
          triggerList: formatTriggerList,
          timePeriodList: formatTimeList,
          dispositionList: formatDispoList,
        });
      })
      .catch((error) => {
        console.log('Error loading file code table: ', error);
      });
  };
  const recordScheduleNameChange = (e) => {
    setState({ ...state, recordScheduleName: e.target.value.trimStart() });
  };
  const scheduleStepNameChange = (e) => {
    setState({
      ...state,
      scheduleStepName: e.target.value.trimStart(),
    });
  };

  const triggerDropListChange = (option) => {
    setState({
      ...state,
      triggerSelection: option.value,
      resetTrigger: option,
    });
  };
  const invalidChars = ['e', 'E', '+', '-', '.'];
  const timeQtyDropListChange = (e) => {
    if (e.target.value > 0 || e.target.value === '') {
      setIsCheckTimeDuration(false);
      setState({
        ...state,
        timeQtySelection: e.target.value,
      });
    } else {
      state.timeQtySelection = '';
      setIsCheckTimeDuration(true);
    }
  };
  const timePeriodDropListChange = (option) => {
    setState({
      ...state,
      timePeriodSelection: option.value,
      resetTime: option,
    });
  };
  const DispositionDropListChange = (option) => {
    setState({
      ...state,
      dispositionSelection: option.value,
      resetDispo: option,
    });
  };
  const EventNameChange = (e) => {
    setState({
      ...state,
      eventName: e.target.value.trimStart(),
    });
    checkRecordScheduleSelection();
  };
  const buildScheduleStep = (e) => {
    let today = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    if (typeof state.triggerSelection != 'undefined' && typeof state.timePeriodSelection != 'undefined' && typeof state.dispositionSelection != 'undefined') {
      const scheduleStep = {
        scheduleStepName: state.scheduleStepName || '',
        eventName: state.triggerSelection || '',
        eventLength: state.timeQtySelection || '',
        calendarunit: state.timePeriodSelection || '',
        disposition: state.dispositionSelection || '',
        eventStartdate: today,
        eventEnddate: null,
        active: true,
      };
      return scheduleStep;
    }
    return null;
  };
  const checkRecordScheduleSelection = () => {
    const scheduleStep = buildScheduleStep();
    const expectedEmptyKeys = ['eventEnddate'];

    if (scheduleStep == null) {
      setFormValid(true);
      return;
    }

    for (var key of Object.keys(scheduleStep)) {
      if (!expectedEmptyKeys.includes(key)) {
        if (scheduleStep[key].length < 1 || scheduleStep[key] === defaultDropdownValue) {
          setFormValid(true);
          return;
        }
      }
    }
    if (state.recordScheduleName.length < MIN_NAMING_SIZE || scheduleStep.scheduleStepName.length < MIN_NAMING_SIZE) {
      setFormValid(true);
      return;
    }

    setFormValid(false);
  };
  const addToEventComponent = (e) => {
    // let today = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    const scheduleStep = buildScheduleStep();
    // Add it to a div
    if (scheduleStep) {
      setScheduleSteps([...scheduleSteps, scheduleStep]);
    }

    clearSelections();
  };
  const saveRecordSchedule = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      let list = scheduleSteps;
      let count = 1;

      let recordSchedule = {
        scheduleName: state.recordScheduleName,
        dispositionInstructions: '',
        active: true,
        scheduleScheduleSteps: [],
      };
      // Promise.all(
      list.map((step, idx) => {
        addScheduleStep(JSON.stringify(step)).then((response) => {
          if (response) {
            recordSchedule.scheduleScheduleSteps.push({
              scheduleSchedulestepsUuid: response.data.schedulestepsUuid,
              scheduleStep: response.data,
              stepOrder: count,
              active: true,
            });
            count++;

            if (idx === list.length - 1) {
              setTimeout(() => {
                addRecordSchedule(recordSchedule).then((recordScheduleResponse) => {
                  if (recordScheduleResponse) {
                    setMessage('New Retention Instruction created successfully');
                    setIsSuccessGrowlHidden(false);

                    setTimeout(() => {
                      setIsSuccessGrowlHidden(true);
                      setShowLoader(false);
                    }, 5000);
                  } else {
                    setMessage('This record already exists.');
                    setIsErrorGrowlHidden(false);
                  }
                });
              }, 2000);
            }
          } else {
            setMessage('This record already exists.');
            setIsErrorGrowlHidden(false);
          }
        });
      });
      // );
    } catch (ex) {
      console.log('saveRecordSchedule error: ', ex.message);
    }
    setTimeout(() => {
      setIsErrorGrowlHidden(true);
      setShowLoader(false);
      // setMessage('');
    }, 5000);
    clearRecordScheduleDivs();
  };

  const clearRecordScheduleDivs = (e) => {
    setState({
      ...state,
      recordScheduleDisabled: false,
      recordScheduleName: '',
      scheduleStepName: '',
      eventName: '',
      resetTrigger: '',
      resetTime: '',
      resetDispo: '',
      triggerSelection: '',
      timeQtySelection: '',
      timePeriodSelection: '',
      dispositionSelection: '',
    });
    setScheduleSteps([]);
    setFormValid(true);
  };
  const clearSelections = () => {
    setState({
      ...state,
      scheduleStepName: '',
      eventName: '',
      resetTrigger: '',
      resetTime: '',
      resetDispo: '',
      triggerSelection: '',
      timeQtySelection: '',
      timePeriodSelection: '',
      dispositionSelection: '',
    });
    setFormValid(true);
  };
  // User is saving a new value for one of the drop down lists
  const onSave = (e) => {
    setShowLoader(true);
    e.preventDefault();
    let name = state.eventName;

    addNewTrigger([name])
      .then(() => {})
      .finally(function () {
        setTimeout(() => {
          setState((prevData) => {
            return { ...prevData, eventName: '' };
          });
        }, 1000);
        refreshDdlsFromAPI();
        setShowLoader(false);
      });
  };

  return (
    <main className="content" tabIndex="-1">
      {showLoader && <GlobalLoader />}
      <div className="content">
        <div className="pageContent">
          <h1 className="HeaderOne-StyleOne">Create Retention Instruction</h1>
          <div className="create-con">
            <div className="create-form">
              <div className="create-dd">
                <div className="flex-item">
                  <label className="fsa-field__label" htmlFor="recSchedName">
                    Retention Instruction Name
                  </label>
                  <Input
                    id="recSchedName"
                    disabled={state.recordScheduleDisabled}
                    value={state.recordScheduleName}
                    onChange={recordScheduleNameChange}
                    className="fsa-input w-100"
                    minLength={MIN_NAMING_SIZE}
                  />
                </div>
              </div>
              <div className="create-dd">
                <div className="flex-item">
                  <label className="fsa-field__label" htmlFor="scheduleStepName">
                    Retention Instruction Step Name
                  </label>
                  <Input id="scheduleStepName" value={state.scheduleStepName} onChange={scheduleStepNameChange} className="fsa-input w-100" minLength={MIN_NAMING_SIZE} />
                </div>
              </div>
              <div className="create-dd mb-2">
                <div className="flex-item">
                  <label className="fsa-field__label" htmlFor="triggerList">
                    Trigger
                  </label>
                  {state.triggerList ? (
                    <Multiselect
                      options={state.triggerList}
                      value={state.resetTrigger}
                      onChange={triggerDropListChange}
                      styles={dropDownStyle}
                      aria-labelledby="triggerList"
                      id="triggerList"
                      aria-describedby="triggerList"
                      aria-required="true"
                      name="triggerList"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : null}
                </div>
                <div className="flex-item timeDurationField">
                  <label className="fsa-field__label" htmlFor="timeQty">
                    Time Duration
                  </label>
                  <Input
                    id="timeQty"
                    type="number"
                    placeholder="e.g. 1, 2, 3, etc."
                    className="fsa-input"
                    pattern="^(0|[1-9][0-9]*)$"
                    value={state.timeQtySelection}
                    onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()}
                    onChange={timeQtyDropListChange}
                  />
                  {isCheckTimeDuration && <div className="validationMessage">Time Duration should be greater than 0.</div>}
                </div>
                <div className="flex-item">
                  <label className="fsa-field__label" htmlFor="timePeriodList">
                    Time Period
                  </label>
                  {state.timePeriodList ? (
                    <Multiselect
                      options={state.timePeriodList}
                      value={state.resetTime}
                      onChange={timePeriodDropListChange}
                      styles={dropDownStyle}
                      aria-labelledby="timePeriodList"
                      id="timePeriodList"
                      aria-describedby="timePeriodList"
                      aria-required="true"
                      name="timePeriodList"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : null}
                </div>
                <div className="flex-item">
                  <label className="fsa-field__label" htmlFor="DispoList">
                    Disposition
                  </label>
                  {state.dispositionList ? (
                    <Multiselect
                      options={state.dispositionList}
                      value={state.resetDispo}
                      onChange={DispositionDropListChange}
                      styles={dropDownStyle}
                      aria-labelledby="DispoList"
                      id="DispoList"
                      aria-describedby="DispoList"
                      aria-required="true"
                      name="DispoList"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : null}
                </div>
                {/* <div className="flex-item">
                  <label className="fsa-field__label">
                    Cut-Off Date
                  </label>

                  <FormDatepicker />
                </div> */}
                <div className="comp-evnt">
                  <button onClick={addToEventComponent} disabled={formValid} className="fsa-btn flex flexrow">
                    + add
                  </button>
                </div>
              </div>
              <hr />
              <div className="rec-sched-con">
                {scheduleSteps && scheduleSteps.length > 0 ? <div> Schedule Steps for a Record Schedule '{state.recordScheduleName}'</div> : <></>}
                <div>
                  {scheduleSteps &&
                    scheduleSteps.map((userParams, index) => (
                      <div className="fPlanDivs" key={index}>
                        <div>{userParams.scheduleStepName}</div>
                        <div>{userParams.eventName}</div>
                        <div>{userParams.eventLength}</div>
                        <div>{userParams.calendarunit}</div>
                        <div>{userParams.disposition}</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="flexrow rs-btns">
                <div className="flex flexrow">
                  <button onClick={clearRecordScheduleDivs} className="fsa-btn">
                    Clear
                  </button>
                </div>

                {userPermissions['Create_Retention_Instruction'] === true && (
                  <div className="flex flexrow">
                    <button onClick={saveRecordSchedule} disabled={state.recordScheduleName && scheduleSteps && scheduleSteps.length > 0 ? false : true} className="fsa-btn">
                      Save Retention Instruction
                    </button>
                  </div>
                )}
              </div>
            </div>

            {userPermissions['Create_Retention_Event_Trigger'] === true && (
              <div className="flex aside-form">
                <div className="">
                  <div className="h3-div">Add New Retention Event Component</div>
                  <div className="flexrow">
                    <div className="comp-evnt">
                      <label className="fsa-field__label" htmlFor="event">
                        Event Trigger
                      </label>
                      <Input id="event" value={state.eventName} onChange={EventNameChange} className="fsa-input" />
                    </div>
                    <button onClick={onSave} disabled={!state.eventName || showLoader} className="fsa-btn flex flexrow">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SuccessProcessGrowl setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} isSuccessGrowlHidden={isSuccessGrowlHidden} message={message} />
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
    </main>
  );
};

export default FilePlan;
