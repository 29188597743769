import React, { useState, useEffect } from 'react';
import UserContext from '../UserContext/UserContext';
import './Dashboard.scss';
import { NavLink } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { oktaLogin } from '../../services/inBasketService';

const DashboardSMC = () => {
  const [userName, setUserName] = useState();
  const [userAgencyCode, setUserAgencyCode] = useState();
  const [userAddress, setUserAddress] = useState();
  const loginUserName = sessionStorage.getItem('userName');
  const { authState } = useOktaAuth();

  const { userInfo, setUserInfo } = React.useContext(UserContext);

  // console.log(authState?.accessToken?.accessToken, '222');
  useEffect(() => {
    if (userInfo.usdaagencycode) {
      let agency_name = '';
      switch (userInfo.usdaagencycode[0]) {
        case 'FA':
        case 'CE':
          agency_name = 'FSA';
          break;
        case '16':
          agency_name = 'NRCS';
          break;
        case '08':
          agency_name = 'RMA';
          break;
        case '12':
        case '99':
          agency_name = 'FBC';
          break;
        default:
          agency_name = 'N/A';
      }
      setUserName(userInfo.name);
      setUserAgencyCode(agency_name);
      setUserAddress(userInfo.address);
    }
    async function fetchData() {
      let userTOken = sessionStorage.getItem('userToken');
      if (authState?.accessToken?.accessToken && authState?.accessToken?.accessToken !== undefined && !userTOken) {
        let userData = await oktaLogin(authState.accessToken.accessToken);
        sessionStorage.setItem('user_information', JSON.stringify(userData.data));
        sessionStorage.setItem('userToken', userData.data.jwt);

        sessionStorage.setItem('userName', userData.data.name);
        sessionStorage.setItem('isUserAuthenticated', true);
        setUserInfo({ name: userData.data.name, usdaagencycode: [''] });
      }
    }
    fetchData();
  }, [userName, userAgencyCode, userAddress, userInfo]);

  return (
    <main className="content" tabIndex="-1">
      <div className="dashboardContent">
        <div className="welcome sidebar">
          <h1 className="page-title">Welcome</h1>
          <div className="authHeaders">
            <hr />
            <div>
              <p>{loginUserName}</p>
            </div>
            <hr />
            <div>
              <p>Organization: BRILLIENT USA</p>
            </div>
            <hr />
          </div>
        </div>

        <div className="flexrow main-section">
          <div className="guide">
            <div className="guide-h2">Suspense Queue</div>
            <ol className="num-list">
              <li> Upload record objects</li>
              <li> Review your submission</li>
              <li> Submit for record storage</li>
            </ol>
            <div className="fsa-divider fsa-divider--strong"></div>
            <div className="guide-h4">Getting Started</div>
            <p className="ds-home-features__blurb">Submit uploads for processing and storage. Preview and review submission responses.</p>
            <div className="fsa-btn">
              <NavLink to="/suspenseQueue" className="fsa-nav-global__link" style={{ color: 'white' }}>
                <span>View your Queue</span>
              </NavLink>
            </div>
          </div>

          <div className="guide">
            <div className="guide-h2">Search</div>
            <ol className="num-list">
              <li>Search any metadata field</li>
              <li>Preview and manage results</li>
              <li>Save recent search requests</li>
            </ol>
            <div className="fsa-divider fsa-divider--strong"></div>
            <div className="guide-h4">Getting Started</div>
            <p className="ds-home-features__blurb">Select any metadata field in the system to provide a search value for your results</p>
            <div className="fsa-btn">
              <NavLink to="/Search" className="fsa-nav-global__link" style={{ color: 'white' }}>
                <span>Start Searching</span>
              </NavLink>
            </div>
          </div>

          <div className="guide">
            <div className="guide-h2">File Plan</div>
            <ol className="num-list">
              <li>Create Retention Instructions</li>
              <li>Create Record Schedules</li>
              <li>Manage File Plans</li>
            </ol>
            <div className="fsa-divider fsa-divider--strong"></div>
            <div className="guide-h4">Getting Started</div>
            <p className="ds-home-features__blurb">Add record schedule components to create reusable schedules. Manage your file plan</p>
            <div className="fsa-btn">
              <NavLink to="/fileplan" className="fsa-nav-global__link" style={{ color: 'white' }}>
                <span>Manage File Plans</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardSMC;
