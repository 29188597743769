import { orderBy } from '@progress/kendo-data-query';
import React, { useState, useEffect } from 'react';
import calculateSize from 'calculate-size';
import styles from './SearchTable.module.scss';
import searchImg from '../../fsa-style/img/searchImg.svg';
import { Grid, GridColumn as Column, GridNoRecords, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';

const headerStyle = (column) => {
  return (
    <div className={styles.tableHeaderStyle}>
      <div className={styles.tableHeaderTitle} style={{ cursor: 'default' }}>
        {column.title}
      </div>
    </div>
  );
};

export const calculateWidth = (data, field, columnName) => {
  let maxWidth = 0;
  const size = calculateSize(columnName.replaceAll('_', ' '), {
    font: 'Arial',
    fontSize: '26px',
  });
  maxWidth = size.width;
  data.forEach((item) => {
    const contentSize = calculateSize(item[field], {
      font: 'Arial',
      fontSize: '24px',
    });
    if (contentSize.width > maxWidth) {
      maxWidth = contentSize.width;
      if (maxWidth > 500) maxWidth = 500;
    }
  });
  return maxWidth;
};

export default function SearchTable(props) {
  const { data = [], fields, total = data.length, page, pageSize, onPageChange } = props;
  const [sort, setSort] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Filter data based on the search keyword
  // const filteredData = data.filter((item) => {
  //   const buowNameLowerCase = item.buowName.toLowerCase();
  //   const searchKeywordLowerCase = props.searchKeyword.toLowerCase();
  //   return buowNameLowerCase.includes(searchKeywordLowerCase);
  // });

  useEffect(() => {
    fields.forEach((column) => {
      return (column.sortType = ''), { ...column }; //to remove ASC/DESC arrow
    });
  }, []);

  const onSelectSort = (column) => {
    return (
      <div className={column.showMenuIcon ? styles.tableHeaderStyle : styles.disabledHeaderStyle} onClick={() => onSortData(column)}>
        <div className={styles.tableHeaderTitle}>
          <div>{column.title}</div>
          {column.showMenuIcon && <div className="header-sort-icon">{column.sortType === '' ? '' : column.sortType === 'ASC' ? '⬆' : '⬇'}</div>}
        </div>
      </div>
    );
  };
  const onSortData = (column) => {
    fields.forEach((obj) => {
      if (obj.field === column.field) {
        if (obj.sortType === '') {
          obj.sortType = 'ASC';
          props.onSortData(column);
        } else if (obj.sortType === 'ASC') {
          obj.sortType = 'DESC';
          props.onSortData(column);
        } else {
          obj.sortType = '';
          props.onRemoveSort();
        }
      } else {
        obj.sortType = '';
      }
    });
    // onSelectSort(column);
  };

  useEffect(() => {
    if (page === 0) {
      const initialData = data.slice(0, pageSize).map((obj, idx) => ({
        ...obj,
        checked: false,
        searchImg: searchImg,
      }));
      setTableData(initialData);
    } else {
      const initialData = data.slice(pageSize * page, pageSize + pageSize * page).map((obj, idx) => ({
        ...obj,
        checked: false,
        searchImg: searchImg,
      }));
      setTableData(initialData);
    }
  }, [data, page, pageSize]);

  const headerStyleWithCheckbox = (column) => {
    return (
      <div className={styles.tableHeaderWithCheckbox}>
        <input className={styles.tableCheckboxInput} type="checkbox" checked={selectAllChecked} onChange={handleSelectAllChange} />
        <span className={styles.tableCheckboxTitle} onClick={column.onClick}>
          {column.title}
        </span>
      </div>
    );
  };

  const handleCheckboxChange = (event, dataItem) => {
    const updatedData = tableData.map((item) => (item.id === dataItem.id ? { ...item, checked: event.target.checked } : item));
    if (props.setSelectedRows) {
      props.setSelectedRows(updatedData.filter((item) => item.checked));
    }
    setTableData(updatedData);

    const allChecked = updatedData.every((item) => item.checked);
    setSelectAllChecked(allChecked);

    if (event.target.checked === true) {
      const row = [dataItem];
      props.setRowsChecked(row);
    } else {
      props.setRowsChecked();
    }
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    const updatedData = tableData.map((item) => ({
      ...item,
      checked: checked,
    }));
    if (props.setSelectedRows) {
      props.setSelectedRows(checked ? updatedData : []);
    }
    setTableData(updatedData);
    setSelectAllChecked(checked);
    if (event.target.checked === true) {
      const row = [{}];
      props.setRowsChecked(row);
    } else {
      props.setRowsChecked();
    }
  };

  const ColumnMenuCheckboxFilter = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
  };

  return (
    <>
      <Grid
        {...props}
        total={total}
        data={orderBy(tableData, sort)}
        page={page}
        pageSize={pageSize}
        skip={page * pageSize}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 15, 20, 25],
          previousNext: true,
        }}
        onPageChange={onPageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridNoRecords>No results</GridNoRecords>

        <Column
          headerCell={headerStyleWithCheckbox}
          field="selected"
          cell={({ dataItem }) => (
            <td className={styles.checkboxOuterWrapper}>
              <input type="checkbox" checked={dataItem.checked} onChange={(e) => handleCheckboxChange(e, dataItem)} className={styles.tableCheckboxInput} />
            </td>
          )}
          title="Select" // Add the title here
          width={90} // Adjust the width if needed
          filterable={false}
        />

        {data &&
          fields.map((column, index) => {
            // if (column.field !== 'logo') {
            return (
              <Column
                // headerCell={headerStyle}
                headerCell={() => onSelectSort(column)}
                field={column.field}
                title={column.title}
                key={index}
                cell={column.cell}
                width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
                // width={160}
                filter={column.filter}
                columnMenu={column.showMenuIcon ? ColumnMenuCheckboxFilter : undefined}
              />
            );
            // }

            // else {
            //   return (
            //     <Column
            //       headerCell={headerStyle}
            //       // headerCell={() => onSelectSort(column)}
            //       field="logo"
            //       cell={({ dataItem }) => (
            //         <td className="pb-0 pt-0">
            //           <img src={`data:image/jpeg;base64,${dataItem.logo}`} style={{ cursor: 'pointer', height: 50, width: 50, objectFit: 'contain' }} alt="rec_logo"></img>
            //         </td>
            //       )}
            //       title="Logo"
            //       width={100}
            //       filterable={false}
            //     />
            //   );
            // }
          })}
        <Column
          headerCell={headerStyle}
          field="edit"
          cell={({ dataItem }) => (
            <td>
              <img src={dataItem.searchImg} style={{ cursor: 'pointer' }} onClick={() => props.viewMoreFunc(dataItem)} alt={'searchImg'}></img>
            </td>
          )}
          title=""
          width={100}
          filterable={false}
        />
      </Grid>
    </>
  );
}
