// ValidatedInput.js

import React from 'react';
import { Input } from '@progress/kendo-react-inputs';

const ValidatedInput = (props) => {
  const { valid, value, onChange, name, ...rest } = props;

  const isRequiredField = name === 'organization' || name === 'transferName' || name === 'description';

  return (
    <div>
      <Input {...rest} value={isRequiredField ? (value !== undefined && value !== '' ? value.trimStart() : value) : value === ' ' ? value.trimStart() : value} onChange={onChange} />
      {(!valid || value === ' ') && <div className="validation-message">This field is required *</div>}
    </div>
  );
};

export default ValidatedInput;
