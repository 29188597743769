import EditObjectTransferFields from '../../json/EditObjectTransferFields.json';
import { useContext, useEffect, useState } from 'react';
import Table from '../Table/SelectableTable';
import axios from 'axios';
// import { EventEmitter } from '../../services/events';
import UserContext from '../UserContext/UserContext';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
export default function ObjectsInTransfer(props) {
  // const { EventEmitter } = require('../../services/events');

  const { onClose, transferObject } = props;
  const [objectsInTransfers, setObjectsInTransfers] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    const url = `/doc_and_metadata_submission_api/submission/search?page=${page}&limit=${pageSize}`;
    const searchData = {
      searchCriteria: [
        {
          condition: 'EQUALS',
          field1: {
            name: 'doc_repo.is_latest',
            value: [true],
          },
        },
        {
          condition: 'CONTAINS',
          field1: {
            name: 'transfers.transfer_number',
            value: [transferObject?.transferNumber],
          },
        },
      ],
      sort: [],
      aggregation: [],
    };

    const config = {
      // TODO replace static officeId with officeId from userInfo
      headers: {
        agencyCode: userInfo.usdaagencycode[0],
        eauthid: userInfo.usdaeauthid,
        // officeId: userInfo.usdaworkofficeid[0] || null,
        username: userInfo.name,
      },
    };

    axios
      .post(url, searchData, config)
      .then((result) => {
        setObjectsInTransfers(result.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page, pageSize]);

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    const newPage = page.skip / page.take;
    setPage(newPage);
  };

  const removeObjectsFromTransfer = (objectsInTransferToRemove) => {
    const data = objectsInTransferToRemove.map((o) => o._id);
    const url = `/recordsmanagementapi/records-management/transfers/remove?transferNumber=${transferObject?.transferNumber}&isRelease=true`;
    setLoading(true);
    axios
      .put(url, data, {
        headers: {
          userName: userInfo.name || '',
          usdaeauthid: userInfo.usdaeauthid || '',
        },
      })
      .then(() => {
        setMessage('Objects Removed from transfer Successfully');
        setIsSuccessGrowlHidden(false);
        // EventEmitter.emit('refreshTransferGrid');

        setTimeout(() => {
          onClose();
          setIsSuccessGrowlHidden(true);
          setLoading(false);
        }, 3000);
      })
      .catch((err) => {
        setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          setLoading(false);
        }, 3000);
      });
  };

  return (
    <>
      {loading && <GlobalLoader />}
      <Table
        pageSize={pageSize}
        total={objectsInTransfers.total_hits}
        page={page}
        data={objectsInTransfers.metadata}
        fields={EditObjectTransferFields}
        onCancel={onClose}
        actionText={'Remove Objects From Transfer'}
        onAction={removeObjectsFromTransfer}
        onPageChange={onPageChange}
        dataKeyItem={'_id'}
      />

      <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
    </>
  );
}
