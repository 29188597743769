import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";

export default function SchedulingStatusDropDownList(props) {
  const {value, onChange} = props;
  const {schedulestatus = [], ...rest} = useRecordReferencesContext()

  return (
    <DropDownList
      data={schedulestatus}
      className="fsa-field__item"
      onChange={onChange} value={value}
    />
  )

}