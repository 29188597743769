import axios from 'axios';
export async function createWorkgroupTable(data) {
  let createResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/workgroups';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error:', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateWorkgroup(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/workgroups/' + data.workgroupId + '/';
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error :', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteWorkgroup(data) {
  let deleteResponse = [];
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/workgroups/${data.workgroupId}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchForWorkGroupTable(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/workgroups/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}