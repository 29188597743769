import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";
import { useRecordReferencesContext } from "../../RecordReferences/RecordReferencesProvider";

export default function HoldStatusRadioGroup(props) {
  const {name, value, onChange} = props;
  const {holdstatus = []} = useRecordReferencesContext()
  const holdStatuses = holdstatus.map(s => ({label: s, value: s}))

  return (
    <RadioGroup
      data={holdStatuses}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}