import React, { useState } from 'react';
//import './DateSearchInput.scss'
// import { DatePicker } from "@progress/kendo-react-dateinputs";

export default function DateSearchInput({ userParams, removeParam, handleDateChange, value }) {
  const systemName = userParams.systemName;
  const friendlyName = userParams.friendlyName;
  const [startValue, setStartValue] = useState(value !== '' ? value : '');
  // const [endValue, setEndValue] = useState();

  return (
    <div key={systemName} className="specsDivs1" style={{ width: '60%' }}>
      <div className="date-flexrow" style={{ gap: '40px' }}>
        <div className="flexcol">
          <label>
            {friendlyName} <img style={{ height: 10, marginLeft: '1rem' }} onClick={() => removeParam(systemName)} src="/img/close.png" alt="Remove Parameter" />
          </label>
          {/* <DatePicker
                        value={startValue}
                        format="MM/dd/yyyy"
                        name={"start_" + friendlyName}
                        onChange={(e) => handleDateChange("start_" + systemName, e, setStartValue)}
                    /> */}
          <input
            type="date"
            value={startValue}
            name={'start_' + friendlyName}
            onChange={(e) => handleDateChange(systemName, e, setStartValue)}
          />
        </div>
        {/* <div className="flexcol">
                    <label>
                        {"End " + userParams.friendlyName}{" "}
                        <img
                            style={{ height: 10, marginLeft: "1rem" }}
                            onClick={() => removeParam(systemName)}
                            src="/img/close.png"
                            alt="Remove Parameter"
                        />
                        
                        <div className={'inputRemoveIcon'} onClick={() => removeParam(systemName)}>
                            x
                        </div>
                    </label>
                    <DatePicker
                        value={endValue}
                        format="MM/dd/yyyy"
                        name={"end_" + friendlyName}
                        onChange={(e) => handleDateChange("end_" + systemName, e, setEndValue)}
                    />
                    <input type="date" value={endValue} name={"end_" + friendlyName} onChange={(e) => handleDateChange("end_" + systemName, e, setEndValue)} />
                </div> */}
      </div>
    </div>
  );
}
