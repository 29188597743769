import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './SearchPage.module.scss';
import '../../GlobalStyle.scss';
import SearchTable from './SearchTable';
import SearchPageData from './Search.json';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import SuccessProcessGrowl from '../../components/SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader.js';
import { onSearchFileData, searchPageColumnsAPI } from './SearchServices';
import ErrorGrowl from '../../components/SuspenseQueue/ErrorGrowl';
// import SearchAdminPanelComponent from '../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import CustomSearch from './CustomSearch/CustomSearch';
import Footer from '../../newcomponents/Footer/Footer';
import UpdateFileCodeModal from './SearchResults/UpdateFileCode/UpdateFileCodeModal';
import UpdateHoldModal from './SearchResults/UpdateHold/UpdateHoldModal';
import UpdateTransferModal from './SearchResults/UpdateTransfer/UpdateTransferModal';
import MetaDataModal from './MetaDataModal/MetaDataModal';
import UserContext from '../../components/UserContext/UserContext';
import metaDownArrow from '../../fsa-style/img/svgs/metaDownArrow.svg';

import { pdfjs } from 'react-pdf';
import ObjectMetadata from './ObjectMetadata/ObjectMetadata.js';
import RecordsManagement from './RecordsManagement/RecordsManagement.js';
import AliceSenseTab from './AliceSenseTab/AliceSenseTab';
import LogsTab from './LogsTab/LogsTab';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PreviousIcon from '../../fsa-style/img/svgs/leftPreviousIcon.svg';
import NextIcon from '../../fsa-style/img/svgs/rightNextIcon.svg';
import ZoomInIcon from '../../fsa-style/img/svgs/zoomInIcon.svg';
import ZoomOutIcon from '../../fsa-style/img/svgs/zoomOutIcon.svg';
import DownloadIcon from '../../fsa-style/img/svgs/downloadIcon.svg';
import FullscreenIcon from '../../fsa-style/img/svgs/fullScreenIcon.svg';
import { getDocDetail, search } from '../../services/metadataSubmissionService';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SearchPage = () => {
  const initialData = [
    {
      id: 1,
      fileSeries: 'Employee Files',
      folder: 'Jones, Sally May 00020',
      section: 'Select Section',
      objectType: 'Select ObjectType',
      clipBoardName: 'Performance Review',
      Added_On: '8/25/21',
    },
    {
      id: 2,
      fileSeries: 'Employee Files',
      folder: 'Cooper, Kristin Nguyen',
      section: 'Select Section',
      objectType: 'Select ObjectType',
      clipBoardName: 'Performance Review',
      Added_On: '9/25/21',
    },
    {
      id: 3,
      fileSeries: 'Employee Files',
      folder: 'Nguyen, Shane Black',
      section: 'Select Section',
      objectType: 'Select ObjectType',
      clipBoardName: 'Performance Review',
      Added_On: '5/25/21',
    },
  ];

  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [recordModalShow, setRecordModalShow] = useState(false);
  // const [deleteModalShow, setDeleteModalShow] = useState(false);
  // const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fileSeries: '',
    folder: '',
    section: '',
    objectType: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [searchPageDetails, setSearchPageData] = useState(SearchPageData);
  // const [popupOpen, setPopupOpen] = useState(false);
  const elementRef = useRef();
  const [rowsChecked, setRowsChecked] = useState([]);
  const [showUpdateFileCode, setShowUpdateFileCode] = useState(false);
  const [showUpdateHold, setShowUpdateHold] = useState(false);
  const [showUpdateTransfer, setShowUpdateTransfer] = useState(false);

  const [metadataModalOpen, setMetadataModalOpen] = useState(false);
  const [searchParamsObjArray, setSearchParamsObjArray] = useState([]);
  const [metadataSelection, setMetadataSelection] = useState(null);
  const [inputTitle, setInputTitle] = useState([]);
  const { userInfo } = useContext(UserContext);
  const [searchPageColumns, setSearchPageColumn] = useState([]);
  const [showViewModel, setShowViewModel] = useState(false);

  async function fetchSearchPageData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await onSearchFileData(payload).then((response) => {
      // setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }
  async function fetchSearchColumns() {
    await searchPageColumnsAPI().then(async (response) => {
      // setData(response.data);
      console.log(response.data, 'ooooo');
      let columnNewArray = [];
      await response.data.map((column) => {
        columnNewArray.push({
          metaId: '_id',
          field: column.name,
          title: column.name,
          dataType: 'text',
          showMenuIcon: 'true',
          sortType: '',
          data: column,
        });
        setSearchPageColumn(columnNewArray);
      });
      // setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    fetchSearchColumns();
    fetchSearchData();
  }, []);
  const fetchSearchData = () => {
    // searchMetadata(0, Number(returnData.get("take")), JSON.parse(returnData.get("sort")), paramArray, null, userInfo).then(response => {

    // const metadataSearchObject = createSearchObject(0, Number(returnData.get("take")), JSON.parse(returnData.get("sort")), paramArray, null, userInfo);

    let metadataSearchObject = { searchCriteria: [{ condition: 'EQUALS', field1: { name: 'doc_repo.is_latest', value: [true] } }], sort: [] };
    return search(metadataSearchObject, 0, 25, userInfo).then((result) => {
      if (result.isAxiosError === true) {
        // setIsLoading(false);
        if (result.response.status === 404) {
          // setSearchErrorMessage("No records were found matching your search criteria.");
        } else {
          // setSearchErrorMessage("We're sorry, something went wrong, please try your search again.");
        }
      } else {
        console.log(result, 'tttt');
        setData(result.data.metadata);
        // reorderColumn(searchPageColumns, latestFilter);
        // setIsLoading(false)
      }
    });
  };

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await onSearchFileData(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  // const DeleteDataFunc = (DeleteData) => {
  //   setLoading(true);
  //   const sendData = {
  //     id: DeleteData.id,
  //   };

  //   deleteSearchData(sendData).then(async (response) => {
  //     if (response.data) {
  //       setDeleteModalShow(false);
  //       await fetchSearchPageData();
  //       setLoading(false);
  //       setIsSuccessGrowlHidden(false);
  //       setMessage('Record deleted successfully');
  //       setTimeout(() => {
  //         setIsSuccessGrowlHidden(true);
  //       }, 3000);
  //     } else if (response.error) {
  //       setIsErrorGrowlHidden(false);
  //       setMessage(response.error.message ? response.error.message : 'Something went wrong');

  //       setTimeout(() => {
  //         setIsErrorGrowlHidden(true);
  //       }, 3000);
  //       console.log('error');
  //       setLoading(false);
  //     }
  //   });
  // };

  const viewMoreFunc = (data) => {
    setShowViewModel(true);
    console.log(data, 'data');
    setFormData({
      fileSeries: data.fileSeries,
      folder: data.folder,
      section: data.section,
      objectType: data.objectType,
      data: data,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  // const DeleteFunc = (DeleteData) => {
  //   setDeleteModalShow(true);
  //   setDeleteData(DeleteData);
  // };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
    if (value.length > 2) onSearch(value);
    if (value.length === 0) onSearch(value);
  };

  const onSearch = async (searchText) => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await onSearchFileData(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newSearchPageData = JSON.parse(JSON.stringify(searchPageColumns));
    newSearchPageData.forEach((itm) => {
      itm.sortType = '';
    });
    setSearchPageData(newSearchPageData);
    await fetchNewSearchPageData();
  };

  // Code needs to change
  const fetchNewSearchPageData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await onSearchFileData(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await onSearchFileData(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  // const closePopup = () => {
  //   setPopupOpen(false);
  // };

  // const handleMetaModal = () => {
  //   if (!popupOpen) {
  //     setPopupOpen(true);
  //   } else {
  //     setPopupOpen(false);
  //   }
  // }
  const handleUpdateFileCode = () => {
    setShowUpdateFileCode(true);
  };
  const handleUpdateHold = (value) => {
    setShowUpdateHold(value);
  };
  const handleUpdateTransfer = (value) => {
    setShowUpdateTransfer(value);
  };
  const closeMetadatModal = () => {
    setMetadataModalOpen(false);
  };
  //ON USER Text INPUT
  // Checks to see if there is already a value by metadata name
  // in the search params object
  // If there is, replaces it. If there isn't, adds it.
  const handleChange = (name, event) => {
    event.preventDefault();
    setSearchParamsObjArray((prevSearchParamsObjArray) => {
      return prevSearchParamsObjArray.find((element) => element.key === name) === undefined
        ? prevSearchParamsObjArray.concat([
            {
              key: name,
              friendlyName: event.friendlyName,
              value: event.target.value,
            },
          ])
        : prevSearchParamsObjArray.map((obj) =>
            obj.key === name
              ? {
                  key: name,
                  friendlyName: obj.friendlyName,
                  value: event.target.value,
                }
              : obj
          );
    });
  };
  const handleInputTitleChange = (updatedInputTitle) => {
    console.log('Updated inputTitle in parent component:', updatedInputTitle);
  };
  const handleMetadataModal = () => {
    // ReactDOM.findDOMNode(this.refs['elementRef']).getBoundingClientRect();
    if (!metadataModalOpen) {
      setMetadataModalOpen(true);
    } else {
      setMetadataModalOpen(false);
    }
  };

  return (
    <div className={styles.searchPage}>
      {isLoading && <GlobalLoader />}

      <div className={styles.searchPageContent}>
        <h1 className={styles.pageTitle}>Search</h1>
        <div className="d-flex justify-content-between">
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <CustomSearch
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  placeholder={'Start typing to search via keyword'}
                ></CustomSearch>
              </div>
            </div>
          </div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Folder Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <CustomSearch
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  placeholder={'Start typing to search via keyword'}
                ></CustomSearch>
              </div>
            </div>
          </div>
        </div>
        {/* new */}
        <div className={[styles.underKeywordContainer].join('')}>
          <div className="modal-link-con" ref={elementRef}>
            {/* <p className="modal-link" style={{ cursor: 'pointer', marginRight: '2rem' }} onClick={handleMetaModal}>
              Metadata Search
              <span>
                {
                  popupOpen ?
                    <FontAwesomeIcon onClick={closePopup} className="float-right chevron" icon={faChevronUp} /> :
                    <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                }
              </span>
            </p> */}

            <div>
              <p className="modal-link" onClick={handleMetadataModal} style={{ cursor: 'pointer', marginRight: '2rem' }}>
                Metadata Search
                <span>
                  <img src={metaDownArrow} alt="downArrow" style={{ marginTop: '1rem' }} />
                </span>
              </p>
            </div>

            <p style={{ marginRight: '2rem' }}>
              Search Parameters:
              <b style={{ fontSize: '16px', marginLeft: '5px' }}>None</b>
            </p>
            <div>
              <input className={styles.tableCheckboxInput} type="checkbox" /> <b style={{ fontSize: '16px', marginLeft: '5px' }}>Full Text</b>
            </div>
          </div>
        </div>

        {/* 2nd row  */}
        <div style={{ marginRight: 'auto' }}>
          <span>
            <b>Search Results</b>
          </span>
          {rowsChecked?.length > 0 ? (
            <>
              <a href="#" className={styles.searchResultsLink} onClick={handleUpdateFileCode} title="Click to update File Code(s)">
                Update File Code
              </a>

              <a href="#" className={styles.searchResultsLink} onClick={() => handleUpdateHold(true)} title="Click to update Hold(s)">
                Update Hold
              </a>
              <a href="#" className={styles.searchResultsLink} onClick={() => handleUpdateTransfer(true)} title="Click to update transfer(s)">
                Update Transfer
              </a>
              <a
                href="#"
                className={styles.searchResultsLink}
                // onClick={() => DeleteObjectVisible(true)}
                title="Click to update transfer(s)"
              >
                Delete Object
              </a>
            </>
          ) : null}
        </div>

        <div className={[styles.searchPageTable, 'glbSearchTable'].join(' ')}>
          <SearchTable
            data={data}
            page={page}
            viewMoreFunc={viewMoreFunc}
            // DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={searchPageColumns}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            // setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
            setRowsChecked={setRowsChecked}
          />
        </div>
        {showViewModel && (
          <CreateEditSearchPage
            isNew={isNew}
            data={data}
            show={recordModalShow}
            formData={formData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setIsErrorGrowlHidden={setIsErrorGrowlHidden}
            setMessage={setMessage}
            setFormData={setFormData}
            setRecordModalShow={setRecordModalShow}
            onHide={() => setRecordModalShow(false)}
            isLoading={(e) => setLoading(e)}
            fetchSearchPageData={fetchSearchPageData}
          />
        )}

        {/* <DeleteModal
          show={deleteModalShow}
          DeleteDataFunc={DeleteDataFunc}
          onHide={() => setDeleteModalShow(false)}
          setDeleteModalShow={setDeleteModalShow}
          selectedDeleteRowData={deleteData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
        ></DeleteModal> */}

        <UpdateFileCodeModal show={showUpdateFileCode} setShowUpdateFileCode={setShowUpdateFileCode} onHide={() => setRecordModalShow(false)} />

        <UpdateHoldModal show={showUpdateHold} showUpdateHold={setShowUpdateHold} onHide={() => setRecordModalShow(false)} handleUpdateHold={handleUpdateHold} />

        <UpdateTransferModal
          show={showUpdateTransfer}
          setShowUpdateTransfer={setShowUpdateTransfer}
          onHide={() => setRecordModalShow(false)}
          handleUpdateTransfer={handleUpdateTransfer}
        />

        {/* metadata modal */}
        <MetaDataModal
          metadataModalOpen={metadataModalOpen}
          closeMetadatModal={closeMetadatModal}
          metadataSelection={metadataSelection}
          setMetadataSelection={setMetadataSelection}
          handleChange={handleChange}
          inputTitle={inputTitle}
          onInputTitleChange={handleInputTitleChange}
        />

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      <Footer />
    </div>
  );
};

// START:- Create / Edit record modal with functionality
const CreateEditSearchPage = (props) => {
  const [objectActive, setObjectActive] = useState();
  const [zoom, setZoom] = useState(1.5);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [docType, setDocType] = useState('pdf');
  const [selected, setSelected] = useState(0);
  const [getObjDetails, setObjDetails] = useState({});
  console.log(props, 'props');
  // const handleClickData = (id) => {
  //   props.data.map((item, index) => {
  //     if (item.id === id) {
  //       setObjectActive(id);
  //     }
  //   });
  // };

  const mimeTypes = {
    gif: 'image/gif',
    bmp: 'image/bmp',
    jpg: 'image/jpg',
    xls: 'application/vnd.ms-excel',
    dgn: 'image/vnd.dgn',
    txt: 'text/plain',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    htm: 'text/html',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    png: 'image/png',
  };

  const getMimeType = (name) => {
    const nameSplit = name.split('.');
    const type = nameSplit[nameSplit.length - 1];
    return mimeTypes[type];
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(numPages, 'numPages');
    setNumPages(numPages);
  };

  const handleZoom = (zoomDirection) => {
    zoomDirection === 'in' ? setZoom((previousZoom) => previousZoom + 0.5) : setZoom((previousZoom) => previousZoom - 0.5);
  };

  const changePage = (direction) => {
    direction === 'left' ? setPageNumber((prevPageNumber) => prevPageNumber - 1) : setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    return getFile();
  }, [props.formData.data._id]);

  const getFile = async () => {
    console.log(props.formData.data, 'pkpkpk');

    let docDetails = await getDocDetail(props.formData.data._id);
    // + 'allVersions=false'\c
    console.log(docDetails, 'docDetails');
    setObjDetails(docDetails.data);
  };
  useEffect(() => {
    console.log(pageNumber, numPages);
  }, [pageNumber, numPages]);

  return (
    <Modal
      {...props}
      className={[styles.searchPageModal, 'glbSearchPageModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      // size="xl"
      fullscreen={true}
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <div className={styles.modalHeaderTitle}>
                {/* {`${props.formData.fileSeries} > ${props.formData.folder} > ${props.formData.section} > ${props.formData.objectType}`} */}
              </div>
            </div>
            <div className={styles.modalHeaderIcon}>
              <div
                onClick={() => {
                  // props.setRecordModalShow(false);
                }}
              >
                <img src={FullscreenIcon} alt="fullscreenIcon icon" />
              </div>
              <div
                onClick={() => {
                  props.setRecordModalShow(false);
                  setPageNumber(1);
                  setNumPages(null);
                  setSelected(0);
                  setObjectActive();
                  setZoom(1.5);
                }}
              >
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.searchPageModalBody}>
            <div className={styles.tableDiv}>
              <div>
                <div className={styles.tableHeadDiv}>
                  <div className={styles.tableHeadObjectType}>Object Type</div>
                  <div className={styles.tableHeadDate}>Added ON</div>
                </div>
                <div className={styles.tableBodyDiv}>
                  {console.log(props.data)}
                  {/* {props.data?.map((values, index) => (
                    <div className={styles.tableRow} key={index}>
                      <div
                        key={values.index}
                        className={[objectActive === values.id && styles.clickedData, styles.tableRowObjectType].join(' ')}
                        onClick={() => handleClickData(values.id)}
                      >
                        {values.clipBoardName}{' '}
                        <img src={OptionArrow} className={objectActive === values.id ? styles.optionClikedImage : styles.optionsImage} alt={'options arrow'}></img>
                      </div>

                      <div className={[objectActive === values.id && styles.clickedData, styles.tableRowDate].join(' ')}>{values.Added_On}</div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>

            <div className={styles.previewSectionDiv}>
              <div className={styles.previewSectionHead}>
                <div className={styles.previewSectionHeadText}>Preview</div>
              </div>

              <div className={styles.previewSectionPDF}>
                <Document
                  options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                  }}
                  file={`data:application/pdf;base64,${getObjDetails?.documentData?.file}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} scale={zoom} />
                </Document>
              </div>
              <div className={styles.previewSectionEdit}>
                <div className={styles.previewSectionEditBtn}>
                  <img src={ZoomOutIcon} disabled={zoom === 1.0} alt="ZoomOut icon" onClick={() => handleZoom('out')} />
                </div>
                <div className={styles.previewSectionEditBtn}>
                  <img
                    src={PreviousIcon}
                    disabled={pageNumber === 1}
                    style={pageNumber === 1 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                    alt="Previous icon"
                    onClick={() => changePage('left')}
                  />
                </div>
                <div className={styles.previewSectionEditText}>
                  {' '}
                  Page {pageNumber} of {numPages}
                </div>
                {console.log(pageNumber === numPages, 'pageNumber === numPages')}
                <div
                  className={styles.previewSectionEditBtn}
                  style={pageNumber === numPages ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                  onClick={() => changePage('right')}
                >
                  <img src={NextIcon} alt="NextIcon icon" />
                </div>
                <div className={styles.previewSectionEditBtn}>
                  <img src={ZoomInIcon} disabled={zoom === 3.0} alt="ZoomIn icon" onClick={() => handleZoom('in')} />
                </div>
                <div className={styles.previewSectionEditBtn}>
                  <a
                    download={getObjDetails?.documentData?.fileName}
                    href={
                      docType === 'pdf'
                        ? `data:application/pdf;base64,${getObjDetails?.documentData?.file}`
                        : `data:${getMimeType(getObjDetails?.documentData?.fileName)};base64,${getObjDetails?.documentData?.file}`
                    }
                  >
                    <img src={DownloadIcon} alt="Download icon" />
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.multiSelectSectionDiv}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Object Metadata">
                  <ObjectMetadata />
                </TabStripTab>
                <TabStripTab title="Records Management">
                  <RecordsManagement />
                </TabStripTab>
                <TabStripTab title="Logs">
                  <LogsTab />
                </TabStripTab>
                <TabStripTab title="SENSE - β">
                  <AliceSenseTab />
                </TabStripTab>
              </TabStrip>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality

export default SearchPage;
