import React from 'react';

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

return (
    <>
        <button className="fsa-btn fsa-btn--secondary" onClick={handleClick}> Add Objects</button>

      <input type="file"
        multiple
             ref={hiddenFileInput}
             onInput={(event) => {
                 props.fileManualSelect(event);
            }}
            onClick={
                (event) => {
                    event.target.value=null;
               } 
            }             
             style={{display:'none'}} 
      /> 
    </>
  );
};
export default FileUploader;