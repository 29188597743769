import React, { useContext } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ScrollContainer from 'react-indiana-drag-scroll';
// import { pdfjs } from 'react-pdf';
import './PDFView.scss';
import DownloadFile from '../DownloadFile/DownloadFile';
import UserContext from '../UserContext/UserContext';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFView({ doc, docName, docType, zoom, onDocumentLoadSuccess, pageNumber, handleZoom, changePage, numPages }) {
  const { userPermissions } = useContext(UserContext);

  return (
    <>
      <ScrollContainer style={{ overflowX: zoom > 1 ? 'visible' : 'hidden' }} className="document cardStyle" hideScrollbars={false} vertical={true} horizontal={false}>
        <Document
          // options={{
          //   cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          //   cMapPacked: true,
          // }}
          file={`data:application/pdf;base64,${doc}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={zoom} />
        </Document>
      </ScrollContainer>

      {doc && (
        <div className='w-100'>
          <div className="changePage">
            <IconButton disabled={zoom === 1.0} onClick={() => handleZoom('out')}>
              <ZoomOutIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <IconButton disabled={pageNumber === 1} onClick={() => changePage('left')}>
              <ChevronLeftIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <div className="pagesLabel">
              Page {pageNumber} of {numPages}
            </div>
            <IconButton disabled={pageNumber === numPages} onClick={() => changePage('right')}>
              <ChevronRightIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            <IconButton disabled={zoom === 3.0} onClick={() => handleZoom('in')}>
              <ZoomInIcon
                style={{
                  fontSize: 40,
                }}
              />
            </IconButton>

            {userPermissions['Download_File'] === true && <DownloadFile doc={doc} docName={docName} docType={docType}></DownloadFile>}
          </div>
        </div>
      )}
    </>
  );
}
