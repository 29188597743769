import { DatePicker } from "@progress/kendo-react-dateinputs";
import Moment from "moment";
import { Error } from "@progress/kendo-react-labels";

/*
   The expected date format is yyyy-mm-dd (ex. 2022-04-14).
   Since Datepicker only uses JS Date we have to convert it back to the expected format.
   Ex. Date Apr 14 2022 10:19:04 GMT-0500 (Central Daylight Time) to "2022-04-14"
 */
export const convertDateToString = (jsDate) => {
  if (jsDate) {
    return Moment(jsDate).format('YYYY-MM-DD')
  }
}

/*
   string to JS Date with user timezone
   Ex. "2022-04-14" to Date Apr 14 2022 10:19:04 GMT-0500 (Central Daylight Time)
*/
const convertStringToDate = (stringDate) => {
  if (typeof stringDate === 'string') {
    const localTimeZone = Moment.tz.guess()
    return Moment(stringDate).tz(localTimeZone).toDate();
  }
}

export default function FormDatepicker(props) {
  const {value, validationMessage, min, ...rest} = props;
  const date = convertStringToDate(value) || value;
  const minDate = convertStringToDate(min) || min;

  return (
    <>
      <DatePicker {...rest} min={minDate} className={'fsa-field__item fsa-datepicker'} value={date}/>
      {validationMessage && <Error>{validationMessage}</Error>}
    </>
  )
}