import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import styles from './DocumentErrorModal.module.scss';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import ErrorCircle from '../../fsa-style/img/ErrorCircle.svg';
import ErrorTriangle from '../../fsa-style/img/ErrorTriangle.png';

const DocumentErrorModal = ({ isModalOpen, closeDocErrorModal }) => {
  return (
    <>
      {/*       
      <div style={{ display: isModalOpen ? 'block' : 'none' }}>
         {/* Modal content goes here 
       <p>This is a modal</p>
         <button onClick={closeDocErrorModal}>Close Modal</button>
        
      </div> */}
      <Modal
        data-testid="document-error-modal"
        show={isModalOpen}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={[styles.documentErrorModal, 'glbErrorDocModal'].join(' ')}
        onHide={closeDocErrorModal}
      >
        <div className={styles.documentModal}>
          <Modal.Header data-testid="documenterrormodal-header">
            <div className={styles.docModalHeaderSection}>
              <div>
                <h2 className={styles.docModalHeaderText}>Error </h2>
              </div>
              <div className={styles.docModalHeaderIcon} onClick={closeDocErrorModal}>
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body data-testid="documenterrormodal-body">
            <div className={styles.docModalBody}>
              <div className={styles.modalImageDiv}>
                <img src={ErrorCircle} className={styles.docModalCircleIcon} alt="circle icon" />
                <img src={ErrorTriangle} className={styles.errorModalTriangleIcon} alt="danger icon" />
              </div>
              <div className={styles.docModalText} data-testid="modal-text-container">
                <div className={styles.docModalDescription}>
                  The uploaded document version doesn't
                  <div className={styles.errorModalHeading}>match our current version.</div> Please try again
                </div>
                <div className={styles.docModalMessage}>
                  <span className={styles.errorModalNote}>Note: </span>In the uploaded document please update the version in the index sheet.
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className={styles.docModalFooter}>
              <CustomButton title="Try Again" className={styles.tryAgainButton} onClick={closeDocErrorModal} />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default DocumentErrorModal;
