import { FieldWrapper } from "@progress/kendo-react-form";

export default function CompletedHoldManagementForm(props) {
  const {holdObject} = props

  return (
    <div>
      <div className="row">
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Hold Name</label>
          <p>{holdObject.holdName}</p>
        </FieldWrapper>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Type of Hold</label>
          <p>{holdObject.holdtype}</p>
        </FieldWrapper>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Responsible Staff Email</label>
          <p>{holdObject.responsibleOfficerEmail}</p>
        </FieldWrapper>
      </div>

      <div className={'row mt-4'}>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Responsible Staff</label>
          <p>{holdObject.responsibleOfficer}</p>
        </FieldWrapper>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Hold Effective Date</label>
          <p>{holdObject.effectiveDate}</p>
        </FieldWrapper>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Hold Expiration Date</label>
          <p>{holdObject.expirationDate}</p>
        </FieldWrapper>
      </div>

      <div className={'row mt-4'}>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Hold Declaration Status</label>
          <p>{holdObject.holdstatus}</p>
        </FieldWrapper>
        <FieldWrapper className={'col-4'}>
          <label className="fsa-field__label">Hold Number</label>
          <p>{holdObject.holdNumber}</p>
        </FieldWrapper>
      </div>

      <div className={'row mt-4'}>
        <FieldWrapper className={'col-12'}>
          <label className="fsa-field__label">Participants/Litigants</label>
          <p>{holdObject.participants}</p>
        </FieldWrapper>
      </div>

      <div className={'row mt-4'}>
        <FieldWrapper className={'col-12'}>
          <label className="fsa-field__label">Hold Scope</label>
          <p>{holdObject.holdScope}</p>
        </FieldWrapper>
      </div>
    </div>
  )
}