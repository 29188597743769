import React, { useEffect, useState, lazy } from 'react';
import styles from './UserInfo.module.scss';
import UserInfoIcon from '../../../fsa-style/img/svgs/UserInfoIcon.svg';
// import EditUserInfo from './EditUserInfoModal';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import _ from 'lodash';

const EditUserInfo = lazy(() => import('./EditUserInfoModal'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));

const UserInfo = () => {
  const userData = JSON.parse(sessionStorage.getItem('user_information'));

  const [formData, setFormData] = useState({
    contextId: userData.contextId,
    userName: userData.userName,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    defaultSearchGroupLevel: userData?.userPreference?.defaultSearchGroupLevel,
    roleName: userData.rolesName,
    id: userData?.userPreference?.id,
  });
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState();

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem('user_information'));
    setFormData({
      contextId: userData.contextId,
      userName: userData.userName,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      defaultSearchGroupLevel: userData?.userPreference?.defaultSearchGroupLevel,
      roleName: userData.rolesName,
      id: userData.userPreference !== null ? userData.userPreference.id : null,
    });
  }, []);
  const handleEdit = () => {
    setModalShow(true);
  };
  const refreshData = () => {
    const userData = JSON.parse(sessionStorage.getItem('user_information'));
    setFormData((prevData) => ({
      ...prevData,
      contextId: userData.contextId,
      userName: userData.userName,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      defaultSearchGroupLevel: userData?.userPreference?.defaultSearchGroupLevel,
      roleName: userData.rolesName,
      id: userData.userPreference !== null ? userData.userPreference.id : null,
    }));
  };

  return (
    <div className={styles.userInfoMainContent}>
      {isLoading && <GlobalLoader />}

      <div className={styles.userInfoContent}>
        <img className={styles.userInfoIcon} src={UserInfoIcon} alt="UserInfoIcon"></img>
        <strong className={styles.userProfileText}>User Profile</strong>
      </div>
      <div className={styles.userDetails}>
        <div className={[styles.inputRow, 'row'].join(' ')}>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>Context id</label>
            <input className={styles.userInput} value={formData.contextId} disabled></input>
          </div>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>User Name</label>
            <input className={styles.userInput} value={formData.userName} disabled></input>
          </div>
        </div>

        <div className={[styles.inputRow, 'row'].join(' ')}>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>First Name</label>
            <input className={styles.userInput} value={formData.firstName} disabled></input>
          </div>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>Last Name</label>
            <input className={styles.userInput} value={formData.lastName} disabled></input>
          </div>
        </div>

        <div className={[styles.inputRow, 'row'].join(' ')}>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>Email</label>
            <input className={styles.userInput} value={formData.email} disabled></input>
          </div>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>Default Search Folder Level</label>
            <input className={styles.userInput} defaultValue={formData.defaultSearchGroupLevel} key={formData.defaultSearchGroupLevel} disabled></input>
          </div>
        </div>

        <div className={[styles.inputRow, 'row'].join(' ')}>
          <div className={[styles.inputSection, 'col-5'].join(' ')}>
            <label className={styles.userLabel}>Role Name(s)</label>
            <input className={styles.userInput} value={formData.roleName} disabled></input>
          </div>
        </div>
      </div>
      <div>
        <CustomButton title="Edit User Profile" className={styles.editUserInfoBtn} onClick={handleEdit} />
      </div>
      <EditUserInfo
        show={modalShow}
        setModalShow={(e) => {
          setModalShow(e);
          e === false && refreshData();
        }}
        formData={_.cloneDeep(formData)}
        setFormData={setFormData}
        isLoading={(e) => setLoading(e)}
        setMessage={setMessage}
        setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
        setIsErrorGrowlHidden={setIsErrorGrowlHidden}
        refreshData={refreshData}
      />
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
    </div>
  );
};

export default UserInfo;
