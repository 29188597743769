import React from "react";

export default function TextSearchInput({userParams, removeParam, handleChange, retrieveValue}) {
    const systemName = userParams.systemName;
    const friendlyName = userParams.friendlyName;

    return(
        <div key={systemName} className="specsDivs1">
          <label>
          <div style ={{alignItems:"center", justifyContent:"space-between",display:"flex",margin: "1px"}}>
            {friendlyName}
            {/* <img
              style={{ height: 10, marginLeft: "1rem" }}
              onClick={() => removeParam(systemName)}
              src="/img/close.png"
              alt="Remove Parameter"
            /> */}
            
            <div className={'inputRemoveIcon'} onClick={() => removeParam(systemName)}>
              x
            </div>
          </div>
            
          </label>
          <input
            value={retrieveValue(systemName)}
            placeholder={friendlyName}
            type="text"
            name={friendlyName}
            onChange={(e) => handleChange(systemName,e)}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          </div>
    )
}
