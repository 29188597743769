import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import HoldManagementForm from './HoldManagementForm';
import ObjectsInHold from './ObjectsInHold';
import CompletedHoldManagementForm from './CompletedHoldManagementForm';

export default function EditHold(props) {
  const { holdObject, onClose } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const onTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const isHoldCompleted = holdObject.holdstatus === 'Completed' || holdObject.holdstatus === 'Inactive';

  return (
    <TabStrip tabContentStyle={{ width: '100%', paddingLeft: 0, paddingRight: 0 }} selected={selectedTab} onSelect={onTabSelect}>
      <TabStripTab title="Objects in Hold">
        <ObjectsInHold onClose={onClose} holdObject={holdObject} />
      </TabStripTab>
      <TabStripTab title="Hold Details">
        {isHoldCompleted ? <CompletedHoldManagementForm holdObject={holdObject} /> : <HoldManagementForm onClose={onClose} holdObject={holdObject} />}
      </TabStripTab>

      {/* {userPermissions['View_Logs_Hold'] === true && <TabStripTab title="Logs" />} */}
    </TabStrip>
  );
}
