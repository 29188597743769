import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './MetadataModal.module.scss';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

import AsyncSelect from 'react-select/async';

const values = [
  {
    display_name: 'Document Name',
  },
  { display_name: 'Document Title' },
  { display_name: 'Employee Number' },
  { display_name: 'First Name' },
  { display_name: 'Last Name' },
  { display_name: 'Middle Name' },
  { display_name: 'Sections' },
  { display_name: 'Employee Number' },
  { display_name: 'Document Name' },
  { display_name: 'Document Title' },
  { display_name: 'Employee Number' },
  { display_name: 'First Name' },
];

const MetaDataModal = ({ metadataModalOpen, closeMetadatModal, setMetadataModalOpen, metadataSelection, onInputTitleChange }) => {
  const [dropdownData, setDropdownData] = useState({
    display_name: '',
  });
  const [dropdownLabel, setDropdownLabel] = useState(false);
  const [inputTitle, setInputTitle] = useState([]);

  const metaDropListChange = (event) => {
    if (event == null)
      return setDropdownData((prevData) => ({
        ...prevData,
        display_name: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    setDropdownData((prevData) => ({
      ...prevData,
      display_name: updatedValue,
    }));
    // console.log(value, 'option');

    if (value !== null) {
      if (!inputTitle.includes(value)) {
        let newObj = { ['name']: value, ['Value']: '' };

        setInputTitle([...inputTitle, newObj]);
      }
    }
  };

  const clearMetadata = () => {
    setInputTitle([]);
    setDropdownLabel(true);
  };

  const handleInputChange = (name, event) => {
    const newValue = event.target.value;

    setInputTitle((prevInputTitle) => {
      return prevInputTitle.map((item) => (item.name === name ? { ...item, Value: newValue } : item));
    });
  };

  let removeValue = (index) => {
    const newInputTitle = inputTitle.filter((_, i) => i !== index);
    setInputTitle(newInputTitle);
    setDropdownLabel(true);
  };

  const loadMetadata = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      let options = await values.map((res) => {
        return { label: res.display_name, value: res.display_name };
      });
      callback(options);
      //   await getSearchContextId(inputValue).then(async (response1) => {
      //     let options = await response1.data.map((res) => {
      //       return { label: res.contextId, value: res.contextId };
      //     });

      //   });
    }
    // const result = values.map((value) => setDropdownData(value.display_name));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '80%',
      minHeight: '80%',
      width: '100%',
    }),

    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',

      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const handleSendData = () => {
    onInputTitleChange(inputTitle);
  };

  return (
    <>
      <Modal
        show={metadataModalOpen}
        onHide={() => {
          closeMetadatModal();
        }}
        className={[styles.metadataModal, 'glbMetadataModal'].join(' ')}
        centered
        size="lg"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Metadata Search</h2>
            </div>
            <div className={styles.modalHeaderIcon}>
              <img src={crossIcon} alt="closeIcon" onClick={() => closeMetadatModal()}></img>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.metadataModalBody}>
          <div>
            <div className={metadataSelection !== null ? 'form-field' : 'form-field defaultItem'}>
              <div>
                <span>Metadata:</span>
              </div>

              <AsyncSelect
                styles={customStyles}
                isClearable
                loadOptions={loadMetadata}
                value={
                  dropdownLabel
                    ? { label: 'Select Metadata' }
                    : dropdownData?.display_name !== ''
                    ? { label: dropdownData?.display_name, value: dropdownData?.display_name }
                    : { label: 'Select Metadata' }
                }
                onChange={(e) => metaDropListChange(e)}
              />

              {inputTitle !== null
                ? inputTitle.map((title, index) => (
                    <div className={styles.inputDiv} key={index}>
                      <div className={[styles.inputFieldSection, 'col-12'].join(' ')}>
                        <div className={styles.inputFieldInternalDiv}>
                          <div className={styles.labelDiv}>
                            <label className={styles.labelName}>{title.name}</label>
                            <span className={styles.removeIcon} onClick={() => removeValue(index)}>
                              x
                            </span>
                          </div>

                          <input name={title.name} type="text" value={title.Value} onChange={(e) => handleInputChange(title.name, e)} />
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.metadataFooter}>
          <div className={styles.footerInternalDiv}>
            <button className={styles.metadataBtn} onClick={() => clearMetadata()}>
              Clear All Metadata
            </button>
            <button
              className={styles.clearBtn}
              onClick={() => {
                handleSendData();
                closeMetadatModal();
              }}
            >
              <span>Search</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MetaDataModal;
